<template>
	<v-card class="" elevation="0" color="">
		<DataTable />
	</v-card>
</template>

<script>
import DataTable from '../../../components/tables/OrdersDataTable.vue';

export default {
	name: 'Orders',
	components: {
		DataTable
	}
};
</script>
