<template>
	<v-card :elevation="0" color="transparent">
		<v-card-title class="font-weight-bold">
			{{ $t('settings') }}
			<v-spacer></v-spacer>
		</v-card-title>
		<v-container>
			<v-row>
				<v-radio-group v-on:change="languageChanged" v-model="radios">
					<template v-slot:label>
						<div>
							<strong>{{ $t('language') }}</strong>
						</div>
					</template>
					<v-radio value="en">
						<template v-slot:label>
							<div>
								<v-img class="rounded-circle" max-height="30" max-width="30" src="../assets/icons/en_US.png"> </v-img>
							</div>
						</template>
					</v-radio>
					<v-radio value="es">
						<template v-slot:label>
							<div>
								<v-img class="rounded-circle" max-height="30" max-width="30" src="../assets/icons/es_MX.png"> </v-img>
							</div>
						</template>
					</v-radio>
				</v-radio-group>
			</v-row>
			<v-row>
				<v-switch v-model="isDark" inset :label="isDark ? $t('dark_mode') : $t('light_mode')"></v-switch>

				<v-spacer> </v-spacer>

				<v-btn color="primary" depressed elevation="2" large raised rounded @click="logout">{{ $t('logout') }}</v-btn>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
//import moment from 'moment';
//import { v4 as uuidv4 } from 'uuid';

//import VueNumeric from 'vue-numeric';

export default {
	components: {
		//VueNumeric
	},
	data() {
		return {
			isDark: false,
			radios: 'en',
		};
	},
	computed: {},

	watch: {
		isDark(val) {
			this.$vuetify.theme.dark = val;
			localStorage.setItem('weserv.useDarkTheme', val.toString());
		},
	},
	methods: {
		languageChanged() {
			localStorage.setItem('weserv.language', this.radios);
			this.$i18n.locale = this.radios;
		},
		logout() {
			localStorage.clear();
			this.$router.push('/login');
		},
	},
	mounted() {
		const theme = localStorage.getItem('weserv.useDarkTheme');
		const lang = localStorage.getItem('weserv.language');
		if (theme) {
			if (theme == 'true') {
				this.isDark = true;
			} else this.isDark = false;
		}
		if (lang) {
			this.radios = lang;
		}
	},
};
</script>

<style scoped></style>
