script<template>
	<v-card class="px-3">
		<v-card-title>
			<h4>{{ title }}</h4>
			<v-spacer></v-spacer>
			<v-card-subtitle>
				{{ time }}
			</v-card-subtitle>
		</v-card-title>

		<v-card-text>
			<h2>{{ formattedNumber(number) }}</h2>
		</v-card-text>
	</v-card>
</template>

<script>
var formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export default {
	name: 'DataCell',
	props: ['title', 'number', 'time'],
	data: () => ({}),
	methods: {
		formattedNumber(number) {
			return formatter.format(number);
		},
	},
};
</script>

<style></style>
