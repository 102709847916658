<template>
	<div class="random">
		<trend-chart
			:datasets="data"
			:grid="grid"
			:labels="xLabels"
			:min="0"
			padding="20 60"
			:interactive="true"
			@mouse-move="onMouseMove"
			class="random-chart"
			v-if="data.length"
		></trend-chart>
		<div id="pop" role="tooltip" ref="tooltip" class="tooltip" :class="{ 'is-active': tooltipData }">
			<div class="tooltip-container " v-if="tooltipData">
				<strong>{{ xLabels.xLabels[tooltipData.index] }}</strong>
				<div class="tooltip-data">
					<div class="tooltip-data-item tooltip-data-item--1">{{ tooltipData.data[0] }}</div>
					<!-- <div class="tooltip-data-item tooltip-data-item--2">{{ tooltipData.data[1] }}</div>
					<div class="tooltip-data-item tooltip-data-item--3">{{ tooltipData.data[2] }}</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Popper from 'popper.js';
export default {
	props: ['data', 'info'],
	data() {
		return {
			grid: {
				verticalLines: true,
				horizontalLines: true,
			},

			tooltipData: null,
			popper: null,
			popperIsActive: false,
		};
	},
	methods: {
		initPopper() {
			const chart = document.querySelector('.random-chart');
			const ref = chart.querySelector('.active-line');
			const tooltip = this.$refs.tooltip;
			this.popper = new Popper(ref, tooltip, {
				placement: 'right',
				modifiers: {
					offset: { offset: '0,10' },
					preventOverflow: {
						boundariesElement: chart,
					},
				},
			});
		},
		onMouseMove(params) {
			this.popperIsActive = !!params;
			this.popper.scheduleUpdate();
			this.tooltipData = params || null;
		},
	},
	computed: {
		xLabels() {
			return {
				xLabels: this.info,
				yLabels: 5,
				yLabelsTextFormatter: (val) => Math.round(val * 100) / 100,
			};
		},
		isLight() {
			const theme = localStorage.getItem('weserv.useDarkTheme');
			if (theme) {
				if (theme == 'true') {
					return false;
				} else return true;
			}
			return false;
		},
	},
	mounted() {
		this.initPopper();
	},
};
</script>

<style lang="scss">
.random {
	width: 100%;
	.vtc {
		height: 250px;
		font-size: 12px;
		@media (min-width: 699px) {
			height: 320px;
		}
	}
	.labels {
		stroke: rgba(0, 0, 0, 0.05);
	}
	.active-line {
		stroke: rgba(0, 0, 0, 0.2);
	}
	.point {
		stroke-width: 2;
		transition: stroke-width 0.2s;
	}
	.point.is-active {
		stroke-width: 5;
	}
	.curve1 {
		.stroke {
			stroke: #fbac91;
			stroke-width: 2;
		}
		.fill {
			fill: #fbac91;
			opacity: 0.05;
		}
		.point {
			fill: #fbac91;
			stroke: #fbac91;
		}
	}
	.curve2 {
		.stroke {
			stroke: #fbe1b6;
			stroke-width: 2;
		}
		.point {
			fill: #fbe1b6;
			stroke: #fbe1b6;
		}
	}
	.curve3 {
		.stroke {
			stroke: #7fdfd4;
			stroke-width: 2;
		}
		.point {
			fill: #7fdfd4;
			stroke: #7fdfd4;
		}
	}

	.tooltip {
		&:not(.is-active) {
			display: none;
		}
		padding: 10px;

		box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		pointer-events: none;
		&-data {
			display: flex;
			&-item {
				display: flex;
				align-items: center;
				&:not(:first-child) {
					margin-left: 20px;
				}
				&:before {
					content: '';
					display: block;
					width: 15px;
					height: 15px;
					margin-right: 5px;
				}
				&--1:before {
					background: #fbac91;
				}
				&--2:before {
					background: #fbe1b6;
				}
			}
		}
	}
}
</style>
