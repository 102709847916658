var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":0,"color":"transparent"}},[_c('v-alert',{attrs:{"transition":"slide-y-transition","border":"left","close-text":"Close Alert","type":_vm.alertType,"dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]),_c('v-card-title',{staticClass:"font-weight-bold"},[_c('h1',[_vm._v(_vm._s(_vm.$t('categories')))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.categories,"items-per-page":10,"search":_vm.search,"loading":_vm.loadingTable,"multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('add_category'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',{staticClass:"d-flex justify-center my-5"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('name'),"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('name'),"filled":"","required":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('priority'),"rules":{ required: true, min_value: 0, max_value: 10 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('priority'),"filled":"","required":""},model:{value:(_vm.editedItem.priority),callback:function ($$v) {_vm.$set(_vm.editedItem, "priority", $$v)},expression:"editedItem.priority"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.accessLevel == 'admin')?_c('validation-provider',{attrs:{"name":_vm.$t('business'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.businesses,"item-text":"name","item-value":"_id","error-messages":errors,"filled":"","label":_vm.$t('business')},model:{value:(_vm.editedItem.restaurant._id),callback:function ($$v) {_vm.$set(_vm.editedItem.restaurant, "_id", $$v)},expression:"editedItem.restaurant._id"}})]}}],null,true)}):_vm._e()],1)],1),_c('v-container',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":"success","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.AddButtonText)+" ")])],1)],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(_vm._s(_vm.$t('delete_dialog_confirm_category')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-sheet',{staticClass:"my-10",attrs:{"color":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-sheet',{attrs:{"color":""}},[_c('h2',{staticClass:"text-h2 pa-3"},[_vm._v(_vm._s(_vm.$t('no_categories')))])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"ma-4",attrs:{"color":"primary"},on:{"click":_vm.getCategories}},[_vm._v(_vm._s(_vm.$t('refresh')))])],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }