<template>
	<div>
		<!-- ALERT -->
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>
		<v-container>
			<v-row>
				<v-col cols="12" sm="" md="" lg="">
					<v-card class="stackSheet">
						<v-card-title class="font-weight-bold">
							<h4>{{ $t('sales_last_4_work_weeks') }}</h4>
							<v-spacer></v-spacer>
							<v-btn
								:disabled="isCountGraph"
								color="blue"
								class="mx-3"
								@click="isCountGraph = !isCountGraph"
								:dark="!isCountGraph"
								>{{ $t('count') }}</v-btn
							>
							<v-btn
								:disabled="!isCountGraph"
								color="green"
								@click="isCountGraph = !isCountGraph"
								:dark="isCountGraph"
								>{{ $t('revenue') }}</v-btn
							>
							<!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field> -->
						</v-card-title>
						<trend-graph :info="graphLabels" :data="graphDatasets"></trend-graph>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<v-container>
			<v-row>
				<v-col cols="12" sm="6" md="">
					<!-- <v-container> -->
					<v-card>
						<v-card-title class="font-weight-bold">
							<h4>{{ $t('products_statistics_orders') }}</h4>
							<v-spacer></v-spacer>
							<v-card-subtitle>{{ $t('last_30_days') }}</v-card-subtitle>
							<!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field> -->
						</v-card-title>
						<v-data-table
							:headers="headers"
							:items="productsData"
							:items-per-page="10"
							multi-sort
							:loading="loadingTable"
						>
							<template v-slot:[`item.revenue`]="{ item }">
								<!-- <v-chip color="green" dark> -->
								{{ item.revenue }}
								<!-- </v-chip> -->
							</template>
							<template v-slot:[`item.qty`]="{ item }">
								<v-chip color="gray" dark>
									{{ item.qty }}
								</v-chip>
							</template>

							<template v-slot:no-data>
								<v-sheet class="my-10" color="">
									<v-row justify="center">
										<v-sheet color="">
											<h2 class="text-h2 pa-3">{{ $t('no_product_data') }}</h2>
										</v-sheet>
									</v-row>
									<v-row justify="center">
										<v-btn class="ma-4" color="primary" @click="getProductStatistics()">{{ $t('refresh') }}</v-btn>
									</v-row>
								</v-sheet>
							</template>
						</v-data-table>
					</v-card>
					<!-- </v-container> -->
				</v-col>
				<v-col cols="12" sm="6">
					<!-- <v-container> -->

					<data-cell
						class="mb-5"
						:title="$t('avg_order_total')"
						:number="orderDataCell[0] ? orderDataCell[0].avg : 0"
						:time="$t('last_30_days')"
					></data-cell>
					<data-cell
						class="mb-5"
						:title="$t('max_order_total')"
						:number="orderDataCell[0] ? orderDataCell[0].max : 0"
						:time="$t('last_30_days')"
					></data-cell>
					<data-cell
						class="mb-5"
						:title="$t('min_order_total')"
						:number="orderDataCell[0] ? orderDataCell[0].min : 0"
						:time="$t('last_30_days')"
					></data-cell>

					<!-- </v-container> -->
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
const gradients = [
	['#222'],
	['#42b3f4'],
	['red', 'orange', 'yellow'],
	['purple', 'violet'],
	['#00c6ff', '#F0F', '#FF0'],
	['#f72047', '#ffd200', '#1feaea'],
];
import DataCell from './cells/DashboardDataCell.vue';
import TrendGraph from './cells/TrendGraph.vue';

export default {
	components: { DataCell, TrendGraph },
	data() {
		return {
			width: 2,
			radius: 10,
			padding: 8,
			lineCap: 'round',
			gradient: gradients[5],

			gradientDirection: 'top',
			gradients,
			fill: false,
			type: 'trend',
			autoLineWidth: false,

			/* GRAPH */
			orderWeeks: [],
			isCountGraph: true,
			/* DATA TABLES */
			//search: '',
			headers: [
				{ text: this.$i18n.t('product'), value: 'name' },
				{ text: this.$i18n.t('revenue'), value: 'revenue', align: 'start' },
				{ text: this.$i18n.t('quantity'), value: 'qty', align: 'center' },
			],
			productsData: [],

			loadingTable: false,

			orderDataCell: [],

			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',
		};
	},
	computed: {
		graphDatasets() {
			const temp = this.orderWeeks;
			while (this.orderWeeks.length > 4) {
				temp.shift();
			}
			while (temp.length < 4) {
				temp.push({ revenue: 0, count: 0 });
			}
			var dataArray;
			if (this.isCountGraph) {
				dataArray = temp.map((w) => w.count);
			} else {
				dataArray = temp.map((w) => w.revenue);
			}

			return [
				{
					data: dataArray.reverse(),
					smooth: true,
					showPoints: true,
					fill: true,
					className: 'curve1',
				},
				// {
				// 	data: arrayRevenue,
				// 	smooth: true,
				// 	showPoints: true,
				// 	className: 'curve2',
				// },
			];
		},
		graphLabels() {
			return this.orderWeeks
				.map((item) => {
					return item.date;
				})
				.reverse();
		},
	},
	methods: {
		splitArrayIntoChunksOfLen(arr, len) {
			var chunks = [],
				i = 0,
				n = arr.length;
			while (i < n) {
				chunks.push(arr.slice(i, (i += len)));
			}
			return chunks;
		},

		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},
		async getProductStatistics() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				var user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					setTimeout(() => {
						user = JSON.parse(localStorage.getItem('weserv.user'));
					}, 100);
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `dashboard/products`, options)
					.then((response) => response.json())
					.then((data) => {
						//console.log(data);
						this.productsData = data.data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
				this.loadingTable = false;
			}
		},
		async getOrderStatistics() {
			try {
				const token = localStorage.getItem('weserv.access');
				var user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					setTimeout(() => {
						user = JSON.parse(localStorage.getItem('weserv.user'));
					}, 100);
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `dashboard/orders`, options)
					.then((response) => response.json())
					.then((data) => {
						//console.log(data);
						this.orderDataCell = data.data;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async getWeeksOrders() {
			try {
				const token = localStorage.getItem('weserv.access');
				var user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					setTimeout(() => {
						user = JSON.parse(localStorage.getItem('weserv.user'));
					}, 100);
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `dashboard/orders/graph`, options)
					.then((response) => response.json())
					.then((data) => {
						//console.log(data);
						this.orderWeeks = data.data;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		this.getProductStatistics();
		this.getOrderStatistics();
		this.getWeeksOrders();
	},
};
</script>

<style lang="css" scoped>
.stackSheet {
	position: relative;
}
.stackSpark {
	position: absolute;
	top: 0;
	left: 0;
}
</style>
