<template>
	<div>
		<gmap-map class="rounded-lg" :zoom="zoom" :center="center" style="width:100%;  height: 350px; overflow:hidden;">
			<gmap-marker
				:key="index"
				v-for="(m, index) in locationMarker"
				:position="m.position"
				:draggable="true"
				@dragend="updateLocation"
				@click="center = m.position"
			></gmap-marker>
			<gmap-circle
				v-for="pin in locationMarker"
				:key="pin.id"
				:center="pin.position"
				:radius="buyingRadius"
				:visible="true"
				:options="{ fillColor: 'red', fillOpacity: 0.2, strokeColor: 'pink' }"
			></gmap-circle>
			<gmap-circle
				v-for="pin in locationMarker"
				:key="pin.id + 1"
				:center="pin.position"
				:radius="visibilityRadius"
				:visible="true"
				:options="{ fillColor: 'blue', fillOpacity: 0.2, strokeColor: 'lightblue' }"
			></gmap-circle>
		</gmap-map>
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'AddGoogleMap',
	props: {
		mapData: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			zoom: 13,
			centerProxy: null,
			// defaultMarker: {
			// 	position: {
			// 		lat: 21.0307114,
			// 		lng: -89.6181018
			// 	}
			// },
			locPlaces: [],
			existingPlace: null,
		};
	},
	computed: {
		locationMarker() {
			//const intLat = parseInt(this.mapData.lat)
			//const
			return [{ id: uuidv4(), position: { lat: this.mapData.lat, lng: this.mapData.lng } }];
		},
		buyingRadius() {
			const intBuyRadius = parseInt(this.mapData.buyRadius);
			if (intBuyRadius) {
				return intBuyRadius;
			}
			return 0;
		},
		visibilityRadius() {
			const intVisibleRadius = parseInt(this.mapData.visibleRadius);
			if (intVisibleRadius) {
				return intVisibleRadius;
			}
			return 0;
		},
		center: {
			get: function() {
				////console.log(` LOC: ${this.locationMarker[0].position.lat} , ${this.locationMarker[0].position.lng}`);
				if (this.centerProxy) {
					////console.log(` CENTER: ${this.centerProxy.lat} , ${this.centerProxy.lng}`);
				}
				return this.centerProxy === null ? this.locationMarker[0].position : this.centerProxy;
			},
			set: function(newValue) {
				this.centerProxy = newValue;
			},
		},
	},

	mounted() {
		//this.locateGeoLocation();
	},

	methods: {
		printLocation() {
			//console.log(this.locationMarker[0].position.lat);
			//console.log(this.locationMarker[0].position.lng);
		},
		updateLocation(e) {
			this.locationMarker[0].position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
			this.center = this.locationMarker[0].position;
			this.$emit('update', {
				lat: e.latLng.lat(),
				lon: e.latLng.lng(),
			});
		},
		initMarker(loc) {
			this.existingPlace = loc;
			//console.log(loc);
		},
		addLocationMarker() {
			if (this.existingPlace) {
				const marker = {
					lat: this.existingPlace.geometry.location.lat(),
					lng: this.existingPlace.geometry.location.lng(),
				};
				this.locationMarkers.push({ position: marker });
				this.locPlaces.push(this.existingPlace);
				this.center = marker;
				this.existingPlace = null;
			}
		},
		resetCenter() {
			this.centerProxy = null;
			this.zoom = 13;
		},
		// locateGeoLocation: function() {
		// 	navigator.geolocation.getCurrentPosition(res => {
		// 		this.center = {
		// 			lat: res.coords.latitude,
		// 			lng: res.coords.longitude
		// 		};
		// 	});
		// }
	},
};
</script>

<style scoped></style>
