<template>
	<div>
		<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
		<v-navigation-drawer v-model="drawer" left app>
			<v-sheet class="pa-4" color="tertiary">
				<v-row justify="space-around">
					<v-menu top :close-on-click="closeOnClick">
						<template v-slot:activator="{ on, attrs }">
							<v-avatar v-bind="attrs" v-on="on" class="ma-2" color="primary" size="56">
								<span class="white--text">{{ `${userData.firstname[0]}${userData.lastname[0]}` }} </span></v-avatar
							>
						</template>

						<v-list>
							<v-list-item>
								<v-list-item-subtitle
									><span class="font-weight-medium">{{ $t('name') }}: </span> {{ userData.firstname }}
									{{ userData.lastname }}</v-list-item-subtitle
								>
							</v-list-item>
							<v-list-item>
								<v-list-item-subtitle
									><span class="font-weight-medium">{{ $t('email') }}: </span> {{ userData.email }}
								</v-list-item-subtitle>
							</v-list-item>
							<v-list-item v-if="userData.business">
								<v-list-item-subtitle
									><span class="font-weight-medium"> {{ $t('business') }}: </span> {{ userData.business.name }}
								</v-list-item-subtitle>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-row>
			</v-sheet>

			<v-divider></v-divider>

			<v-list nav>
				<v-btn
					v-if="userData.accessLevel == 'business' || userData.accessLevel == 'employee'"
					:loading="ordersButtonLoading"
					dark
					block
					:color="orderButtonColor"
					class="mb-3 py-8 "
					@click="changeAvailabilityOfRestaurant"
				>
					{{ userData.business.available ? $t('close_orders') : $t('open_to_orders') }}
				</v-btn>

				<v-list-item-group v-model="selectedItem" color="primary">
					<v-list-item v-for="link in allowedLinks" :key="link.name" :color="link.color" link>
						<!-- <v-col cols="12" sm="4"> -->
						<v-btn block @click="sendToView(link)">
							<v-list-item-icon class="">
								<v-icon>{{ link.icon }}</v-icon>
							</v-list-item-icon>
							<!-- </v-col>
						<v-col cols="12" sm="8"> -->
							<v-list-item-content>
								<v-list-item-title class="text-button text-start">{{ $t(link.name) }}</v-list-item-title>
							</v-list-item-content>
						</v-btn>
						<!-- </v-col> -->
					</v-list-item>
				</v-list-item-group>
			</v-list>
			<!-- <template v-slot:append>
				<div class="pa-2">
					<v-btn block>
						Logout
					</v-btn>
				</div>
			</template> -->
		</v-navigation-drawer>

		<v-main>
			<router-view class="pa-5" />
		</v-main>
	</div>
</template>

<script>
export default {
	name: 'frame',
	data() {
		return {
			drawer: true,
			selectedItem: '',
			ordersButtonLoading: true,
			closeOnClick: true,
			userData: {
				email: '',
				firstname: '',
				lastname: '',
				accessLevel: '',
				business: {
					_id: '',
					available: undefined,
				},
			},
			links: [
				{
					icon: 'mdi-monitor-dashboard',
					name: 'dashboard',
					link: 'dashboard',
					color: 'green',
					allow_admin: false,
					allow_business: true,
					allow_employee: false,
				},
				{
					icon: 'mdi-account-supervisor',
					name: 'business',
					link: 'business',
					color: 'red',
					allow_admin: false,
					allow_business: true,
					allow_employee: false,
				},
				{
					icon: 'mdi-stadium',
					name: 'venues',
					link: 'stadiums',
					color: 'yellow',
					allow_admin: true,
					allow_business: false,
					allow_employee: false,
				},
				{
					icon: 'mdi-account-supervisor',
					name: 'businesses',
					link: 'businesses',
					color: 'green',
					allow_admin: true,
					allow_business: false,
					allow_employee: false,
				},

				{
					icon: 'mdi-account-supervisor',
					name: 'employees',
					link: 'employees',
					color: 'blue',
					allow_admin: true,
					allow_business: true,
					allow_employee: false,
				},
				{
					icon: 'mdi-shape',
					name: 'categories',
					link: 'categories',
					color: 'purple',
					allow_admin: true,
					allow_business: true,
					allow_employee: false,
				},
				{
					icon: 'mdi-food-fork-drink',
					name: 'products',
					link: 'products',
					color: 'orange',
					allow_admin: true,
					allow_business: true,
					allow_employee: true,
				},

				{
					icon: 'mdi-inbox-full',
					name: 'daily_orders',
					link: 'orders',
					color: 'red',
					allow_admin: true,
					allow_business: true,
					allow_employee: true,
				},
				{
					icon: 'mdi-history',
					name: 'all_orders',
					link: 'history',
					color: 'orange',
					allow_admin: true,
					allow_business: true,
					allow_employee: false,
				},
				{
					icon: 'mdi-badge-account-alert',
					name: 'reports',
					link: 'reports',
					color: 'gray',
					allow_admin: true,
					allow_business: false,
					allow_employee: false,
				},
				{
					icon: 'mdi-account-cancel',
					name: 'banned_users_menu',
					link: 'users/banned',
					color: 'red',
					allow_admin: true,
					allow_business: false,
					allow_employee: false,
				},
				{
					icon: 'mdi-bell',
					name: 'notifications',
					link: 'notifications',
					color: 'blue',
					allow_admin: true,
					allow_business: false,
					allow_employee: false,
				},
				{
					icon: 'mdi-account-cog',
					name: 'settings',
					link: 'settings',
					color: 'gray',
					allow_admin: true,
					allow_business: true,
					allow_employee: true,
				},
			],
		};
	},
	computed: {
		email: function() {
			return this.userData.email;
		},
		allowedLinks() {
			switch (this.userData.accessLevel) {
				case 'admin':
					return this.links.filter((link) => link.allow_admin);
				case 'business':
					return this.links.filter((link) => link.allow_business);
				case 'employee':
					return this.links.filter((link) => link.allow_employee);

				default:
					return this.links;
			}
		},
		orderButtonColor() {
			if (!this.orderButtonLoading) {
				return this.userData.business.available ? 'red' : 'green';
			}
			return 'blue';
		},
	},
	methods: {
		sendToView(item) {
			//console.log('------------------------------------');
			//console.log(this.$router.currentRoute);
			//console.log(item.link);
			if (this.$router.currentRoute.name != item.link) {
				this.$router.push(`/admin/${item.link}`);
			}
		},
		async getUserInfo() {
			try {
				const token = localStorage.getItem('weserv.access');
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + 'user/info', options).then(async (response) => {
					if (response.ok) {
						const data = await response.json();
						//console.log(data);
						localStorage.setItem('weserv.user', JSON.stringify(data));
						this.userData = data;
						this.ordersButtonLoading = false;
					} else if (response.status == 403) {
						localStorage.clear();
						this.$router.push('/login');
					}
				});
			} catch (error) {
				//console.log(error);
			}
		},
		async changeAvailabilityOfRestaurant() {
			try {
				this.ordersButtonLoading = true;
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				formData.append('available', !this.userData.business.available);

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				/* PUT request to edit status */
				await fetch(
					process.env.VUE_APP_API_URL + `business/${this.userData.business._id}/availability`,
					requestOptions,
				).then(async (response) => {
					if (response.ok) {
						const data = await response.json();
						this.userData.business = data;
					}
					this.ordersButtonLoading = false;
				});
			} catch (error) {
				//console.log(error);
				this.ordersButtonLoading = false;
			}
		},
	},
	mounted() {
		const theme = localStorage.getItem('weserv.useDarkTheme');
		const lang = localStorage.getItem('weserv.language');

		if (theme) {
			if (theme == 'true') {
				this.$vuetify.theme.dark = true;
			} else this.$vuetify.theme.dark = false;
		}
		if (lang) {
			//console.log(lang);
			this.$i18n.locale = lang;
		}
	},
	async created() {
		////console.log('FRAME A');
		await this.getUserInfo();
		////console.log('FRAME B');
	},
};
</script>

<style scoped>
a {
	text-decoration: none;
	color: black;
}
</style>
