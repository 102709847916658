<template>
	<div id="image-background" class="container-fluid image-background">
		<img class="logo" src="../assets/weserv_logo_white.png" width="280" alt="" />

		<!-- <div class="card-btn-login flex-column">
			<p class="text1">Inicio de sesión</p>
			<p class="text2">
				SUPERVISIÓN,<br />
				CONTROL Y CALIDAD
			</p>
			<p class="text1">una gran experiencia de venta</p>

			<v-btn @click="active = true" class="ml-auto" color="black">Inicia sesión</v-btn>
		</div> -->
		<v-row class="px-15" justify="end">
			<v-card max-width="500" class="blurred" color="transparent">
				<v-row justify="end">
					<v-radio-group class="mx-4 mt-4" v-on:change="languageChanged" v-model="radios" row>
						<v-radio color="black" value="en">
							<template v-slot:label>
								<div>
									<v-img class="rounded-circle" max-height="30" max-width="30" src="../assets/icons/en_US.png"> </v-img>
								</div>
							</template>
						</v-radio>
						<v-radio color="black" value="es">
							<template v-slot:label>
								<div>
									<v-img class="rounded-circle" max-height="30" max-width="30" src="../assets/icons/es_MX.png"> </v-img>
								</div>
							</template>
						</v-radio>
					</v-radio-group>
				</v-row>

				<v-card-title class="mb-3">
					<span class="text-h4 font-weight-bold white-text">{{
						!forgotPassword ? $t('login') : $t('password_reset')
					}}</span>
				</v-card-title>
				<v-card-subtitle>
					<span class="text-body-1 white-text ">{{
						!forgotPassword ? $t('enter_credentials') : $t('password_reset_message')
					}}</span>
				</v-card-subtitle>
				<v-card-text>
					<v-container>
						<v-alert
							v-model="alert"
							border="left"
							transition="slide-y-transition"
							close-text="Close Alert"
							:type="alertType"
							dismissible
						>
							{{ alertMessage }}
						</v-alert>
						<validation-observer ref="observer" v-slot="{ invalid }">
							<form @submit.prevent="submit">
								<validation-provider v-slot="{ errors }" :name="$t('email')" rules="required|email">
									<v-text-field
										color="black"
										v-model="email"
										:error-messages="errors"
										:label="$t('email')"
										autocomplete="off"
										required
									></v-text-field>
								</validation-provider>

								<validation-provider v-if="!forgotPassword" v-slot="{ errors }" :name="$t('password')" rules="required">
									<v-text-field
										color="black"
										v-model="password"
										:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
										:error-messages="errors"
										:type="show1 ? 'text' : 'password'"
										name="input-10-1"
										:label="$t('password')"
										autocomplete="off"
										@click:append="show1 = !show1"
									></v-text-field>
								</validation-provider>

								<v-row class="mt-5 ">
									<v-col cols="12" sm="12" md="4">
										<v-btn type="submit" :disabled="invalid" color="success">
											{{ !forgotPassword ? $t('log_in') : $t('reset') }}
										</v-btn>
									</v-col>
									<v-col cols="12" sm="12" md="6">
										<v-btn class="ml-1 " text @click="forgotPassword = !forgotPassword">
											<span class="white-text">{{ !forgotPassword ? $t('forgot_password') : $t('cancel') }} </span>
										</v-btn>
									</v-col>
								</v-row>
							</form>
						</validation-observer>
					</v-container>
					<v-dialog v-model="loadingDialog" hide-overlay persistent width="100">
						<v-card color="primary">
							<v-card-text class="text-center">
								<v-progress-circular indeterminate color="white" size="50" class="mt-4"> </v-progress-circular>
							</v-card-text>
						</v-card>
					</v-dialog>
				</v-card-text>
			</v-card>
		</v-row>
	</div>

	<!-- <div class="background">
		<v-container>
			<v-row justify="space-around">
				<v-img class="rounded-lg" max-height="350" max-width="350" src="../assets/logoWeserv.png"></v-img>
			</v-row>
			<v-row class="mt-10" justify="space-around">
				<v-card outlined elevation="2" width="600" class="pa-6" color="secondary">
					<v-alert
						v-model="alert"
						border="left"
						transition="slide-y-transition"
						close-text="Close Alert"
						:type="alertType"
						dismissible
					>
						{{ alertMessage }}
					</v-alert>
					<validation-observer ref="observer" v-slot="{ invalid }">
						<form @submit.prevent="submit">
							<validation-provider v-slot="{ errors }" name="Email" rules="required|email">
								<v-text-field v-model="email" :error-messages="errors" label="Email" required></v-text-field>
							</validation-provider>

							<validation-provider v-slot="{ errors }" name="Contraseña" rules="required">
								<v-text-field
									v-model="password"
									:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									:error-messages="errors"
									:type="show1 ? 'text' : 'password'"
									name="input-10-1"
									label="Contraseña"
									@click:append="show1 = !show1"
								></v-text-field>
							</validation-provider>

							<v-btn class="mr-4" type="submit" :disabled="invalid" color="primary">
								Iniciar Sesión
							</v-btn>
						</form>
					</validation-observer>
				</v-card>
			</v-row>
			<v-dialog v-model="loadingDialog" hide-overlay persistent width="100">
				<v-card color="primary" dark>
					<v-card-text class="text-center">
						<v-progress-circular indeterminate color="white" size="50" class="mt-4"> </v-progress-circular>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-container>
	</div> -->
</template>

<script>
import { required, digits, email, max } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('digits', {
	...digits,
});

extend('required', {
	...required,
});

extend('max', {
	...max,
});

extend('email', {
	...email,
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	data: () => ({
		active: false,
		email: '',
		password: '',
		remember: false,
		show1: false,
		loadingDialog: false,
		radios: 'en',

		alertMessage: '',
		alert: false,
		alertType: 'error',
		forgotPassword: false,
	}),

	methods: {
		languageChanged() {
			localStorage.setItem('weserv.language', this.radios);
			this.$i18n.locale = this.radios;
		},
		submit() {
			this.$refs.observer.validate();
			//login
			if (this.forgotPassword) {
				// send password reset email
				this.sendResetRequest();
			} else {
				this.sendLogin();
			}
		},
		setupAlert(message, type) {
			this.alert = true;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 4000);
		},
		clear() {
			this.name = '';
			this.phoneNumber = '';
			this.password = '';
			this.email = '';
			this.select = null;
			this.checkbox = null;
			this.$refs.observer.reset();
		},
		async sendLogin() {
			try {
				this.loadingDialog = true;
				const formData = new FormData();
				formData.append('email', this.email);
				formData.append('password', this.password);

				const requestOptions = {
					method: 'POST',
					body: formData,
				};
				this.clear();
				//console.log(process.env.VUE_APP_API_URL + 'user/login/');
				await fetch(process.env.VUE_APP_API_URL + 'user/login/', requestOptions).then(async (response) => {
					const json = await response.json();
					this.loadingDialog = false;
					if (response.status > 300 || response.status < 200) {
						throw new Error('Email or password are incorrect.');
					}

					if (!json) {
						throw new Error("Data couldn't be parsed");
					}
					//console.log(json);

					localStorage.setItem('weserv.access', json.access);
					localStorage.setItem('weserv.refresh', json.refresh);
					////console.log(JSON.parse(json.user));
					localStorage.setItem('weserv.userData', json.user);

					const user = await JSON.parse(json.user);
					if (!user) {
						throw new Error('Error parsing User object');
					}
					/* SEND USERS BASED ON THEIR ACCESS LEVELS TO DIFFERENT PAGES */
					if (localStorage.getItem('weserv.access') != null) {
						switch (user.accessLevel) {
							case 'admin':
								this.$router.push('/admin/stadiums');
								break;

							case 'business':
								this.$router.push('/admin/business');
								break;

							case 'employee':
								this.$router.push('/admin/orders');
								break;

							default:
								break;
						}
					}
				});
			} catch (error) {
				/* ERROR HANDLING */
				//console.log(error);
				//show Alert

				this.setupAlert(error.message, 'error');
				this.loadingDialog = false;
				////console.log('EROOOROROR');
			}
		},
		async sendResetRequest() {
			try {
				if (!this.email) {
					throw new Error(this.$i18n.t('error_no_email'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('email', this.email);

				const requestOptions = {
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'api/user/forget/password/', requestOptions).then(
					async (response) => {
						if (response.ok) {
							this.setupAlert(this.$i18n.t('password_reset_sent_msg'), 'success');
						} else {
							//const data = await response.json();
							this.setupAlert(this.$i18n.t('no_user_email'), 'error');
						}

						//response.json();
					},
				);
			} catch (error) {
				////console.log('??');
				//console.log(error);
				this.setupAlert(error, 'error');
			}
		},
	},
	mounted() {
		const lang = localStorage.getItem('weserv.language');
		if (lang) {
			this.radios = lang;
			this.$i18n.locale = lang;
		}
	},
	created() {
		//console.log(process.env.VUE_APP_API_URL);
	},
};
</script>

<style scoped>
.container-fluid {
	width: 100vw;
	height: 100vh;
}
.image-background {
	background-image: linear-gradient(to left, rgba(245, 246, 252, 0.52), rgba(0, 0, 0, 0.73)),
		url('../assets/login_bg_2.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.logo {
	padding: 30px;
}

.blurred {
	border-radius: 5px;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
}

@media (max-width: 477px) {
	.blurred {
		width: 100%;
	}
}

@media (max-width: 675px) {
	.white-text {
		color: #fff;
	}
}
</style>
