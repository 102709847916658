<template>
	<v-card class="" elevation="0" color="">
		<DataTable />
	</v-card>
</template>

<script>
import DataTable from '../../../components/tables/UserReportsDataTable.vue';

export default {
	name: 'Reports',
	components: {
		DataTable,
	},
};
</script>
