<template>
	<v-card class="" elevation="0" color="">
		<DataTable />
	</v-card>
</template>

<script>
import DataTable from '../../../components/tables/BannedUsersDataTable.vue';

export default {
	name: 'bannedUsers',
	components: {
		DataTable,
	},
};
</script>
