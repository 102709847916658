<template>
	<v-card class="" elevation="0" color="">
		<BusinessForm />
	</v-card>
</template>

<script>
import BusinessForm from '../../../components/Dashboard.vue';

export default {
	name: 'Business',
	components: {
		BusinessForm
	}
};
</script>
