<template>
	<v-card class="" elevation="0" color="">
		<DataTable />
	</v-card>
</template>

<script>
import DataTable from '../../../components/tables/stadiumSeats/SectionsDataTable.vue';

export default {
	name: 'Sections',
	components: {
		DataTable
	}
};
</script>
