<template>
	<div>
		<v-alert
			v-model="alert"
			border="left"
			transition="slide-y-transition"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>
		<!-- <v-row justify="center"> -->
		<v-container>
			<v-card outlined class="text-center " :loading="loadingDialog">
				<v-card-title>
					{{ $t('notifications') }}
				</v-card-title>

				<v-card-text>
					<!-- INPUTS  -->

					<validation-observer ref="observer" v-slot="{ invalid }">
						<form @submit.prevent="submit">
							<v-row justify="center">
								<v-col cols="12" sm="12">
									<validation-provider v-slot="{ errors }" :name="$t('venue')" rules="required">
										<v-select
											:items="stadiums"
											item-text="name"
											item-value="_id"
											v-model="notification.stadium"
											:error-messages="errors"
											filled
											:label="$t('venue')"
											:disabled="loadingDialog"
										></v-select>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row justify="center">
								<v-col cols="12" sm="12">
									<validation-provider v-slot="{ errors }" :name="$t('range')" rules="required|numeric">
										<v-text-field
											v-model="notification.range"
											:error-messages="errors"
											:label="$t('range')"
											filled
											:disabled="loadingDialog"
										></v-text-field>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row justify="center">
								<v-col cols="12" sm="12">
									<validation-provider v-slot="{ errors }" :name="$t('title')" rules="required">
										<v-text-field
											v-model="notification.title"
											:error-messages="errors"
											:label="$t('title')"
											filled
											:disabled="loadingDialog"
										></v-text-field>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row justify="center">
								<v-col cols="12" sm="12">
									<validation-provider v-slot="{ errors }" :name="$t('message')" rules="required">
										<v-textarea
											filled
											clearable
											clear-icon="mdi-close-circle"
											counter
											:label="$t('message')"
											:error-messages="errors"
											v-model="notification.message"
											:disabled="loadingDialog"
										></v-textarea>
									</validation-provider>
								</v-col>
							</v-row>

							<v-container class="mt-5">
								<v-btn class="mr-4" color="error" @click="clear">
									{{ $t('clear') }}
								</v-btn>
								<v-btn type="submit" color="success" :disabled="invalid" @click="send">
									{{ $t('send') }}
								</v-btn>
							</v-container>
						</form>
					</validation-observer>
				</v-card-text>
			</v-card>
			<!-- </v-row> -->
		</v-container>
	</div>
</template>

<script>
import { required, alpha, numeric } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
setInteractionMode('eager');

extend('alpha', {
	...alpha,
});
extend('required', {
	...required,
});
extend('numeric', {
	...numeric,
});
export default {
	components: { ValidationProvider, ValidationObserver },
	data: () => ({
		// Alert
		alert: false,
		alertType: 'error',
		alertMessage: '',

		//LOADING DIALOG
		loadingDialog: false,

		stadiums: [],

		notification: {
			stadium: '',
			range: 0,
			message: '',
			title: '',
		},
		defaultNotification: {
			stadium: '',
			range: 0,
			message: '',
			title: '',
		},
	}),
	methods: {
		submit() {
			this.$refs.observer.validate();
		},
		clear() {
			this.notification = Object.assign({}, this.defaultNotification);
			this.$refs.observer.reset();
		},
		async send() {
			//activate loader
			this.loadingDialog = true;
			//await send POST REQUEST
			await this.sendNotification();
			// clear()
			this.clear();
			// hide loader
			this.loadingDialog = false;
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},
		async getStadiums() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);

				await fetch(process.env.VUE_APP_API_URL + 'stadium/', options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data.data);
						this.stadiums = data.data;
					});
			} catch (error) {
				//console.log(error);

				this.setupAlert(true, error, 'error');
			}
		},
		async sendNotification() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('stadium', this.notification.stadium);
				formData.append('range', this.notification.range);
				formData.append('title', this.notification.title);
				formData.append('message', this.notification.message);

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'notifications/', requestOptions).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_notification_sent'), 'success');
					}
					//response.json();
				});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		this.getStadiums();
	},
};
</script>

<style scoped>
/* >>>.loader {
	align-self: flex-center;
} */
</style>
