<template>
	<v-card :elevation="0" color="transparent">
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>

		<v-row align="center">
			<v-btn class="ml-4" color="primary" @click="back">{{ $t('back') }}</v-btn>
			<v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
		</v-row>

		<v-card-title class="font-weight-bold">
			<h1>{{ $t('seats') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>
		<v-row class="py-3" align="center">
			<h2 class="text- ml-5">
				<span class="font-weight-light">{{ $t('venue') }}:</span> {{ row.section.stadium.name }}
			</h2>
			<h2 class="text-h6  ml-5">~</h2>
			<h2 class="text-h6  ml-5">
				<span class="font-weight-light">{{ $t('section') }}:</span> {{ row.section.name }}
			</h2>
			<h2 class="text-h6  ml-5">~</h2>
			<h2 class="text-h6  ml-5">
				<span class="font-weight-light">{{ $t('row') }}:</span> {{ row.name }}
			</h2>
		</v-row>

		<v-card outlined class="">
			<v-data-table
				:headers="tableHeaders"
				:items="seats"
				:items-per-page="30"
				:search="search"
				:loading="loadingTable"
				multi-sort
				calculate-widths
			>
				<template v-slot:top>
					<v-toolbar flat color="transparent">
						<v-spacer></v-spacer>
						<v-dialog v-model="dialog" max-width="600px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="primary" dark class=" mb-2" v-bind="attrs" v-on="on">
									{{ $t('add_seat') }}
								</v-btn>
							</template>
							<v-card>
								<v-card-title class="d-flex justify-center">
									<span class="headline">{{ formTitle }}</span>
								</v-card-title>

								<v-card-text class="d-flex justify-center my-5">
									<!-- INPUTS  -->

									<validation-observer ref="observer" v-slot="{ invalid }">
										<form @submit.prevent="submit">
											<v-row justify="center">
												<v-col cols="6" sm="12">
													<validation-provider v-slot="{ errors }" :name="$t('name')" rules="required">
														<v-text-field
															v-model="editedItem.name"
															:error-messages="errors"
															:label="$t('name')"
															filled
															required
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="6" sm="12">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('priority')"
														:rules="{ required: true, min_value: 0, max_value: 100 }"
													>
														<v-text-field
															v-model="editedItem.priority"
															:error-messages="errors"
															:label="$t('priority')"
															filled
															required
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>

											<v-container class="mt-5">
												<v-btn class="mr-4" color="error" @click="close">
													{{ $t('close') }}
												</v-btn>
												<v-btn type="submit" color="success" :disabled="invalid" @click="save">
													{{ AddButtonText }}
												</v-btn>
											</v-container>
										</form>
									</validation-observer>
								</v-card-text>

								<!-- <v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" @click="close">
									Cancel
								</v-btn>
								<v-btn color="success" @click="save">
									{{ AddButtonText }}
								</v-btn>
							</v-card-actions> -->
							</v-card>
						</v-dialog>
						<v-dialog v-model="dialogDelete" max-width="600px">
							<v-card>
								<v-card-title class="headline justify-center">{{ $t('delete_dialog_confirm_seat') }}</v-card-title>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
									<v-btn color="error" text @click="deleteItemConfirm">OK</v-btn>
									<v-spacer></v-spacer>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<!-- <template v-slot:[`item.birthday`]="{ item }">
				{{ getDateFormat(item.birthday) }}
			</template> -->
				<!-- <template v-slot:[`item.image`]="{ item }">
				<v-avatar class="ma-1">
					<img :src="getImage(item.image)" alt="no image" />
				</v-avatar>
			</template> -->
				<!-- <template v-slot:[`item.total_given`]="{ item }">
				<v-chip :color="getColor(item.total_given)" dark>
					{{ item.total_given }}
				</v-chip>
			</template> -->
				<!-- <template v-slot:[`item.available`]="{ item }">
				<v-chip :color="getColorActive(item.available)" dark>
					{{ item.available ? 'Available' : 'Unavailable' }}
				</v-chip>
			</template>
			<template v-slot:[`item.hasAlcohol`]="{ item }">
				<v-chip :color="getColorActive(item.hasAlcohol)" dark>
					{{ item.hasAlcohol ? 'Yes' : 'No' }}
				</v-chip>
			</template> -->
				<!-- <template v-slot:[`item.gender`]="{ item }">
				<v-chip :color="getColorGender(item.gender)" dark>
					{{ item.gender === 'female' ? 'Mujer' : 'Hombre' }}
				</v-chip>
			</template> -->
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon small class="mr-2" @click="editItem(item)">
						mdi-pencil
					</v-icon>
					<v-icon small @click="deleteItem(item)">
						mdi-delete
					</v-icon>
				</template>
				<template v-slot:no-data>
					<v-sheet class="my-10" color="">
						<v-Seat justify="center">
							<v-sheet color="">
								<h2 class="text-h2 pa-3">{{ $t('no_seats') }}</h2>
							</v-sheet>
						</v-Seat>
						<v-row justify="center">
							<v-btn class="ma-4" color="primary" @click="getSeats">{{ $t('refresh') }}</v-btn>
						</v-row>
					</v-sheet>
				</template>
			</v-data-table>
		</v-card>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';

import { required, alpha, numeric, max_value, min_value } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
setInteractionMode('eager');

extend('numeric', {
	...numeric,
});

extend('alpha', {
	...alpha,
});
extend('required', {
	...required,
});

extend('max_value', {
	...max_value,
});
extend('min_value', {
	...min_value,
});

export default {
	components: { ValidationProvider, ValidationObserver },
	data() {
		return {
			search: '',
			dialog: false,
			dialogDelete: false,
			/* NEW CATEGORY VARS */
			// newImage: null,
			// imageUrl: null,
			loadingTable: false,

			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',
			row: {
				_id: '',
				name: '',
				section: {
					_id: '',
					name: '',
					stadium: {
						_id: '',
						name: '',
					},
				},
			},
			start: 0,
			end: 0,
			seats: [],
			editedIndex: -1,
			editedItem: {
				_id: '',
				name: '',
				priority: 0,
			},

			defaultItem: {
				_id: '',
				name: '',
				priority: 0,
			},
			numberRule: (v) => {
				//if (!v.trim()) return true;
				if (!isNaN(parseFloat(v)) && v >= 0) return true;
				return 'The number has to be greater than 0';
			},
			descriptionRules: (v) => {
				//if (!v.trim()) return true;
				if (!v || v.length <= 130) return true;
				return 'Max 130 characters';
			},
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? this.$i18n.t('new_seat') : this.$i18n.t('edit_seat');
		},
		AddButtonText() {
			return this.editedIndex === -1 ? this.$i18n.t('add') : this.$i18n.t('save');
		},
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},

		breadcrumbs() {
			return [
				{
					text: this.$i18n.t('venues'),
					disabled: false,
					href: '/admin/stadiums',
				},
				{
					text: this.$i18n.t('sections'),
					disabled: false,
					href: `/admin/stadium/${this.row.section.stadium._id}/sections`,
				},
				{
					text: this.$i18n.t('rows'),
					disabled: false,
					href: `/admin/section/${this.row.section._id}/rows`,
				},
				{
					text: this.$i18n.t('seats'),
					disabled: true,
					href: ``,
				},
			];
		},
		tableHeaders() {
			var headers = [
				{ text: this.$i18n.t('name'), value: 'name' },
				{ text: this.$i18n.t('priority'), value: 'priority' },
				{ text: this.$i18n.t('actions'), value: 'actions', sortable: false },
			];

			return headers;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		editItem(item) {
			this.editedIndex = this.seats.indexOf(item);
			this.editedItem = Object.assign({}, item);

			this.dialog = true;
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},

		deleteItem(item) {
			this.editedIndex = this.seats.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.deleteSeat();
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
			this.start = 0;
			this.end = 0;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				//edit
				//Object.assign(this.people[this.editedIndex], this.editedItem);
				this.editSeat();
			} else {
				//add
				//this.people.push(this.editedItem);
				this.addSeat();
			}
			this.close();
		},
		back() {
			this.$router.push(`/admin/section/${this.row.section._id}/rows`);
		},

		// NETWORK LOGIC

		async getSeats() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				//const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				// if (!user) {
				// 	throw new Error(this.$i18n.t('error_msg_no_user'));
				// }

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				const rowID = this.$route.params.id;

				if (!rowID) {
					throw new Error(this.$i18n.t('no_id'));
				}

				const rowsURL = `stadium/row/${rowID}/seats`;
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + rowsURL, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						////console.log(data);
						this.seats = data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
				this.loadingTable = false;
			}
		},

		async getRow() {
			try {
				const token = localStorage.getItem('weserv.access');
				//const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				// if (!user) {
				// 	throw new Error(this.$i18n.t('error_msg_no_user'));
				// }

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				const rowID = this.$route.params.id;

				if (!rowID) {
					throw new Error(this.$i18n.t('no_id'));
				}

				const stadiumURL = `stadium/row/${rowID}`;
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + stadiumURL, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						//console.log(data);
						this.row = data;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},

		async addSeat() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('name', this.editedItem.name);
				formData.append('row', this.row._id);
				formData.append('priority', this.editedItem.priority);

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'stadium/seat/', requestOptions).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_seat_added'), 'success');
					}
					//response.json();
				});
				this.getSeats();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async editSeat() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				formData.append('name', this.editedItem.name);
				formData.append('row', this.row._id);
				formData.append('priority', this.editedItem.priority);

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + `stadium/seat/${this.editedItem._id}`, requestOptions).then(
					(response) => {
						if (response.ok) {
							this.setupAlert(true, this.$i18n.t('alert_msg_seat_updated'), 'success');
						}
					},
				);
				this.getSeats();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async deleteSeat() {
			try {
				const token = localStorage.getItem('weserv.access');

				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				await fetch(process.env.VUE_APP_API_URL + `stadium/seat/${this.editedItem._id}`, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'DELETE',
				}).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_seat_deleted'), 'success');
					}
				});
				this.getSeats();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		this.getSeats();
		this.getRow();
	},
};
</script>

<style scoped></style>
