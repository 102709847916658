<template>
	<v-card :elevation="0" color="transparent">
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>
		<v-card-title class="font-weight-bold">
			<h1>{{ $t('businesses') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>
		<v-data-table
			:headers="tableHeaders"
			:items="businesses"
			:items-per-page="10"
			:search="search"
			multi-sort
			:loading="loadingTable"
			calculate-widths
		>
			<template v-slot:top>
				<v-toolbar flat color="transparent">
					<v-spacer></v-spacer>
					<v-dialog persistent v-model="dialog" max-width="1000px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
								{{ $t('add_business') }}
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="headline">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<!-- INPUTS  -->
								<v-container>
									<validation-observer ref="observer" v-slot="{ invalid }">
										<form @submit.prevent="submit">
											<v-row>
												<v-col cols="12" sm="8">
													<validation-provider v-slot="{ errors, validate }" name="Image" :rules="imageRules">
														<v-file-input
															@change="onChange($event, validate)"
															@click:clear="resetImage"
															:label="$t('image')"
															required
															:error-messages="errors"
															v-model="newImage"
															accept="image/png, image/jpeg"
															filled
															prepend-icon="mdi-camera"
														></v-file-input>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="4">
													<v-img v-if="imageUrl" :src="imageUrl" max-height="150" class="rounded-lg"></v-img>
												</v-col>
											</v-row>
											<v-row justify="space-around">
												<v-col cols="12" sm="6">
													<validation-provider v-slot="{ errors }" :name="$t('name')" rules="required">
														<v-text-field
															v-model="editedItem.name"
															:error-messages="errors"
															:label="$t('name')"
															filled
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="6">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('phone_number')"
														:rules="{
															required: true,
															numeric: true,
															digits: 10,
														}"
													>
														<v-text-field
															v-model="editedItem.phone"
															:counter="10"
															:error-messages="errors"
															:label="$t('phone_number')"
															filled
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>
											<v-row justify="space-around">
												<v-col cols="12" sm="12">
													<validation-provider v-slot="{ errors }" :name="$t('description')" rules="required">
														<v-textarea
															filled
															clearable
															clear-icon="mdi-close-circle"
															counter
															:label="$t('description')"
															:error-messages="errors"
															v-model="editedItem.description"
														></v-textarea>
													</validation-provider>
												</v-col>
											</v-row>
											<v-row justify="space-around">
												<v-col cols="12" sm="6" md="4">
													<validation-provider v-slot="{ errors }" :name="$t('payment_methods')" rules="required">
														<v-select
															v-model="editedItem.paymentMethods"
															:items="filteredPaymentMethods"
															filled
															chips
															:label="$t('payment_methods')"
															multiple
															:error-messages="errors"
															item-text="keyUS"
															return-object
														></v-select>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="6" md="4">
													<validation-provider v-slot="{ errors }" :name="$t('delivery_methods')" rules="required">
														<v-select
															v-model="editedItem.deliveryMethods"
															:items="deliveryMethods"
															filled
															chips
															:label="$t('delivery_methods')"
															multiple
															:error-messages="errors"
															item-text="keyUS"
															return-object
														></v-select>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="6" md="4">
													<validation-provider v-slot="{ errors }" :name="$t('venue')" rules="required">
														<v-select
															v-model="editedItem.stadium._id"
															:items="stadiums"
															filled
															chips
															:label="$t('venue')"
															:error-messages="errors"
															item-text="name"
															item-value="_id"
														></v-select>
													</validation-provider>
												</v-col>
											</v-row>
											<v-row justify="center">
												<v-col cols="12" sm="4">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('fee')"
														:rules="{
															required: true,
															decimal: true,
															max: 6,
															min_value: 0.1,
														}"
													>
														<v-text-field
															v-model="editedItem.weservFee"
															filled
															:label="$t('fee')"
															:error-messages="errors"
															clearable
														></v-text-field>
													</validation-provider>
												</v-col>

												<v-col cols="12" sm="8" v-if="isEditing">
													<v-text-field
														v-model="editedItem.manager.email"
														filled
														:label="$t('manager_email')"
														:disabled="true"
													></v-text-field>
												</v-col>
											</v-row>

											<v-card outlined class="pa-3" v-if="!isEditing">
												<h2 class="text-h5 text-center pa-3">{{ $t('business_manager') }}</h2>
												<v-row>
													<v-col cols="12" sm="6">
														<validation-provider v-slot="{ errors }" :name="$t('name')" rules="required|alpha">
															<v-text-field
																v-model="newUser.firstname"
																:error-messages="errors"
																:label="$t('name')"
																filled
																required
															></v-text-field>
														</validation-provider>
													</v-col>
													<v-col cols="12" sm="6">
														<validation-provider v-slot="{ errors }" :name="$t('last_name')" rules="required|alpha">
															<v-text-field
																v-model="newUser.lastname"
																:error-messages="errors"
																:label="$t('last_name')"
																filled
																required
															></v-text-field>
														</validation-provider>
													</v-col>
												</v-row>
												<v-row justify="center">
													<v-col cols="12" sm="6">
														<validation-provider v-slot="{ errors }" :name="$t('email')" rules="required|email">
															<v-text-field
																v-model="newUser.email"
																:error-messages="errors"
																:label="$t('email')"
																filled
																:disabled="isEditing"
																required
															></v-text-field>
														</validation-provider>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" sm="6">
														<validation-provider
															v-slot="{ errors }"
															:name="$t('password')"
															:rules="{
																required: true,
																regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!.@$%^&*-]).*$/,
															}"
														>
															<v-text-field
																v-model="newUser.password"
																:error-messages="errors"
																:label="$t('password')"
																required
															></v-text-field>
														</validation-provider>
													</v-col>
													<v-col cols="12" sm="6">
														<validation-provider
															v-slot="{ errors }"
															vid="confirmation"
															:name="$t('confirm_password')"
															:rules="{ required: true, is: newUser.password }"
														>
															<v-text-field
																v-model="newUser.confirmation"
																:error-messages="errors"
																:label="$t('confirm_password')"
																:counter="passwordLength"
																required
															></v-text-field>
														</validation-provider>
													</v-col>
												</v-row>
											</v-card>

											<v-row>
												<v-col cols="12" sm="2">
													<v-switch v-model="editedItem.available" inset :label="$t('available')"></v-switch>
												</v-col>
												<v-col cols="12" sm="2" v-if="isEditing">
													<v-switch
														v-model="editedItem.admin_available"
														inset
														:label="$t('overwrite_available')"
													></v-switch>
												</v-col>
											</v-row>
											<v-container class="mt-5">
												<v-btn class="mr-4" color="error" @click="close">
													{{ $t('close') }}
												</v-btn>
												<v-btn type="submit" color="success" :disabled="invalid" @click="save">
													{{ AddButtonText }}
												</v-btn>
											</v-container>
										</form>
									</validation-observer>
								</v-container>
							</v-card-text>

							<!-- <v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" @click="close">
									Cancel
								</v-btn>
								<v-btn color="success" @click="save">
									{{ AddButtonText }}
								</v-btn>
							</v-card-actions> -->
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="600px">
						<v-card>
							<v-card-title class="headline justify-center">{{ $t('delete_dialog_confirm_business') }}</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
								<v-btn color="error" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<!-- <template class="col-3 text-truncate" v-slot:[`item.description`]="{ item }">
				spa{{ item.description }}
			</template> -->
			<template v-slot:[`item.image`]="{ item }">
				<v-avatar class="ma-1">
					<img :src="getImage(item.image)" alt="no image" />
				</v-avatar>
			</template>
			<!-- <template v-slot:[`item.total_given`]="{ item }">
				<v-chip :color="getColor(item.total_given)" dark>
					{{ item.total_given }}
				</v-chip>
			</template> -->
			<template v-slot:[`item.stripeId`]="{ item }">
				<v-chip :color="item.stripeId ? 'blue' : 'red'" dark>
					{{ item.stripeId ? $t('yes') : 'No' }}
				</v-chip>
			</template>
			<template v-slot:[`item.available`]="{ item }">
				<v-chip :color="getColorActive(item.available)" dark>
					{{ item.available ? $t('available') : $t('unavailable') }}
				</v-chip>
			</template>

			<template v-slot:[`item.rating`]="{ item }">
				<v-rating
					:value="item.rating"
					length="5"
					color="amber"
					background-color="grey lighten-1"
					readonly
					size="14"
				></v-rating>
			</template>
			<!-- <template v-slot:[`item.gender`]="{ item }">
				<v-chip :color="getColorGender(item.gender)" dark>
					{{ item.gender === 'female' ? 'Mujer' : 'Hombre' }}
				</v-chip>
			</template> -->
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="mr-2" @click="editItem(item)">
					mdi-pencil
				</v-icon>
				<v-icon small @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>
			<template v-slot:no-data>
				<v-sheet class="my-10" color="">
					<v-row justify="center">
						<v-sheet color="">
							<h2 class="text-h2 pa-3">{{ $t('no_businesses') }}</h2>
						</v-sheet>
					</v-row>
					<v-row justify="center">
						<v-btn class="ma-4" color="primary" @click="getBusinesses">{{ $t('refresh') }}</v-btn>
					</v-row>
				</v-sheet>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';
import moment from 'moment';

import { required, digits, email, max, alpha, numeric, is, image, double, min_value } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('digits', {
	...digits,
});

extend('double', {
	...double,
});
extend('min_value', {
	...min_value,
});
extend('image', {
	...image,
});

extend('alpha', {
	...alpha,
});

extend('numeric', {
	...numeric,
});

extend('required', {
	...required,
});

extend('max', {
	...max,
});

extend('is', {
	...is,
});

extend('email', {
	...email,
});

export default {
	components: { ValidationProvider, ValidationObserver },
	data() {
		return {
			search: '',
			dialog: false,
			dialogDelete: false,
			/* NEW PRODUCT VARS */
			newImage: null,
			imageUrl: null,

			loadingTable: false,

			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',

			newUser: {
				confirmation: '',
				password: '',
				email: '',
				firstname: '',
				lastname: '',
			},
			defaultUser: {
				confirmation: '',
				password: '',
				email: '',
				firstname: '',
				lastname: '',
			},

			stadiums: [{ _id: '', name: '' }],
			deliveryMethods: [],
			paymentMethods: [],
			businesses: [],
			editedIndex: -1,
			editedItem: {
				name: '',
				description: '',
				phone: '',
				stripeId: '',
				stadium: {
					_id: '',
					name: '',
				},
				manager: {
					email: '',
				},
				deliveryMethods: [],
				paymentMethods: [],
				weservFee: '',
				available: false,
				image: '',
			},
			defaultItem: {
				name: '',
				description: '',
				phone: '',
				stripeId: '',
				stadium: {
					_id: '',
					name: '',
				},
				manager: {
					email: '',
				},
				deliveryMethods: [],
				paymentMethods: [],
				weservFee: '',
				available: false,
				image: '',
			},
			numberRule: (v) => {
				//if (!v.trim()) return true;
				if (!isNaN(parseFloat(v)) && v >= 0) return true;
				return 'The number has to be greater than 0';
			},
			descriptionRules: (v) => {
				//if (!v.trim()) return true;
				if (!v || v.length <= 130) return true;
				return 'Max 130 characters';
			},
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? this.$i18n.t('new_business') : this.$i18n.t('edit_business');
		},
		AddButtonText() {
			return this.editedIndex === -1 ? this.$i18n.t('add') : this.$i18n.t('save');
		},
		imageRules() {
			return this.editedIndex === -1 ? 'required|image' : 'image';
		},
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},
		tableHeaders() {
			const headers = [
				{ text: this.$i18n.t('venue'), value: 'stadium.name', align: 'center' },
				{
					text: this.$i18n.t('image'),
					align: 'start',
					sortable: false,
					value: 'image',
				},
				{ text: this.$i18n.t('name'), value: 'name' },
				{ text: 'Description', value: 'description', sortable: false },

				{ text: this.$i18n.t('phone'), value: 'phone' },
				{ text: this.$i18n.t('fee'), value: 'weservFee', align: 'center' },
				{ text: this.$i18n.t('rating'), value: 'rating', align: 'center' },
				{ text: this.$i18n.t('card_setup'), value: 'stripeId', align: 'center', sortable: false },
				{ text: this.$i18n.t('availability'), value: 'available', align: 'center' },

				//{ text: '# Asistencias', value: 'total_assistance', align: 'center' },

				{ text: this.$i18n.t('actions'), value: 'actions', sortable: false },
			];
			return headers;
		},
		passwordLength() {
			return this.newUser.password.length ?? 0;
		},
		filteredCategories() {
			////console.log(this.editedItem.restaurant._id);
			const categories = this.categories.filter((category) => {
				////console.log(category.restaurant._id);
				return category.restaurant._id == this.editedItem.restaurant._id;
			});

			return categories;
		},
		filteredPaymentMethods() {
			var filteredPaymentMethods;
			if (this.editedIndex == -1 || !this.editedItem.stripeId) {
				//	//console.log('FILTER NOT ID');
				filteredPaymentMethods = this.paymentMethods.filter((method) => {
					////console.log(method.keyUS);
					return method.keyUS === 'CASH';
				});
				////console.log(filteredPaymentMethods);
				return filteredPaymentMethods;
			}
			return this.paymentMethods;
		},
		isEditing() {
			return this.editedIndex != -1;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		submit() {
			this.$refs.observer.validate();
			//login
		},
		getColorActive(active) {
			if (!active) return 'red';
			else return 'green';
		},

		getPaymentDate(date) {
			return moment(date).format('L');
		},
		getImage(name) {
			////console.log(process.env.VUE_APP_IMAGE_URL + name);
			return process.env.VUE_APP_IMAGE_URL + name;
		},
		onChange(event, validate) {
			this.onFileChange(event);
			validate();
		},
		onFileChange(e) {
			if (e) {
				const file = e;
				this.imageUrl = URL.createObjectURL(file);
				this.newImage = file;
			}
		},
		resetImage() {
			if (this.editedItem.image) {
				this.imageUrl = this.getImage(this.editedItem.image);
			} else {
				this.imageUrl = null;
			}

			this.newImage = null;
		},
		editItem(item) {
			this.editedIndex = this.businesses.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.imageUrl = this.getImage(this.editedItem.image);
			this.dialog = true;
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},
		getDateFormat(string) {
			return moment(string).format('DD-MMM-YYYY');
		},

		deleteItem(item) {
			this.editedIndex = this.businesses.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.deleteBusiness();
			this.closeDelete();
		},

		close() {
			//console.log('AaaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAe');
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.newUser = Object.assign({}, this.defaultUser);
				this.editedIndex = -1;
				this.image = '';
				this.newImage = null;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		async save() {
			if (this.editedIndex > -1) {
				//edit
				//Object.assign(this.people[this.editedIndex], this.editedItem);
				await this.editBusiness();
			} else {
				//add
				//console.log('-0000000000000000000000000000----');
				//console.log(this.editedItem);
				//this.people.push(this.editedItem);
				await this.addBusiness(this.editedItem);
			}
			this.dialog = false;
			//this.close();
		},

		// NETWORK LOGIC

		async getPaymentMethods() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `payment/paymentMethods?stripe=true`, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data);
						this.paymentMethods = data;
					});
			} catch (error) {
				////console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async getDeliveryMethods() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `payment/deliveryMethods`, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data);
						this.deliveryMethods = data;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},

		async getStadiums() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);

				await fetch(process.env.VUE_APP_API_URL + 'stadium/', options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data.data);
						this.stadiums = data.data;
					});
			} catch (error) {
				//console.log(error);

				this.setupAlert(true, error, 'error');
			}
		},

		async getBusinesses() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				await fetch(process.env.VUE_APP_API_URL + 'business/', options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data.data);
						this.businesses = data.data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.loadingTable = false;
				this.setupAlert(true, error, 'error');
			}
		},

		async registerManager() {
			const token = localStorage.getItem('weserv.access');
			const user = JSON.parse(localStorage.getItem('weserv.user'));
			if (!token) {
				throw new Error(this.$i18n.t('error_msg_no_token'));
			}
			if (!user) {
				throw new Error(this.$i18n.t('error_msg_no_user'));
			}

			const formData = new FormData();
			//formData.append('uuid', uuidv4());

			formData.append('firstname', this.newUser.firstname);
			formData.append('lastname', this.newUser.lastname);
			formData.append('email', this.newUser.email);
			formData.append('phone', this.editedItem.phone);
			formData.append('password', this.newUser.password);

			const requestOptions = {
				headers: {
					Authorization: 'Bearer ' + token,
				},
				method: 'POST',
				body: formData,
			};

			const _id = await fetch(process.env.VUE_APP_API_URL + 'user/register', requestOptions).then(async (response) => {
				const data = await response.json();
				if (response.ok) {
					//console.log('------------------');
					//console.log(data);
					return data._id;
				} else {
					this.setupAlert(true, data.msg, 'error');
					return undefined;
				}

				//response.json();
			});
			return _id;
		},

		async addBusiness(business) {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				////console.log(t)
				const managerId = await this.registerManager();
				//console.log(managerId);

				if (!managerId) {
					throw new Error(this.$i18n.t('error_msg_no_manager'));
				}

				//console.log(business, image);
				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('name', business.name);
				formData.append('description', business.description);
				formData.append('phone', business.phone);
				formData.append('weservFee', business.weservFee);
				formData.append('deliveryMethods', JSON.stringify(business.deliveryMethods));
				formData.append('paymentMethods', JSON.stringify(business.paymentMethods));
				formData.append('stadium', business.stadium._id);
				formData.append('manager', managerId);
				formData.append('available', business.available);

				if (this.newImage) {
					formData.append('image', this.newImage);
				}
				//console.log('----====0------====------');

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'business/', requestOptions).then(async (response) => {
					const data = await response.json();
					//console.log(data);
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_business_added'), 'success');
					} else {
						this.setupAlert(true, data.msg, 'error');
					}

					//response.json();
				});
				this.getBusinesses();
			} catch (error) {
				////console.log('??');
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async editBusiness() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				formData.append('name', this.editedItem.name);
				formData.append('description', this.editedItem.description);
				formData.append('phone', this.editedItem.phone);
				formData.append('weservFee', this.editedItem.weservFee);
				formData.append('deliveryMethods', JSON.stringify(this.editedItem.deliveryMethods));
				formData.append('paymentMethods', JSON.stringify(this.editedItem.paymentMethods));
				formData.append('stadium', this.editedItem.stadium._id);
				formData.append('admin_available', this.editedItem.admin_available);

				formData.append('available', this.editedItem.available);
				if (this.newImage) {
					formData.append('image', this.newImage);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + `business/${this.editedItem._id}`, requestOptions).then(
					async (response) => {
						if (response.ok) {
							this.setupAlert(true, this.$i18n.t('alert_msg_business_updated'), 'success');
						} else {
							const data = await response.json();
							this.setupAlert(true, data.msg, 'error');
						}
					},
				);
				this.getBusinesses();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async deleteBusiness() {
			try {
				const token = localStorage.getItem('weserv.access');

				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				await fetch(process.env.VUE_APP_API_URL + `business/${this.editedItem._id}`, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'DELETE',
				}).then(async (response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_business_deleted'), 'success');
					} else {
						const data = await response.json();
						this.setupAlert(true, data.msg, 'error');
					}
				});
				this.getBusinesses();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		////console.log('B');

		this.getBusinesses();
		this.getStadiums();
		this.getPaymentMethods();
		this.getDeliveryMethods();

		////console.log('B2');
	},
};
</script>

<style scoped></style>
