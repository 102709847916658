<template>
	<v-card :elevation="0" color="transparent">
		<v-alert
			v-model="alert"
			border="left"
			transition="slide-y-transition"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>

		<v-card-title class="font-weight-bold">
			<h1>{{ $t('employees') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>
		<v-data-table
			:headers="tableHeaders"
			:items="employees"
			:items-per-page="10"
			:search="search"
			multi-sort
			:loading="loadingTable"
		>
			<template v-slot:top>
				<v-toolbar flat color="transparent">
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" persistent max-width="1000px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
								{{ $t('add_employee') }}
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="headline">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<!-- INPUTS  -->
								<v-container>
									<!-- <v-row>
										<v-col cols="12" sm="8">
											<v-file-input
												@change="onFileChange"
												@click:clear="resetImage"
												label="Image"
												required
												v-model="newImage"
												accept="image/png, image/jpeg"
												filled
												prepend-icon="mdi-camera"
											></v-file-input>
										</v-col>
										<v-col cols="12" sm="4">
											<v-img v-if="imageUrl" :src="imageUrl" max-height="150"></v-img>
										</v-col>
									</v-row> -->

									<validation-observer ref="observer" v-slot="{ invalid }">
										<form @submit.prevent="submit">
											<v-row>
												<v-col cols="12" sm="6">
													<validation-provider v-slot="{ errors }" :name="$t('name')" rules="required|alpha">
														<v-text-field
															v-model="editedItem.firstname"
															:error-messages="errors"
															:label="$t('name')"
															filled
															required
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="6">
													<validation-provider v-slot="{ errors }" :name="$t('last_name')" rules="required|alpha">
														<v-text-field
															v-model="editedItem.lastname"
															:error-messages="errors"
															:label="$t('last_name')"
															filled
															required
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" sm="6">
													<validation-provider v-slot="{ errors }" :name="$t('email')" rules="required|email">
														<v-text-field
															v-model="editedItem.email"
															:error-messages="errors"
															:label="$t('email')"
															filled
															:disabled="isEditing"
															required
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="6">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('phone_number')"
														:rules="{
															required: true,
															numeric: true,
															digits: 10,
														}"
													>
														<v-text-field
															v-model="editedItem.phone"
															:counter="10"
															:error-messages="errors"
															:label="$t('phone_number')"
															filled
															required
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>
											<v-row justify="center" v-if="accessLevel == 'admin'">
												<v-col cols="12" sm="12">
													<validation-provider v-slot="{ errors }" :name="$t('business')" rules="required">
														<v-select
															:items="businesses"
															item-text="name"
															item-value="_id"
															v-model="editedItem.business._id"
															:error-messages="errors"
															filled
															:label="$t('business')"
														></v-select>
													</validation-provider>
												</v-col>
											</v-row>
											<v-row v-if="!isEditing">
												<v-col cols="12" sm="6">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('password')"
														:rules="{
															required: true,
															regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!.@$%^&*-]).*$/,
														}"
													>
														<v-text-field
															v-model="password"
															:error-messages="errors"
															:label="$t('password')"
															required
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="6">
													<validation-provider
														v-slot="{ errors }"
														vid="confirmation"
														:name="$t('confirm_password')"
														:rules="{ required: true, is: password }"
													>
														<v-text-field
															v-model="confirmation"
															:error-messages="errors"
															:label="$t('confirm_password')"
															:counter="passwordLength"
															required
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>
											<v-container class="mt-5">
												<v-btn class="mr-4" color="error" @click="close">
													{{ $t('close') }}
												</v-btn>
												<v-btn type="submit" color="success" :disabled="invalid" @click="save">
													{{ addButtonLabel }}
												</v-btn>
											</v-container>
										</form>
									</validation-observer>
								</v-container>
							</v-card-text>

							<!-- <v-card-actions v-if="isEditing">
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="close">
									Cancelar
								</v-btn>
								<v-btn color="blue darken-1" text @click="save">
									Guardar
								</v-btn>
							</v-card-actions> -->
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="600px">
						<v-card>
							<v-card-title class="headline justify-center">{{ $t('delete_dialog_confirm_employee') }}</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
								<v-btn color="error" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<!-- <template v-slot:[`item.birthday`]="{ item }">
				{{ getDateFormat(item.birthday) }}
			</template> -->
			<!-- <template v-slot:[`item.image`]="{ item }">
				<v-avatar class="ma-1">
					<img :src="getImage(item.image)" alt="no image" />
				</v-avatar>
			</template> -->
			<!-- <template v-slot:[`item.total_given`]="{ item }">
				<v-chip :color="getColor(item.total_given)" dark>
					{{ item.total_given }}
				</v-chip>
			</template> -->
			<!-- <template v-slot:[`item.available`]="{ item }">
				<v-chip :color="getColorActive(item.available)" dark>
					{{ item.available ? 'Available' : 'Unavailable' }}
				</v-chip>
			</template> -->
			<!-- <template v-slot:[`item.hasAlcohol`]="{ item }">
				<v-chip :color="getColorActive(item.hasAlcohol)" dark>
					{{ item.hasAlcohol ? 'Yes' : 'No' }}
				</v-chip>
			</template> -->
			<!-- <template v-slot:[`item.gender`]="{ item }">
				<v-chip :color="getColorGender(item.gender)" dark>
					{{ item.gender === 'female' ? 'Mujer' : 'Hombre' }}
				</v-chip>
			</template> -->
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="mr-2" @click="editItem(item)">
					mdi-pencil
				</v-icon>
				<v-icon small @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>
			<template v-slot:no-data>
				<v-sheet class="my-10" color="">
					<v-row justify="center">
						<v-sheet color="">
							<h2 class="text-h2 pa-3">{{ $t('no_employees') }}</h2>
						</v-sheet>
					</v-row>
					<v-row justify="center">
						<v-btn class="ma-4" color="primary" @click="getEmployees">{{ $t('refresh') }}</v-btn>
					</v-row>
				</v-sheet>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';
import moment from 'moment';
import { required, digits, email, max, alpha, numeric, is } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('digits', {
	...digits,
});

extend('alpha', {
	...alpha,
});

extend('numeric', {
	...numeric,
});

extend('required', {
	...required,
});

extend('max', {
	...max,
});

extend('is', {
	...is,
});

extend('email', {
	...email,
});
export default {
	components: { ValidationProvider, ValidationObserver },
	data() {
		return {
			validForm: false,
			search: '',
			dialog: false,
			dialogDelete: false,

			/* NEW PRODUCT VARS */
			newImage: null,
			imageUrl: null,

			allowSpaces: false,
			password: '',
			confirmation: '',

			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',

			loadingTable: false,
			businesses: [],
			employees: [],
			editedIndex: -1,
			editedItem: {
				firstname: '',
				lastname: '',
				email: '',
				phone: '',
				business: {
					_id: '',
					name: '',
				},
			},
			defaultItem: {
				firstname: '',
				lastname: '',
				email: '',
				phone: '',
				business: {
					_id: '',
					name: '',
				},
			},
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? this.$i18n.t('new_employee') : this.$i18n.t('edit_employee');
		},
		addButtonLabel() {
			return this.editedIndex === -1 ? this.$i18n.t('add') : this.$i18n.t('save');
		},
		isEditing() {
			return this.editedIndex === -1 ? false : true;
		},
		passwordLength() {
			return this.password.length;
		},
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},
		tableHeaders() {
			var headers = [
				// {
				// 	text: 'Image',
				// 	align: 'start',
				// 	sortable: false,
				// 	value: 'image'
				// },
				{ text: this.$i18n.t('first_name'), value: 'firstname' },
				{ text: this.$i18n.t('last_name'), value: 'lastname' },
				{ text: this.$i18n.t('email'), value: 'email', align: 'center' },
				{ text: this.$i18n.t('phone'), value: 'phone', sortable: false },
				// { text: 'Alcohol', value: 'hasAlcohol', align: 'center' },
				// //{ text: '# Asistencias', value: 'total_assistance', align: 'center' },
				// { text: 'Category', value: 'category.name', align: 'center' },
				{ text: this.$i18n.t('actions'), value: 'actions', sortable: false },
			];

			if (this.accessLevel == 'admin') {
				const restaurants = {
					text: this.$i18n.t('business'),
					value: 'business.name',
					align: 'center',
				};
				headers.splice(headers.length - 1, 0, restaurants);
			}
			return headers;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		getColorActive(active) {
			if (!active) return 'red';
			else return 'green';
		},

		getImage(name) {
			////console.log(process.env.VUE_APP_IMAGE_URL + name);
			return process.env.VUE_APP_IMAGE_URL + name;
		},
		onFileChange(e) {
			if (e) {
				const file = e;
				this.imageUrl = URL.createObjectURL(file);
				this.newImage = file;
			}
		},
		resetImage() {
			this.imageUrl = null;
			this.newImage = null;
		},
		editItem(item) {
			this.editedIndex = this.employees.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.imageUrl = this.getImage(this.editedItem.image);
			this.dialog = true;
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},
		getDateFormat(string) {
			return moment(string).format('DD-MMM-YYYY');
		},

		deleteItem(item) {
			this.editedIndex = this.employees.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.deleteEmployee();
			this.closeDelete();
		},

		close() {
			this.$refs.observer.reset();
			this.dialog = false;
			this.password = '';
			this.confirmation = '';
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				//edit
				//Object.assign(this.people[this.editedIndex], this.editedItem);
				this.editEmployee();
			} else {
				//add
				//this.people.push(this.editedItem);
				this.addEmployee();
			}
			this.close();
		},

		// NETWORK LOGIC

		async getBusinesses() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				await fetch(process.env.VUE_APP_API_URL + 'business/', options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data.data);
						this.businesses = data.data;
					});
			} catch (error) {
				//console.log(error);

				this.setupAlert(true, error, 'error');
			}
		},

		async getEmployees() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + 'employee/', options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data.data);
						this.employees = data.data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
				this.loadingTable = false;
			}
		},

		async addEmployee() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('firstname', this.editedItem.firstname);
				formData.append('lastname', this.editedItem.lastname);
				formData.append('phone', this.editedItem.phone);
				formData.append('email', this.editedItem.email);
				formData.append('password', this.password);

				if (this.accessLevel == 'admin') {
					formData.append('business', this.editedItem.business._id);
				}

				if (this.newImage) {
					formData.append('image', this.newImage);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'employee/', requestOptions).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_employee_added'), 'success');
					}
					//response.json();
				});
				this.getEmployees();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async editEmployee() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				formData.append('firstname', this.editedItem.firstname);
				formData.append('lastname', this.editedItem.lastname);
				formData.append('phone', this.editedItem.phone);

				if (this.accessLevel == 'admin') {
					formData.append('business', this.editedItem.business._id);
				}

				if (this.newImage) {
					formData.append('image', this.newImage);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + `employee/${this.editedItem._id}`, requestOptions).then(
					(response) => {
						if (response.ok) {
							this.setupAlert(true, this.$i18n.t('alert_msg_employee_updated'), 'success');
						}
					},
				);
				this.getEmployees();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async deleteEmployee() {
			try {
				const token = localStorage.getItem('weserv.access');

				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				await fetch(process.env.VUE_APP_API_URL + `employee/${this.editedItem._id}`, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'DELETE',
				}).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_employee_deleted'), 'success');
					}
				});
				this.getEmployees();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		this.getEmployees();
		if (this.accessLevel == 'admin') {
			this.getBusinesses();
		}
	},
};
</script>

<style scoped></style>
