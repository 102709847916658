<template>
	<v-card :elevation="0" color="transparent">
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>
		<v-card-title class="font-weight-bold">
			<h1>{{ $t('products') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>
		<v-data-table
			:headers="tableHeaders"
			:items="products"
			:items-per-page="10"
			:search="search"
			multi-sort
			:loading="loadingTable"
		>
			<template v-slot:top>
				<v-toolbar flat color="transparent">
					<v-spacer></v-spacer>
					<v-dialog persistent v-model="dialog" max-width="1000px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-if="accessLevel != 'employee'" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
								{{ $t('add_product') }}
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="headline">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<!-- INPUTS  -->
								<v-container>
									<validation-observer ref="observer" v-slot="{ invalid }">
										<form @submit.prevent="submit">
											<v-row>
												<v-col cols="12" sm="8">
													<validation-provider v-slot="{ errors, validate }" :name="$t('image')" :rules="imageRules">
														<v-file-input
															@change="onChange($event, validate)"
															@click:clear="resetImage"
															:label="$t('image')"
															required
															:error-messages="errors"
															v-model="newImage"
															accept="image/png, image/jpeg"
															filled
															prepend-icon="mdi-camera"
															:disabled="accessLevel === 'employee'"
														></v-file-input>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="4">
													<v-img v-if="imageUrl" :src="imageUrl" max-height="150" class="rounded-lg"></v-img>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" sm="8">
													<validation-provider v-slot="{ errors }" :name="$t('name')" rules="required">
														<v-text-field
															v-model="editedItem.name"
															filled
															:error-messages="errors"
															:label="$t('name')"
															clearable
															:disabled="accessLevel === 'employee'"
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="4">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('price')"
														:rules="{
															required: true,
															decimal: true,
															max: 6,
															min_value: 0.1,
														}"
													>
														<v-text-field
															v-model="editedItem.price"
															filled
															:label="$t('price')"
															:error-messages="errors"
															clearable
															:disabled="accessLevel === 'employee'"
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>
											<validation-provider v-slot="{ errors }" :name="$t('description')" rules="required">
												<v-textarea
													filled
													clearable
													clear-icon="mdi-close-circle"
													counter
													:label="$t('description')"
													:error-messages="errors"
													v-model="editedItem.description"
													:disabled="accessLevel === 'employee'"
												></v-textarea>
											</validation-provider>
											<v-row align="center">
												<v-col class="" cols="12" sm="6" md="6" lg="6">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('business')"
														rules="required"
														v-if="accessLevel == 'admin'"
													>
														<v-select
															:items="businesses"
															item-text="name"
															item-value="_id"
															v-model="editedItem.restaurant._id"
															:error-messages="errors"
															filled
															:label="$t('business')"
														></v-select>
													</validation-provider>
												</v-col>
												<v-col class="" cols="12" sm="6" md="6" lg="6">
													<validation-provider v-slot="{ errors }" :name="$t('category')" rules="required">
														<v-select
															:items="filteredCategories"
															item-text="name"
															item-value="_id"
															v-model="editedItem.category._id"
															:error-messages="errors"
															filled
															:label="$t('category')"
															:disabled="accessLevel === 'employee'"
														></v-select>
													</validation-provider>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" sm="6">
													<v-switch
														v-model="editedItem.available"
														inset
														:label="$t('available')"
														color="green"
													></v-switch>
												</v-col>
												<v-col ols="12" sm="6">
													<v-switch
														v-model="editedItem.hasAlcohol"
														inset
														:label="
															`${accessLevel === 'employee' && !editedItem.hasAlcohol ? 'No' : ''} ${$t('alcohol')}`
														"
														:disabled="accessLevel === 'employee'"
														color="green"
													></v-switch>
												</v-col>
											</v-row>
											<v-container class="mt-5">
												<v-btn class="mr-4" color="error" @click="close">
													{{ $t('close') }}
												</v-btn>
												<v-btn type="submit" color="success" :disabled="invalid" @click="save">
													{{ AddButtonText }}
												</v-btn>
											</v-container>
										</form>
									</validation-observer>
								</v-container>
							</v-card-text>

							<!-- <v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" @click="close">
									Cancel
								</v-btn>
								<v-btn color="success" @click="save">
									{{ AddButtonText }}
								</v-btn>
							</v-card-actions> -->
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="600px">
						<v-card>
							<v-card-title class="headline justify-center">{{ $t('delete_dialog_confirm_product') }}</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
								<v-btn color="error" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<!-- <template v-slot:[`item.birthday`]="{ item }">
				{{ getDateFormat(item.birthday) }}
			</template> -->
			<template v-slot:[`item.image`]="{ item }">
				<v-avatar class="ma-1">
					<img :src="getImage(item.image)" alt="no image" />
				</v-avatar>
			</template>
			<!-- <template v-slot:[`item.total_given`]="{ item }">
				<v-chip :color="getColor(item.total_given)" dark>
					{{ item.total_given }}
				</v-chip>
			</template> -->
			<template v-slot:[`item.available`]="{ item }">
				<v-chip :color="getColorActive(item.available)" dark>
					{{ item.available ? $t('available') : $t('unavailable') }}
				</v-chip>
			</template>
			<template v-slot:[`item.hasAlcohol`]="{ item }">
				<v-chip :color="getColorActive(item.hasAlcohol)" dark>
					{{ item.hasAlcohol ? $t('yes') : 'No' }}
				</v-chip>
			</template>
			<!-- <template v-slot:[`item.gender`]="{ item }">
				<v-chip :color="getColorGender(item.gender)" dark>
					{{ item.gender === 'female' ? 'Mujer' : 'Hombre' }}
				</v-chip>
			</template> -->
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="mr-2" @click="editItem(item)">
					mdi-pencil
				</v-icon>
				<v-icon v-if="accessLevel != 'employee'" small @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>
			<template v-slot:no-data>
				<v-sheet class="my-10" color="">
					<v-row justify="center">
						<v-sheet color="">
							<h2 class="text-h2 pa-3">{{ $t('no_products') }}</h2>
						</v-sheet>
					</v-row>
					<v-row justify="center">
						<v-btn class="ma-4" color="primary" @click="getProducts">{{ $t('refresh') }}</v-btn>
					</v-row>
				</v-sheet>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';
import moment from 'moment';

import { required, digits, email, max, alpha, numeric, is, image, double, min_value } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('digits', {
	...digits,
});

extend('double', {
	...double,
});
extend('min_value', {
	...min_value,
});
extend('image', {
	...image,
});

extend('alpha', {
	...alpha,
});

extend('numeric', {
	...numeric,
});

extend('required', {
	...required,
});

extend('max', {
	...max,
});

extend('is', {
	...is,
});

extend('email', {
	...email,
});

export default {
	components: { ValidationProvider, ValidationObserver },
	data() {
		return {
			search: '',
			dialog: false,
			dialogDelete: false,
			/* NEW PRODUCT VARS */
			newImage: null,
			imageUrl: null,

			loadingTable: false,

			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',

			categories: [{ _id: 'dfasd123das', name: 'Pizza' }],
			products: [],
			businesses: [],
			editedIndex: -1,
			editedItem: {
				_id: '',
				name: '',
				description: '',
				price: '',
				restaurant: {
					name: '',
				},
				category: {
					name: '',
				},
				available: false,
				hasAlcohol: false,
				image: null,
			},
			defaultItem: {
				_id: '',
				name: '',
				description: '',
				price: '',
				restaurant: {
					name: '',
				},
				category: {
					name: '',
				},
				available: false,
				hasAlcohol: false,
				image: null,
			},
			numberRule: (v) => {
				//if (!v.trim()) return true;
				if (!isNaN(parseFloat(v)) && v >= 0) return true;
				return 'The number has to be greater than 0';
			},
			descriptionRules: (v) => {
				//if (!v.trim()) return true;
				if (!v || v.length <= 130) return true;
				return 'Max 130 characters';
			},
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? this.$i18n.t('new_product') : this.$i18n.t('edit_product');
		},
		AddButtonText() {
			return this.editedIndex === -1 ? this.$i18n.t('add') : this.$i18n.t('save');
		},
		imageRules() {
			return this.editedIndex === -1 ? 'required|image' : 'image';
		},
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},
		filteredCategories() {
			////console.log(this.editedItem.restaurant._id);
			if (this.accessLevel === 'business') {
				return this.categories;
			}
			const categories = this.categories.filter((category) => {
				//console.log(category.restaurant._id);
				return category.restaurant._id == this.editedItem.restaurant._id;
			});

			return categories;
		},
		tableHeaders() {
			var headers = [
				{
					text: this.$i18n.t('image'),
					align: 'start',
					sortable: false,
					value: 'image',
				},
				{ text: this.$i18n.t('name'), value: 'name', align: 'start' },
				{
					text: 'Description',
					value: 'description',
					sortable: false,
					align: 'start',
				},
				{ text: this.$i18n.t('price'), value: 'price', align: 'center' },
				{ text: this.$i18n.t('availability'), value: 'available', align: 'center' },
				{ text: this.$i18n.t('alcohol'), value: 'hasAlcohol', align: 'center' },
				//{ text: '# Asistencias', value: 'total_assistance', align: 'center' },
				{ text: this.$i18n.t('category'), value: 'category.name', align: 'center' },
				{ text: this.$i18n.t('actions'), value: 'actions', sortable: false },
			];
			if (this.accessLevel == 'admin') {
				const adminHeaders = [
					{
						text: this.$i18n.t('venue'),
						value: 'restaurant.stadium.name',
						align: 'center',
					},
					{
						text: this.$i18n.t('business'),
						value: 'restaurant.name',
						align: 'center',
					},
				];
				headers.unshift(adminHeaders[1]);
				headers.unshift(adminHeaders[0]);
			}
			return headers;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		submit() {
			this.$refs.observer.validate();
			//login
		},
		getColorActive(active) {
			if (!active) return 'red';
			else return 'green';
		},

		getPaymentDate(date) {
			return moment(date).format('L');
		},
		getImage(name) {
			////console.log(process.env.VUE_APP_IMAGE_URL + name);
			return process.env.VUE_APP_IMAGE_URL + name;
		},
		onChange(event, validate) {
			this.onFileChange(event);
			validate();
		},
		onFileChange(e) {
			if (e) {
				const file = e;
				this.imageUrl = URL.createObjectURL(file);
				this.newImage = file;
			}
		},
		resetImage() {
			if (this.editedItem.image) {
				this.imageUrl = this.getImage(this.editedItem.image);
			} else {
				this.imageUrl = null;
			}

			this.newImage = null;
		},
		editItem(item) {
			this.editedIndex = this.products.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.imageUrl = this.getImage(this.editedItem.image);
			this.dialog = true;
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},

		deleteItem(item) {
			this.editedIndex = this.products.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.deleteProduct();
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				this.resetImage();
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				//edit
				//Object.assign(this.people[this.editedIndex], this.editedItem);
				this.editProduct();
			} else {
				//add
				//this.people.push(this.editedItem);
				this.addProduct();
			}

			this.close();
			this.imageUrl = null;
		},

		// NETWORK LOGIC

		async getProducts() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				var productsURL = 'product/restaurant/';
				if (user.accessLevel == 'admin') {
					productsURL = 'product/';
				}

				await fetch(process.env.VUE_APP_API_URL + productsURL, options).then(async (response) => {
					if (response.ok) {
						const data = await response.json();
						//console.log(data.data);
						this.products = data.data;
						this.loadingTable = false;
					} else if (response.status == 403) {
						localStorage.clear();
						this.$router.push('/login');
					}
				});
			} catch (error) {
				//console.log(error);
				this.loadingTable = false;
				this.setupAlert(true, error, 'error');
			}
		},

		async getBusinesses() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				await fetch(process.env.VUE_APP_API_URL + 'business/', options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data.data);
						this.businesses = data.data;
					});
			} catch (error) {
				//console.log(error);

				this.setupAlert(true, error, 'error');
			}
		},

		async getCategories() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				var categoryURL = `category/restaurant/${user._id}`;
				if (user.accessLevel == 'admin') {
					categoryURL = 'category/';
				}
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + categoryURL, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						//console.log(data.data);
						this.categories = data.data;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},

		async addProduct() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('name', this.editedItem.name);
				formData.append('description', this.editedItem.description);
				formData.append('price', this.editedItem.price);
				formData.append('hasAlcohol', this.editedItem.hasAlcohol);
				formData.append('category', this.editedItem.category._id);
				formData.append('available', this.editedItem.available);

				if (this.accessLevel == 'business') {
					/*
					!ONLY IF ITS BUSINESS
					*/
					formData.append('restaurant', user.business._id);
				} else if (this.accessLevel == 'admin') {
					formData.append('restaurant', this.editedItem.restaurant._id);
				}

				if (this.newImage) {
					formData.append('image', this.newImage);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'product/', requestOptions).then(async (response) => {
					if (response.ok) {
						this.newImage = null;
						this.setupAlert(true, this.$i18n.t('alert_msg_product_added'), 'success');
					} else {
						const data = await response.json();
						this.setupAlert(true, data.msg, 'error');
					}

					//response.json();
				});
				this.getProducts();
			} catch (error) {
				////console.log('??');
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async editProduct() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				formData.append('name', this.editedItem.name);
				formData.append('description', this.editedItem.description);
				formData.append('price', this.editedItem.price);
				formData.append('hasAlcohol', this.editedItem.hasAlcohol);
				formData.append('category', this.editedItem.category._id);
				formData.append('available', this.editedItem.available);
				if (this.accessLevel == 'business') {
					/*
					!ONLY IF ITS BUSINESS
					*/
					formData.append('restaurant', user.business._id);
				} else if (this.accessLevel == 'admin') {
					formData.append('restaurant', this.editedItem.restaurant._id);
				}

				if (this.newImage) {
					formData.append('image', this.newImage);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + `product/${this.editedItem._id}`, requestOptions).then(
					async (response) => {
						if (response.ok) {
							this.setupAlert(true, this.$i18n.t('alert_msg_product_updated'), 'success');
						} else {
							const data = await response.json();
							this.setupAlert(true, data.msg, 'error');
						}
					},
				);
				this.getProducts();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async deleteProduct() {
			try {
				const token = localStorage.getItem('weserv.access');

				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				await fetch(process.env.VUE_APP_API_URL + `product/${this.editedItem._id}`, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'DELETE',
				}).then(async (response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_product_deleted'), 'success');
					} else {
						const data = await response.json();
						this.setupAlert(true, data.msg, 'error');
					}
				});
				this.getProducts();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		////console.log('B');
		this.getProducts();
		if (this.accessLevel == 'admin') {
			this.getBusinesses();
		}
		this.getCategories();

		////console.log('B2');
	},
};
</script>

<style scoped></style>
