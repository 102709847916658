<template>
	<v-card :elevation="0" color="transparent">
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>
		<v-card-title class="font-weight-bold">
			<h1>{{ $t('venues') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				hide-overlay
				single-line
				hide-details
			></v-text-field>
		</v-card-title>
		<v-data-table
			:headers="tableHeaders"
			:items="stadiums"
			:items-per-page="10"
			:search="search"
			multi-sort
			:loading="loadingTable"
		>
			<template v-slot:top>
				<v-toolbar flat color="transparent">
					<v-spacer></v-spacer>
					<v-dialog persistent v-model="dialog" max-width="1000px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
								{{ $t('add_venue') }}
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="headline">{{ $t(formTitle) }}</span>
							</v-card-title>

							<v-card-text>
								<!-- INPUTS  -->
								<v-container>
									<validation-observer ref="observer" v-slot="{ invalid }">
										<form @submit.prevent="submit">
											<v-row>
												<v-col cols="12" sm="8">
													<validation-provider v-slot="{ errors, validate }" name="Image" :rules="imageRules">
														<v-file-input
															@change="onChange($event, validate)"
															@click:clear="resetImage"
															:label="$t('image')"
															required
															:error-messages="errors"
															v-model="newImage"
															accept="image/png, image/jpeg"
															filled
															prepend-icon="mdi-camera"
														></v-file-input>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="4">
													<v-img v-if="imageUrl" :src="imageUrl" max-height="150" class="rounded-lg"></v-img>
												</v-col>
											</v-row>
											<v-row justify="start">
												<v-col cols="12" sm="6">
													<validation-provider v-slot="{ errors }" name="Name" rules="required">
														<v-text-field
															v-model="editedItem.name"
															filled
															:error-messages="errors"
															:label="$t('name')"
															clearable
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col class="" cols="12" sm="6" md="6" lg="6">
													<validation-provider v-slot="{ errors }" name="Country" rules="required">
														<v-select
															:items="countries"
															item-text="name"
															item-value="code"
															v-model="editedItem.country"
															:error-messages="errors"
															filled
															:label="$t('country')"
														></v-select>
													</validation-provider>
												</v-col>
											</v-row>

											<add-google-map ref="gmap" :mapData="editedItemData" @update="setNewCoordinates"></add-google-map>

											<v-row class="mt-4" justify="center">
												<v-col cols="12" sm="4">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('visible_range')"
														:rules="{
															required: true,
															numeric: true,
															min_value: 0,
														}"
													>
														<v-text-field
															v-model="editedItem.visibleRange"
															filled
															:label="$t('visible_range')"
															:error-messages="errors"
															clearable
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="4">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('buy_range')"
														:rules="{
															required: true,
															numeric: true,
															min_value: 0,
														}"
													>
														<v-text-field
															v-model="editedItem.buyRange"
															filled
															:label="$t('buy_range')"
															:error-messages="errors"
															clearable
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" sm="4">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('stripe_percentage_fee')"
														:rules="{
															required: true,
															decimal: true,
															min_value: 0,
														}"
													>
														<v-text-field
															v-model="editedItem.stripePercentageFee"
															filled
															:label="$t('stripe_percentage_fee')"
															:error-messages="errors"
															clearable
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="4">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('stripe_fixed_fee')"
														:rules="{
															required: true,
															decimal: true,
															min_value: 0,
														}"
													>
														<v-text-field
															v-model="editedItem.stripeFixedFee"
															filled
															:label="$t('stripe_fixed_fee')"
															:error-messages="errors"
															clearable
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="12" sm="4">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('tax')"
														:rules="{
															required: true,
															decimal: true,
															min_value: 0,
														}"
													>
														<v-text-field
															v-model="editedItem.tax"
															filled
															:label="$t('tax')"
															:error-messages="errors"
															clearable
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>
											<!-- <v-row>
												<v-col cols="12" sm="6">
													<v-switch
														v-model="editedItem.available"
														inset
														label="Available"
														color="green"
													></v-switch>
												</v-col>
											</v-row> -->
											<v-container class="mt-5">
												<v-btn class="mr-4" color="error" @click="close">
													{{ $t('close') }}
												</v-btn>
												<v-btn type="submit" color="success" :disabled="invalid" @click="save">
													{{ AddButtonText }}
												</v-btn>
											</v-container>
										</form>
									</validation-observer>
								</v-container>
							</v-card-text>

							<!-- <v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" @click="close">
									Cancel
								</v-btn>
								<v-btn color="success" @click="save">
									{{ AddButtonText }}
								</v-btn>
							</v-card-actions> -->
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="600px">
						<v-card>
							<v-card-title class="headline justify-center">{{ $t('delete_dialog_confirm_venue') }}</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
								<v-btn color="error" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<!-- <template v-slot:[`item.birthday`]="{ item }">
				{{ getDateFormat(item.birthday) }}
			</template> -->
			<template v-slot:[`item.image`]="{ item }">
				<v-avatar class="ma-1">
					<img :src="getImage(item.image)" alt="no image" />
				</v-avatar>
			</template>
			<!-- <template v-slot:[`item.total_given`]="{ item }">
				<v-chip :color="getColor(item.total_given)" dark>
					{{ item.total_given }}
				</v-chip>
			</template> -->
			<template v-slot:[`item.available`]="{ item }">
				<v-chip :color="getColorActive(item.available)" dark>
					{{ item.available ? 'Available' : 'Unavailable' }}
				</v-chip>
			</template>
			<template v-slot:[`item.hasAlcohol`]="{ item }">
				<v-chip :color="getColorActive(item.hasAlcohol)" dark>
					{{ item.hasAlcohol ? 'Yes' : 'No' }}
				</v-chip>
			</template>
			<!-- <template v-slot:[`item.gender`]="{ item }">
				<v-chip :color="getColorGender(item.gender)" dark>
					{{ item.gender === 'female' ? 'Mujer' : 'Hombre' }}
				</v-chip>
			</template> -->
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="mr-2" @click="sendToSections(item)">
					mdi-seat
				</v-icon>
				<v-icon small class="mr-2" @click="editItem(item)">
					mdi-pencil
				</v-icon>
				<v-icon small @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>
			<template v-slot:no-data>
				<v-sheet class="my-10" color="">
					<v-row justify="center">
						<v-sheet color="">
							<h2 class="text-h2 pa-3">No Venues</h2>
						</v-sheet>
					</v-row>
					<v-row justify="center">
						<v-btn class="ma-4" color="primary" @click="getStadiums">Refresh</v-btn>
					</v-row>
				</v-sheet>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';
import moment from 'moment';
import AddGoogleMap from '../AddGoogleMap.vue';
//import { i18n } from '../../lang/index';

import { required, digits, email, max, alpha, numeric, is, image, double, min_value } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('digits', {
	...digits,
});

extend('double', {
	...double,
});
extend('min_value', {
	...min_value,
});
extend('image', {
	...image,
});

extend('alpha', {
	...alpha,
});

extend('numeric', {
	...numeric,
});

extend('required', {
	...required,
});

extend('max', {
	...max,
});

extend('is', {
	...is,
});

extend('email', {
	...email,
});

export default {
	components: { ValidationProvider, ValidationObserver, AddGoogleMap },
	data() {
		return {
			search: '',
			dialog: false,
			dialogDelete: false,
			/* NEW PRODUCT VARS */
			newImage: null,
			imageUrl: null,

			loadingTable: false,
			showingSeats: false,

			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',

			categories: [{ _id: 'dfasd123das', name: 'Pizza' }],
			countries: [
				{ code: 'MX', name: 'Mexico' },
				{ code: 'US', name: 'USA' },
			],
			stadiums: [],
			businesses: [],
			editedIndex: -1,
			editedItem: {
				_id: '',
				name: '',
				buyRange: 0,
				lat: 0,
				lon: 0,
				country: '',
				visibleRange: 0,
				stripePercentageFee: 0,
				stripeFixedFee: 0,
				tax: 0,
				image: '',
			},
			defaultItem: {
				_id: '',
				name: '',
				buyRange: 10,
				lat: 0,
				lon: 0,
				country: '',
				visibleRange: 0,
				stripePercentageFee: 0,
				stripeFixedFee: 0,
				tax: 0,
				image: '',
			},
			numberRule: (v) => {
				//if (!v.trim()) return true;
				if (!isNaN(parseFloat(v)) && v >= 0) return true;
				return 'The number has to be greater than 0';
			},
			descriptionRules: (v) => {
				//if (!v.trim()) return true;
				if (!v || v.length <= 130) return true;
				return 'Max 130 characters';
			},
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? 'new_venue' : 'edit_venue';
		},
		AddButtonText() {
			return this.editedIndex === -1 ? 'add' : 'save';
		},
		imageRules() {
			return this.editedIndex === -1 ? 'required|image' : 'image';
		},
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},
		editedItemData() {
			if (this.editedIndex === -1) {
				return {
					lat: 21.0307114,
					lng: -89.6181018,
					buyRadius: this.editedItem.buyRange,
					visibleRadius: this.editedItem.visibleRange,
				};
			}
			////console.log(`stad:  ${this.editedItem.lat} , ${this.editedItem.lon}`);
			return {
				lat: this.editedItem.lat,
				lng: this.editedItem.lon,
				buyRadius: this.editedItem.buyRange,
				visibleRadius: this.editedItem.visibleRange,
			};
		},
		filteredCategories() {
			////console.log(this.editedItem.restaurant._id);
			const categories = this.categories.filter((category) => {
				////console.log(category.restaurant._id);
				return category.restaurant._id == this.editedItem.restaurant._id;
			});

			return categories;
		},
		tableHeaders() {
			var headers = [
				{
					text: this.$i18n.t('image'),
					align: 'start',
					sortable: false,
					value: 'image',
				},
				{ text: this.$i18n.t('name'), value: 'name', align: 'start' },
				{
					text: this.$i18n.t('country'),
					value: 'country',
					sortable: true,
					align: 'center',
				},

				{ text: this.$i18n.t('actions'), value: 'actions', sortable: false },
			];

			return headers;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		submit() {
			this.$refs.observer.validate();
			//login
		},
		getColorActive(active) {
			if (!active) return 'red';
			else return 'green';
		},

		getPaymentDate(date) {
			return moment(date).format('L');
		},
		getImage(name) {
			////console.log(process.env.VUE_APP_IMAGE_URL + name);
			return process.env.VUE_APP_IMAGE_URL + name;
		},
		onChange(event, validate) {
			this.onFileChange(event);
			validate();
		},
		onFileChange(e) {
			if (e) {
				const file = e;
				this.imageUrl = URL.createObjectURL(file);
				this.newImage = file;
			}
		},
		resetImage() {
			if (this.editedItem.image) {
				this.imageUrl = this.getImage(this.editedItem.image);
			} else {
				this.imageUrl = null;
			}

			this.newImage = null;
		},
		editItem(item) {
			this.editedIndex = this.stadiums.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.imageUrl = this.getImage(this.editedItem.image);
			this.dialog = true;
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},
		getDateFormat(string) {
			return moment(string).format('DD-MMM-YYYY');
		},

		deleteItem(item) {
			this.editedIndex = this.stadiums.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.deleteProduct();
			this.closeDelete();
		},

		close() {
			this.dialog = false;

			this.$nextTick(() => {
				this.$refs.gmap.resetCenter();
				this.resetImage();
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		async save() {
			if (this.editedIndex > -1) {
				//edit
				//Object.assign(this.people[this.editedIndex], this.editedItem);
				await this.editStadium();
			} else {
				//add
				//this.people.push(this.editedItem);
				await this.addStadium();
			}
			this.resetImage();
			this.close();
		},
		setNewCoordinates(e) {
			//console.log('----------');
			//console.log(e);

			this.editedItem.lat = e.lat;
			this.editedItem.lon = e.lon;
		},

		sendToSections(item) {
			//this.showingSeats = true;
			//console.log(`/admin/stadiums/${item._id}/sections`);
			this.$router.push(`/admin/stadium/${item._id}/sections`);
		},

		// NETWORK LOGIC

		async getStadiums() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				var user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					setTimeout(() => {
						user = JSON.parse(localStorage.getItem('weserv.user'));
						if (!user) {
							throw new Error(this.$i18n.t('error_msg_no_user'));
						}
					}, 100);
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);

				await fetch(process.env.VUE_APP_API_URL + 'stadium/', options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data.data);
						this.stadiums = data.data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.loadingTable = false;
				this.setupAlert(true, error, 'error');
			}
		},

		async addStadium() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('name', this.editedItem.name);
				formData.append('lat', this.editedItem.lat);
				formData.append('lon', this.editedItem.lon);
				formData.append('country', this.editedItem.country);
				formData.append('buyRange', this.editedItem.buyRange);
				formData.append('visibleRange', this.editedItem.visibleRange);
				formData.append('stripePercentageFee', this.editedItem.stripePercentageFee);
				formData.append('stripeFixedFee', this.editedItem.stripeFixedFee);
				formData.append('tax', this.editedItem.tax);

				if (this.newImage) {
					formData.append('image', this.newImage);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'stadium/', requestOptions).then(async (response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_venue_added'), 'success');
					} else {
						const data = await response.json();
						this.setupAlert(true, data.msg, 'error');
					}

					//response.json();
				});
				this.getStadiums();
			} catch (error) {
				////console.log('??');
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async editStadium() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				formData.append('name', this.editedItem.name);
				formData.append('lat', this.editedItem.lat);
				formData.append('lon', this.editedItem.lon);
				formData.append('country', this.editedItem.country);
				formData.append('buyRange', this.editedItem.buyRange);
				formData.append('visibleRange', this.editedItem.visibleRange);
				formData.append('stripePercentageFee', this.editedItem.stripePercentageFee);
				formData.append('stripeFixedFee', this.editedItem.stripeFixedFee);
				formData.append('tax', this.editedItem.tax);

				if (this.newImage) {
					formData.append('image', this.newImage);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + `stadium/${this.editedItem._id}/edit`, requestOptions).then(
					async (response) => {
						if (response.ok) {
							this.setupAlert(true, this.$i18n.t('alert_msg_venue_updated'), 'success');
						} else {
							const data = await response.json();
							this.setupAlert(true, data.msg, 'error');
						}
					},
				);
				this.getStadiums();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async deleteStadium() {
			try {
				const token = localStorage.getItem('weserv.access');

				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				await fetch(process.env.VUE_APP_API_URL + `stadium/${this.editedItem._id}/delete`, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'DELETE',
				}).then(async (response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_venue_deleted'), 'success');
					} else {
						const data = await response.json();
						this.setupAlert(true, data.msg, 'error');
					}
				});
				this.getStadiums();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		////console.log('B');
		this.getStadiums();

		////console.log('B2');
	},
};
</script>

<style scoped></style>
