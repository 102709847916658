<template>
	<div>
		<v-container>
			<!-- ALERT -->
			<v-alert
				v-model="alert"
				transition="slide-y-transition"
				border="left"
				close-text="Close Alert"
				:type="alertType"
				dismissible
			>
				{{ alertMessage }}
			</v-alert>
			<v-row justify="start" align="center">
				<v-col cols="12" sm="" md="">
					<h2 class="text-h2">{{ business.name }}</h2>
					<h2 class="text-h5">{{ business.stadium.name }}</h2>
				</v-col>
				<v-spacer></v-spacer>
				<v-rating
					:value="business.rating"
					length="5"
					color="amber"
					background-color="grey lighten-1"
					readonly
					size="23"
				></v-rating>
			</v-row>
			<v-row class="py-4 " justify="start" align="center">
				<a href="#" class="stripe-connect slate" @click="sendToStripe"
					><span>{{ stripeButtonLabel }}</span></a
				>
				<v-spacer> </v-spacer>
				<v-switch v-model="isEditing" inset :label="$t('edit')"></v-switch>
			</v-row>
			<v-row justify="space-around">
				<v-card outlined elevation="" width="1610" class="pa-6" color="tertiary">
					<validation-observer ref="observer" v-slot="{ invalid }">
						<form @submit.prevent="submit">
							<v-row justify="space-around" align="center">
								<v-col cols="12" sm="2" md="4" lg="3">
									<v-img v-if="imageURL" class="rounded-lg" :src="imageURL" max-height="150"></v-img>
								</v-col>

								<v-col v-show="isEditing" cols="12" sm="6" md="8" lg="9">
									<validation-provider v-slot="{ errors, validate }" :name="$t('image')" rules="image">
										<v-file-input
											@change="onChange($event, validate)"
											:label="$t('image')"
											@click:clear="resetImage"
											:error-messages="errors"
											v-model="newImage"
											filled
											prepend-icon="mdi-camera"
										></v-file-input>
									</validation-provider>
								</v-col>

								<!-- <v-col cols="12" sm="4">
							
							</v-col> -->
							</v-row>
							<v-row v-show="isEditing" justify="space-around">
								<v-col cols="12" sm="6">
									<validation-provider v-slot="{ errors }" :name="$t('name')" rules="required">
										<v-text-field
											v-model="business.name"
											:error-messages="errors"
											:disabled="!isEditing"
											:label="$t('name')"
											filled
										></v-text-field>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="6">
									<validation-provider
										v-slot="{ errors }"
										:name="$t('phone_number')"
										:rules="{
											required: true,
											numeric: true,
											digits: 10,
										}"
									>
										<v-text-field
											v-model="business.phone"
											:counter="10"
											:error-messages="errors"
											:label="$t('phone_number')"
											filled
											:disabled="!isEditing"
										></v-text-field>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row justify="space-around">
								<v-col cols="12" sm="12">
									<validation-provider v-slot="{ errors }" :name="$t('description')" rules="required">
										<v-textarea
											filled
											clearable
											clear-icon="mdi-close-circle"
											counter
											:label="$t('description')"
											:error-messages="errors"
											v-model="business.description"
											:disabled="!isEditing"
										></v-textarea>
									</validation-provider>
								</v-col>
							</v-row>
							<v-row justify="space-around">
								<v-col cols="12" sm="6" md="4">
									<validation-provider v-slot="{ errors }" :name="$t('payment_methods')" rules="required">
										<v-select
											v-model="business.paymentMethods"
											:items="paymentMethods"
											filled
											chips
											:label="$t('payment_methods')"
											multiple
											:error-messages="errors"
											:item-text="selectedLocale"
											return-object
											:disabled="!isEditing"
										></v-select>
									</validation-provider>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<validation-provider v-slot="{ errors }" :name="$t('delivery_methods')" rules="required">
										<v-select
											v-model="business.deliveryMethods"
											:items="deliveryMethods"
											filled
											chips
											:label="$t('delivery_methods')"
											multiple
											:error-messages="errors"
											:item-text="selectedLocale"
											return-object
											:disabled="!isEditing"
										></v-select>
									</validation-provider>
								</v-col>
							</v-row>
							<!-- <v-row>
								<v-col cols="12" sm="2">
									<v-switch
										v-model="business.available"
										inset
										:disabled="!isEditing"
										:label="business.available ? $t('available') : $t('unavailable')"
									></v-switch>
								</v-col>
							</v-row> -->

							<v-container v-show="isEditing" class="mt-5">
								<v-row justify="end">
									<v-btn class="mr-4" color="error" @click="close">
										{{ $t('close') }}
									</v-btn>
									<v-btn type="submit" color="success" :disabled="invalid" @click="save">
										{{ $t('save') }}
									</v-btn>
								</v-row>
							</v-container>
						</form>
					</validation-observer>
				</v-card>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { required, digits, email, max, alpha, numeric, is, image } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('digits', {
	...digits,
});

extend('image', {
	...image,
});

extend('alpha', {
	...alpha,
});

extend('numeric', {
	...numeric,
});

extend('required', {
	...required,
});

extend('max', {
	...max,
});

extend('is', {
	...is,
});

extend('email', {
	...email,
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	data: () => ({
		email: '',
		password: '',
		remember: false,
		show1: false,

		loadingUser: false,

		/* ALERTS */

		alert: false,
		alertMessage: '',
		alertType: 'error',

		/* iMAGES */

		imageURL: '',
		newImage: null,

		stripeSetupResponse: false,

		/* VARS */

		isEditing: false,
		deliveryMethods: [],
		paymentMethods: [],
		business: {
			name: '',
			description: '',
			phone: '',
			stripeId: '',
			stadium: {
				name: '',
			},
			deliveryMethods: [],
			paymentMethods: [],
			available: false,
			image: '',
		},
	}),
	computed: {
		stripeButtonLabel() {
			return this.business.stripeId ? 'Dashboard' : 'Connect with';
		},
		isStripeSetup() {
			return this.stripeSetupResponse;
		},
		selectedLocale() {
			return this.$i18n.locale == 'en' ? 'keyUS' : 'keyMX';
		},
	},

	methods: {
		submit() {
			this.$refs.observer.validate();
			//login
		},
		clear() {
			this.name = '';
			this.phoneNumber = '';
			this.email = '';
			this.select = null;
			this.checkbox = null;
			this.$refs.observer.reset();
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},
		onChange(event, validate) {
			this.onFileChange(event);
			validate();
		},

		onFileChange(event) {
			if (event) {
				const file = event;
				this.imageURL = URL.createObjectURL(file);
				this.newImage = file;
			}
		},
		resetImage() {
			if (this.business.image) {
				this.imageURL = this.getImage(this.business.image);
			} else {
				this.imageURL = null;
			}

			this.newImage = null;
		},

		getImage(name) {
			////console.log(process.env.VUE_APP_IMAGE_URL + name);
			return process.env.VUE_APP_IMAGE_URL + name;
		},
		async save() {
			await this.updateBusiness();
			this.close();
		},
		close() {
			this.isEditing = false;
		},
		sendToStripe() {
			if (this.isStripeSetup) {
				this.sendToStripeDashboard();
				//this.sendToStripeOnboarding();
			} else {
				this.sendToStripeOnboarding();
			}
		},

		/* NETWORK METHODS */
		async updateBusiness() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				//console.log(this.paymentMethods);
				//console.log(this.business.paymentMethods);
				const formData = new FormData();
				if (this.newImage) {
					formData.append('image', this.newImage);
				}
				formData.append('name', this.business.name);
				formData.append('phone', this.business.phone);
				formData.append('description', this.business.description);
				formData.append('paymentMethods', JSON.stringify(this.business.paymentMethods));
				formData.append('deliveryMethods', JSON.stringify(this.business.deliveryMethods));
				//formData.append('available', this.business.available);

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				/* PUT request to edit status */
				await fetch(process.env.VUE_APP_API_URL + `business/${this.business._id}`, requestOptions).then(
					async (response) => {
						if (response.ok) {
							//this.setupAlert(true, ' edited successfully', 'success');

							//const data = await response.json();
							//console.log(data);
							this.getBusiness();
							this.newImage = null;
							this.setupAlert(true, this.$i18n.t('alert_msg_business_updated'), 'success');
						}
					},
				);
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async sendToStripeOnboarding() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `user/stripe/authorize`, options)
					.then((response) => response.json())
					.then((data) => {
						if (!data.url) {
							this.setupAlert(true, this.$i18n.t('error_connecting_stripe'), 'error');
						}
						window.location.href = data.url;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async sendToStripeDashboard() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `user/stripe/dashboard`, options)
					.then((response) => response.json())
					.then((data) => {
						if (!data.url) {
							this.setupAlert(true, this.$i18n.t('error_connecting_stripe'), 'error');
						}
						let stripeDash = window.open(data.url, '_blank');
						stripeDash.location = data.url;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async getPaymentMethods() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `payment/paymentMethods?stripe=${this.isStripeSetup}`, options)
					.then((response) => response.json())
					.then((data) => {
						//console.log(data);
						this.paymentMethods = data;
					});
			} catch (error) {
				////console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async getDeliveryMethods() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `payment/deliveryMethods`, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data);
						this.deliveryMethods = data;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async getBusiness() {
			try {
				//console.log('v1.10');
				const token = localStorage.getItem('weserv.access');
				var user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					setTimeout(() => {
						user = JSON.parse(localStorage.getItem('weserv.user'));
					}, 100);
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `business/info`, options)
					.then((response) => response.json())
					.then((data) => {
						//console.log(data);
						this.business = Object.assign({}, data.business);
						this.stripeSetupResponse = data.stripe;
						if (this.business.image) {
							this.imageURL = this.getImage(this.business.image);
						}
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	async created() {
		//console.log('v1.10.1');
		await this.getBusiness();
		this.getPaymentMethods();
		this.getDeliveryMethods();
	},
};
</script>

<style scoped>
.stripe-connect {
	background: #635bff;
	display: inline-block;
	height: 38px;
	text-decoration: none;
	width: 180px;

	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;

	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;

	-webkit-font-smoothing: antialiased;
}

.stripe-connect span {
	color: #ffffff;
	display: block;
	font-family: sohne-var, 'Helvetica Neue', Arial, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 14px;
	padding: 11px 0px 0px 24px;
	position: relative;
	text-align: left;
}

.stripe-connect:hover {
	background: #7a73ff;
}

.stripe-connect.slate {
	background: #0a2540;
}

.stripe-connect.slate:hover {
	background: #425466;
}

.stripe-connect.white {
	background: #ffffff;
}

.stripe-connect.white span {
	color: #0a2540;
}

.stripe-connect.white:hover {
	background: #f6f9fc;
}

.stripe-connect span::after {
	background-repeat: no-repeat;
	background-size: 49.58px;
	content: '';
	height: 20px;
	left: 62%;
	position: absolute;
	top: 28.95%;
	width: 49.58px;
}

/* Logos */
.stripe-connect span::after {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.stripe-connect.white span::after {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}
</style>
