<template>
	<Notifications />
</template>

<script>
import Notifications from '../../../components/Notifications.vue';

export default {
	name: 'Notification',
	components: {
		Notifications
	}
};
</script>
