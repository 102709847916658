<template>
	<v-card :elevation="0" color="transparent">
		<!-- ALERT -->
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>
		<!-- TITLE AND SEARCH BAR -->
		<v-card-title class="font-weight-bold">
			<h1>{{ $t('user_reports') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>

		<!-- DATA TABLE FOR ORDERS -->
		<v-data-table
			:headers="tableHeaders"
			:items="reports"
			:items-per-page="10"
			:search="search"
			multi-sort
			:loading="loadingTable"
		>
			<template v-slot:top>
				<v-toolbar flat color="transparent">
					<!-- REPORT DETAIL DIALOG -->
					<v-dialog v-model="reportDialog" transition="dialog-bottom-transition" max-width="1400">
						<template v-slot:default="dialog">
							<v-card>
								<v-toolbar color="primary" dark>
									<v-toolbar-title>{{ $t('user_reports') }}</v-toolbar-title>
									<v-spacer></v-spacer>
								</v-toolbar>

								<v-alert
									v-model="detailAlert"
									transition="slide-y-transition"
									border="left"
									close-text="Close Alert"
									:type="detailAlertType"
									dismissible
								>
									{{ detailAlertMessage }}
								</v-alert>

								<v-card-text class="my-10">
									<div v-if="!reportLoading">
										<v-row class="pa-4">
											<h2 class="text-h5 font-weight-bold">{{ $t('date') }}:</h2>
											<h4 class="ml-4 text-h5">
												{{ `${getDateFormat(selectedItem.created)}` }}
											</h4>
											<v-spacer></v-spacer>

											<!--  ACTION BUTTONS -->
											<div v-if="!selectedItem.addressed">
												<v-btn class="ml-4 my-2" color="success" @click="disregardDialog = true">{{
													$t('dismiss')
												}}</v-btn>

												<v-btn class="ml-4 my-2" color="error" @click="showBanDialog">{{ $t('ban_user') }}</v-btn>
											</div>
											<div v-if="selectedItem.actionTaken && selectedItem.user.bannedUntil">
												<h4 class="ml-4 text-h5">
													<span class="text-h5 font-weight-bold">{{ $t('banned_until') }}</span>
													{{ `${getDateFormat(selectedItem.user.bannedUntil)}` }}
												</h4>
											</div>
										</v-row>
										<v-row justify="center">
											<!-- ORDER DETAILS -->
											<v-col cols="12" sm="6">
												<v-card max-width="480" outlined>
													<v-card-text>
														<v-list>
															<v-row align="center" class="pa-3">
																<h3>{{ $t('business') }}:</h3>
																<v-spacer />
																<v-chip color="red" dark>{{
																	selectedItem.restaurant ? selectedItem.restaurant.name : 'ADMIN'
																}}</v-chip>
															</v-row>

															<div>
																<v-divider class="mt-4"></v-divider>
																<v-container class="mt-3">
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('reported_by') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{
																			`${selectedItem.reportedBy.firstname} ${selectedItem.reportedBy.lastname}`
																		}}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('reason') }}</h3>
																		<v-spacer />
																		<v-chip dark small :color="getReasonColor(selectedItem.reason)">{{
																			getReasonText(selectedItem.reason)
																		}}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('comments') }}</h3>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<p>{{ selectedItem.comment }}</p>
																	</v-row>
																</v-container>
															</div>
														</v-list>
													</v-card-text>
												</v-card>
												<v-card v-show="selectedItem.order.status == -2" class="my-4" max-width="480" outlined>
													<v-card-text>
														<v-list>
															<v-row align="center" class="pl-3 pa-2">
																<h3>{{ $t('order_rejection_information') }}</h3>
															</v-row>

															<div>
																<v-divider class="mt-4"></v-divider>
																<v-container class="mt-3">
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('reason') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ getReasonText(selectedItem.reason) }}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('comments') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ selectedItem.order.comments }}</v-chip>
																	</v-row>
																</v-container>
															</div>
														</v-list>
													</v-card-text>
												</v-card>
												<v-card v-if="!selectedItem.user" class="my-4" max-width="480" color="red lighten-3" outlined>
													<v-card-text>
														<h3 class=" mx-4 text-center font-weight-black text-button">
															{{ $t('client_was_deleted') }}
														</h3>
														<div class="mx-auto">
															<lottie-player
																autoplay
																loop
																mode="normal"
																speed="1.5"
																src="/src/assets/animations/error-cone.json"
																style="width: 100px"
															></lottie-player>
														</div>
													</v-card-text>
												</v-card>
											</v-col>
											<!-- ORDER CLIENT DETAILS -->
											<v-col cols="12" sm="6">
												<v-card max-width="480" v-if="selectedItem.user" outlined>
													<v-card-text>
														<v-list>
															<v-row align="center" class="pa-3">
																<h3>{{ $t('client') }}:</h3>
																<v-spacer />
																<v-chip color="light-blue" dark>{{
																	`${selectedItem.user.firstname} ${selectedItem.user.lastname}`
																}}</v-chip>
															</v-row>

															<div>
																<v-divider class="mt-4"></v-divider>
																<v-container class="mt-3">
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('phone') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ getPhoneFormat(selectedItem.user.phone) }}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('email') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ selectedItem.user.email }}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('number_reports') }}</h3>
																		<v-spacer />
																		<v-chip
																			dark
																			small
																			:color="
																				selectedItem.user.reports
																					? selectedItem.user.reports.length >= 1
																						? 'error'
																						: 'success'
																					: 'success'
																			"
																			>{{ selectedItem.user.reports ? selectedItem.user.reports.length : 0 }}</v-chip
																		>
																	</v-row>
																</v-container>
															</div>
														</v-list>
													</v-card-text>
												</v-card>

												<v-card v-if="!selectedItem.user" class="my-4" max-width="480" color="red lighten-3">
													<v-card-text>
														<h3 class=" mx-4 text-center font-weight-black text-button">
															{{ $t('client_was_deleted') }}
														</h3>
														<div class="mx-auto">
															<lottie-player
																autoplay
																loop
																mode="normal"
																speed="1.5"
																src="/src/assets/animations/error-cone.json"
																style="width: 100px"
															></lottie-player>
														</div>
													</v-card-text>
												</v-card>
											</v-col>
										</v-row>
										<!-- PRODUCTS TABLE -->
										<v-card class="mt-4" outlined>
											<v-card-title>
												<span class="headline">{{ $t('order_info') }}</span>
												<v-spacer></v-spacer>
												<v-chip class="mr-2" :color="getStatusColor(selectedItem.order.status)" dark>{{
													getStatus(selectedItem.order)
												}}</v-chip>
												<v-chip class="mr-2" :color="getPaymentColor(selectedItem.order)" dark>{{
													selectedItem.order.paymentMethod.keyUS
												}}</v-chip>
												<v-chip class="mr-2" :color="getDeliveryColor(selectedItem.order)" dark>{{
													selectedItem.order.deliveryMethod.keyUS
												}}</v-chip>

												<div v-if="selectedItem.order.deliveryMethod.keyUS == 'SEAT'">
													<v-chip class="mr-2" dark small>{{ selectedItem.order.section.name }}</v-chip>
													<v-chip class="mr-2" dark small>{{ selectedItem.order.row.name }}</v-chip>
													<v-chip class="mr-2" dark small>{{ selectedItem.order.seat.name }}</v-chip>
												</div>
											</v-card-title>
											<v-card-text>
												<v-simple-table>
													<template v-slot:default>
														<thead>
															<tr>
																<th class="text-left">
																	{{ $t('product') }}
																</th>
																<th class="text-left">
																	{{ $t('quantity') }}
																</th>
																<th class="text-left">
																	{{ $t('total') }}
																</th>
															</tr>
														</thead>
														<tbody>
															<tr v-for="product in selectedItem.order.products" :key="product._id">
																<td>{{ product.product.name }}</td>
																<td>{{ product.quantity }}</td>
																<td>{{ getProductPrice(product) }}</td>
															</tr>
															<tr>
																<td></td>
																<td>
																	<h3>{{ $t('total') }}</h3>
																</td>
																<td>
																	<h3>
																		{{ getProductsTotal(selectedItem.order.products) }}
																	</h3>
																</td>
															</tr>
														</tbody>
													</template>
												</v-simple-table>
											</v-card-text>
										</v-card>
									</div>
									<!-- PLACEHOLDER -->
									<div v-if="reportLoading">
										<v-row>
											<v-col cols="12" sm="6">
												<v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
											</v-col>

											<v-col cols="12" sm="6" class="d-flex flex-row-reverse">
												<v-skeleton-loader class="mr-0" max-width="300" type="actions"></v-skeleton-loader>
											</v-col>
										</v-row>

										<v-row>
											<v-col cols="12" sm="4">
												<v-card>
													<v-skeleton-loader max-width="300" type="article, list-item-three-line"></v-skeleton-loader>
												</v-card>
											</v-col>

											<v-col cols="12" sm="4">
												<v-card>
													<v-skeleton-loader
														class="mr-0"
														max-width="300"
														type="article, list-item-two-line"
													></v-skeleton-loader>
												</v-card>
											</v-col>
										</v-row>

										<v-card class="my-5">
											<v-row>
												<v-col cols="12" sm="12">
													<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
													<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
												</v-col>
											</v-row>
										</v-card>
									</div>
								</v-card-text>
								<v-card-actions class="justify-end">
									<v-btn text @click="dialog.value = false">{{ $t('close') }}</v-btn>
								</v-card-actions>
							</v-card>
							<!-- CONFIRM DIALOG -->
							<v-dialog persistent v-model="disregardDialog" max-width="700px">
								<v-card>
									<v-card-title class=" justify-center">
										{{ $t('disregard_report_dialog_confirm') }}
									</v-card-title>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="error" text @click="disregardDialogClose">{{ $t('cancel') }}</v-btn>
										<v-btn color="success" text @click="disregardDialogConfirmed(selectedItem._id)">OK</v-btn>
										<v-spacer></v-spacer>
									</v-card-actions>
								</v-card>
							</v-dialog>
							<!-- END CONFIRM DIALOG -->

							<!-- REPORTING DIALOG -->
							<v-dialog persistent v-model="banningDialog" max-width="800px">
								<v-card>
									<v-card-title class=" justify-center">{{ $t('ban_user') }}</v-card-title>
									<v-card-text>
										<validation-observer ref="observer" v-slot="{ invalid }">
											<form @submit.prevent="submit">
												<v-row justify="center">
													<v-col cols="12" sm="4">
														<validation-provider
															v-slot="{ errors }"
															:name="$t('days_banned')"
															:rules="{
																required: !ban.permanent,
																numeric: true,
																min_value: 1,
															}"
														>
															<v-text-field
																v-model="ban.daysBanned"
																filled
																:label="$t('days_banned')"
																:error-messages="errors"
																clearable
																:disabled="ban.permanent"
															></v-text-field>
														</validation-provider>
													</v-col>
													<v-col cols="12" sm="4">
														<v-switch
															v-model="ban.permanent"
															inset
															:label="$t('ban_permanently')"
															color="green"
														></v-switch>
													</v-col>
												</v-row>

												<v-container class="mt-5">
													<v-btn class="mr-4" color="error" @click="banningDialogClose">
														{{ $t('close') }}
													</v-btn>
													<v-btn
														type="submit"
														color="success"
														:disabled="invalid"
														@click="banUserConfirmed(selectedItem._id)"
													>
														{{ $t('submit') }}
													</v-btn>
												</v-container>
											</form>
										</validation-observer>
									</v-card-text>
								</v-card>
							</v-dialog>
							<!-- END REPORTING DIALOG -->
							<!-- LOADING STATUS CHANGE DIALOG -->
							<v-dialog v-model="loadingDialog" hide-overlay persistent width="100">
								<v-card color="primary" dark>
									<v-card-text class="text-center">
										<v-progress-circular indeterminate color="white" size="50" class=" mt-4"></v-progress-circular>
									</v-card-text>
								</v-card>
							</v-dialog>

							<!-- END LOADING STATUS CHANGE DIALOG -->
						</template>
						<!-- END ORDER DETAIL DIALOG -->
					</v-dialog>
				</v-toolbar>
			</template>

			<!-- SLOTS FOR THE COLUMN ITEMS IN THE TABLE -->
			<template v-slot:[`item.created`]="{ item }">
				{{ getDateFormat(item.orderDate) }}
			</template>
			<template v-slot:[`item.restaurant.name`]="{ item }">
				{{ item.restaurant ? item.restaurant.name : 'ADMIN' }}
			</template>
			<template v-slot:[`item.order.total`]="{ item }"> {{ getPriceFormat(item.order.total) }} </template>

			<template v-slot:[`item.reason`]="{ item }">
				<v-chip :color="getReasonColor(item.reason)" dark>
					{{ getReasonText(item.reason) }}
				</v-chip>
			</template>

			<template v-slot:[`item.actionTaken`]="{ item }">
				<v-chip :color="item.actionTaken ? 'green' : 'red'" dark>
					{{ item.actionTaken ? $t('yes') : 'No' }}
				</v-chip>
			</template>
			<template v-slot:[`item.addressed`]="{ item }">
				<v-chip :color="item.addressed ? 'green' : 'red'" dark>
					{{ item.addressed ? $t('yes') : 'No' }}
				</v-chip>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon medium class="mr-2" @click="getDetailOrder(item)">
					mdi-arrow-right
				</v-icon>
			</template>
			<template v-slot:no-data>
				<v-sheet class="my-10" color="">
					<v-row justify="center">
						<v-sheet color="">
							<h2 class="text-h2 pa-3">{{ $t('no_reports') }}</h2>
						</v-sheet>
					</v-row>
					<v-row justify="center">
						<v-btn class="ma-4" color="primary" @click="getUserReports">{{ $t('refresh') }}</v-btn>
					</v-row>
				</v-sheet>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';
import moment from 'moment';

//import io from 'socket.io-client';
//import { v4 as uuidv4 } from 'uuid';
import { required, alpha_spaces, numeric, min_value } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
setInteractionMode('eager');

extend('alpha_spaces', {
	...alpha_spaces,
});
extend('required', {
	...required,
});
extend('numeric', {
	...numeric,
});
extend('min_value', {
	...min_value,
});

export default {
	components: { ValidationProvider, ValidationObserver },
	data() {
		return {
			search: '',
			dialog: false,
			dialogStatus: false,
			dialogStatusText: '',
			dialogStatusColor: 'error',
			wantedStatus: null,
			loadingDialog: false,
			disregardDialog: false,
			banningDialog: false,
			/* order VARS */

			reportDialog: false,
			reportLoading: true,

			loadingTable: false,

			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',

			detailAlert: false,
			detailAlertMessage: '',
			detailAlertType: 'error',

			reasons: [
				{ id: 1, text: this.$i18n.t('order_out_of_time') },
				{ id: 2, text: this.$i18n.t('product_out_of_stock') },
				{ id: 3, text: this.$i18n.t('technical_difficulties') },
				{ id: -1, text: this.$i18n.t('other_reason') },
				// { id: 4, keyUS: 'Card Declined', keyMX: 'Tarjeta Rechazada' },
				// { id: 5, keyUS: 'Card Declined Too many times.', keyMX: 'Tarjeta Rechazada Demasiadas Veces' }
			],
			reportUserReasons: [
				{ id: 1, text: this.$i18n.t('missing_failed_to_pay') },
				{ id: 2, text: this.$i18n.t('spam_orders') },
				{ id: 3, text: this.$i18n.t('refused_to_pay') },
				{ id: -1, text: this.$i18n.t('other_reason') },
			],
			ban: {
				daysBanned: null,
				permanent: false,
			},
			reports: [],
			selectedIndex: -1,
			selectedItem: null,

			numberRule: (v) => {
				//if (!v.trim()) return true;
				if (!isNaN(parseFloat(v)) && v >= 0) return true;
				return 'The number has to be greater than 0';
			},
			descriptionRules: (v) => {
				//if (!v.trim()) return true;
				if (!v || v.length <= 130) return true;
				return 'Max 130 characters';
			},
		};
	},
	computed: {
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},
		tableHeaders() {
			var headers = [
				{ text: this.$i18n.t('restaurant'), value: 'restaurant.name' },

				{
					text: this.$i18n.t('client'),
					value: 'user.email',
					align: 'center',
				},
				{
					text: this.$i18n.t('reason'),
					value: 'reason',
					align: 'center',
				},
				{ text: this.$i18n.t('addressed'), value: 'addressed', align: 'center' },
				{ text: this.$i18n.t('action_taken'), value: 'actionTaken', align: 'center' },
				{ text: this.$i18n.t('date'), value: 'created', align: 'center' },
				{ text: this.$i18n.t('total'), value: 'order.total', align: 'center' },
				{ text: '', value: 'actions', sortable: false },
			];

			return headers;
		},
		isReportsEmpty() {
			if (!this.reports) {
				return true;
			} else if (this.reports.length == 0) {
				return true;
			}
			return false;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		submit() {
			this.$refs.observer.validate();
			//login
		},

		showBanDialog() {
			this.banningDialog = true;
		},
		banningDialogClose() {
			this.banningDialog = false;
			this.ban = {
				daysBanned: null,
				permanent: false,
			};
		},
		getStatusColor(status) {
			switch (status) {
				case -2:
					return 'error';

				case -1:
					return 'red';

				case 0:
					return 'blue';

				case 1:
					return 'amber';

				case 2:
					return 'orange'; //item.deliveryMethod.keyUS === 'PICKUP' ? 'READY' : 'ON THE WAY';

				case 3:
					return 'success';

				default:
					return 'purple';
			}
		},

		getStatus(item) {
			switch (item.status) {
				case -2:
					return this.$i18n.t('rejected');

				case -1:
					return this.$i18n.t('cancelled');

				case 0:
					return this.$i18n.t('new_order');

				case 1:
					return this.$i18n.t('preparing');

				case 2:
					return item.deliveryMethod.keyUS === 'PICKUP' ? this.$i18n.t('ready') : this.$i18n.t('on_the_way');

				case 3:
					return this.$i18n.t('delivered');

				default:
					return 'ERROR';
			}
		},

		getStatusDialog(item, status) {
			switch (status) {
				case -2:
					return this.$i18n.t('rejected');

				case -1:
					return this.$i18n.t('cancelled');

				case 0:
					return this.$i18n.t('new_order');

				case 1:
					return this.$i18n.t('preparing');

				case 2:
					return item.deliveryMethod.keyUS === 'PICKUP' ? this.$i18n.t('ready') : this.$i18n.t('on_the_way');

				case 3:
					return this.$i18n.t('delivered');

				default:
					return 'ERROR';
			}
		},
		getProductPrice(item) {
			////console.log(item);
			var formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});

			return formatter.format(item.quantity * item.product.price);
		},

		getProductsTotal(products) {
			var total = 0;
			products.forEach((product) => {
				total += product.product.price * product.quantity;
			});

			return this.getPriceFormat(total);
		},
		getRatingColor(item) {
			switch (item.status) {
				case 0:
					return 'blue';

				case 1:
					return 'red';

				case 2:
					return 'deep-orange'; //item.deliveryMethod.keyUS === 'PICKUP' ? 'READY' : 'ON THE WAY';

				case 3:
					return 'amber';
				case 4:
					return 'light-green';

				case 5:
					return 'green';

				default:
					return 'purple';
			}
		},

		getReasonText(reason) {
			switch (reason) {
				case -1:
					return this.$i18n.t('other_reason');

				case 1:
					return this.$i18n.t('missing_failed_to_pay');

				case 2:
					return this.$i18n.t('spam_orders');

				case 3:
					return this.$i18n.t('refused_to_pay');
			}
		},
		getReasonColor(reason) {
			switch (reason) {
				case -1:
					return 'purple';
				case 1:
					return 'red';
				case 2:
					return 'orange';
				case 3:
					return 'amber';
			}
		},
		getDeliveryText(item) {
			switch (item.deliveryMethod.keyUS) {
				case 'SEAT':
					return this.$i18n.t('seat_method');

				case 'PICKUP':
					return this.$i18n.t('pickup_method');

				default:
					return 'ERROR';
			}
		},
		getPaymentText(item) {
			switch (item.paymentMethod.keyUS) {
				case 'CASH':
					return this.$i18n.t('cash');

				case 'CARD':
					return this.$i18n.t('card');

				default:
					return 'ERROR';
			}
		},

		getDeliveryColor(item) {
			switch (item.deliveryMethod.keyUS) {
				case 'SEAT':
					return 'cyan';

				case 'PICKUP':
					return 'pink';

				default:
					return 'purple';
			}
		},
		getPaymentColor(item) {
			switch (item.paymentMethod.keyUS) {
				case 'CASH':
					return 'light-green';

				case 'CARD':
					return 'amber';

				default:
					return 'purple';
			}
		},
		getPriceFormat(price) {
			var formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			});

			return formatter.format(price);
		},

		getImage(name) {
			////console.log(process.env.VUE_APP_IMAGE_URL + name);
			return process.env.VUE_APP_IMAGE_URL + name;
		},
		getPhoneFormat(str) {
			//Filter only numbers from the input
			const cleaned = ('' + str).replace(/\D/g, '');
			const areacode = cleaned.length - 10;
			var match;
			switch (areacode) {
				case 0:
					match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
					break;
				case 1:
					match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
					break;
				case 2:
					match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
					break;
				case 3:
					match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{4})$/);
					break;

				default:
					match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
					break;
			}

			if (match) {
				if (match.length == 4) {
					return '(' + match[1] + ') ' + match[2] + '-' + match[3];
				} else {
					return ' + ' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
				}
			}

			return null;
		},

		getStatusButtonsLabel(order) {
			switch (order.status) {
				case 0:
					return this.$i18n.t('prepare');

				case 1:
					return order.deliveryMethod.keyUS === 'PICKUP' ? this.$i18n.t('ready') : this.$i18n.t('on_the_way');

				case 2:
					return this.$i18n.t('delivered');

				default:
					return 'ERROR';
			}
		},

		getStatusButtonsColor(order) {
			switch (order.status) {
				case 0:
					return 'yellow';

				case 1:
					return 'deep-orange';

				case 2:
					return 'success';

				default:
					return 'ERROR';
			}
		},

		async getDetailOrder(item) {
			////console.log(item._id);
			this.selectedIndex = this.reports.indexOf(item);
			this.selectedItem = Object.assign({}, item);
			this.reportLoading = true;
			this.reportDialog = true;
			const report = await this.getUserReportDetail(item._id);
			//console.log(report);
			this.selectedItem = Object.assign({}, report);
			setTimeout(() => {
				this.reportLoading = false;
			}, 200);
		},

		// editItem(item) {
		// 	this.editedIndex = this.products.indexOf(item);
		// 	this.editedItem = Object.assign({}, item);
		// 	this.imageUrl = this.getImage(this.editedItem.image);
		// 	this.dialog = true;
		// },
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},

		setupDetailAlert(active, message, type) {
			this.detailAlert = active;
			this.detailAlertMessage = message;
			this.detailAlertType = type;
			setTimeout(() => {
				this.detailAlert = false;
			}, 2000);
		},
		getDateFormat(string) {
			return moment(string).format(this.$i18n.t('date_time_format'));
		},

		showChangeStatusDialog(order, rejected) {
			if (rejected) {
				this.wantedStatus = -2;
			} else {
				this.wantedStatus = order.status + 1;
			}
			this.dialogStatusColor = this.getStatusColor(this.wantedStatus);
			this.dialogStatusText = this.getStatusDialog(order, this.wantedStatus);
			this.dialogStatus = true;
		},

		async disregardDialogConfirmed(id) {
			await this.disregardReport(id);
			this.selectedItem.addressed = true;
			this.disregardDialogClose();
		},

		disregardDialogClose() {
			this.disregardDialog = false;
		},

		async changeStatusOrder(order) {
			await this.setNewStatus(order, this.wantedStatus);
			this.wantedStatus = 0;
		},

		async banUserConfirmed(id) {
			await this.banUser(id, this.ban);
			this.selectedItem.actionTaken = true;
			this.selectedItem.addressed = true;
			this.banningDialogClose();
		},

		// NETWORK LOGIC

		async getUserReports() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				const ordersURL = `user/report`;

				await fetch(process.env.VUE_APP_API_URL + ordersURL, options)
					.then((response) => response.json())
					.then((data) => {
						//console.log(data.data);
						this.reports = data.data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
				this.loadingTable = false;
			}
		},
		async disregardReport(id) {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				const disregardURL = `user/report/${id}/disregard`;

				await fetch(process.env.VUE_APP_API_URL + disregardURL, options).then((response) => response.json());

				this.setupDetailAlert(true, this.$i18n.t('disregarded_successfully'), 'success');

				this.getUserReports();
			} catch (error) {
				//console.log(error);
				this.setupDetailAlert(true, error, 'error');
			}
		},
		async banUser(id, ban) {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				if (ban.permanent) {
					formData.append('permanent', ban.permanent);
				}
				if (ban.daysBanned) {
					formData.append('daysBanned', ban.daysBanned);
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					body: formData,
					method: 'POST',
				};
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `user/report/${id}/ban`, options)
					.then((response) => response.json())
					.then(() => {
						////console.log('CATEGORY');
						//console.log(data);
						this.setupDetailAlert(true, this.$i18n.t('banned_successfully'), 'success');

						this.getUserReports();
					});
			} catch (error) {
				//console.log(error);
				this.setupDetailAlert(true, error, 'error');
			}
		},

		async getUserReportDetail(id) {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				var response;
				await fetch(process.env.VUE_APP_API_URL + `user/report/${id}`, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						//console.log(data);
						response = data.data;
					});
				return response;
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {},
	async mounted() {
		await this.getUserReports();
	},
};
</script>

<style scoped>
lottie-player {
	margin: 0 auto;
}
</style>
