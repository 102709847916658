<template>
	<v-card :elevation="0" color="transparent">
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>
		<v-card-title class="font-weight-bold">
			<h1>{{ $t('categories') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>
		<v-data-table
			:headers="tableHeaders"
			:items="categories"
			:items-per-page="10"
			:search="search"
			:loading="loadingTable"
			multi-sort
		>
			<template v-slot:top>
				<v-toolbar flat color="transparent">
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="600px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
								{{ $t('add_category') }}
							</v-btn>
						</template>
						<v-card>
							<v-card-title class="d-flex justify-center">
								<span class="headline">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text class="d-flex justify-center my-5">
								<!-- INPUTS  -->

								<validation-observer ref="observer" v-slot="{ invalid }">
									<form @submit.prevent="submit">
										<v-row justify="center">
											<v-col cols="6" sm="12">
												<validation-provider v-slot="{ errors }" :name="$t('name')" rules="required|alpha_spaces">
													<v-text-field
														v-model="editedItem.name"
														:error-messages="errors"
														:label="$t('name')"
														filled
														required
													></v-text-field>
												</validation-provider>
											</v-col>
											<v-col cols="6" sm="12">
												<validation-provider
													v-slot="{ errors }"
													:name="$t('priority')"
													:rules="{ required: true, min_value: 0, max_value: 10 }"
												>
													<v-text-field
														v-model="editedItem.priority"
														:error-messages="errors"
														:label="$t('priority')"
														filled
														required
													></v-text-field>
												</validation-provider>
											</v-col>
										</v-row>
										<v-row justify="center">
											<v-col cols="12" sm="12">
												<validation-provider
													v-slot="{ errors }"
													:name="$t('business')"
													rules="required"
													v-if="accessLevel == 'admin'"
												>
													<v-select
														:items="businesses"
														item-text="name"
														item-value="_id"
														v-model="editedItem.restaurant._id"
														:error-messages="errors"
														filled
														:label="$t('business')"
													></v-select>
												</validation-provider>
											</v-col>
										</v-row>

										<v-container class="mt-5">
											<v-btn class="mr-4" color="error" @click="close">
												{{ $t('close') }}
											</v-btn>
											<v-btn type="submit" color="success" :disabled="invalid" @click="save">
												{{ AddButtonText }}
											</v-btn>
										</v-container>
									</form>
								</validation-observer>
							</v-card-text>

							<!-- <v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" @click="close">
									Cancel
								</v-btn>
								<v-btn color="success" @click="save">
									{{ AddButtonText }}
								</v-btn>
							</v-card-actions> -->
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="600px">
						<v-card>
							<v-card-title class="headline justify-center">{{ $t('delete_dialog_confirm_category') }}</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
								<v-btn color="error" text @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<!-- <template v-slot:[`item.birthday`]="{ item }">
				{{ getDateFormat(item.birthday) }}
			</template> -->
			<!-- <template v-slot:[`item.image`]="{ item }">
				<v-avatar class="ma-1">
					<img :src="getImage(item.image)" alt="no image" />
				</v-avatar>
			</template> -->
			<!-- <template v-slot:[`item.total_given`]="{ item }">
				<v-chip :color="getColor(item.total_given)" dark>
					{{ item.total_given }}
				</v-chip>
			</template> -->
			<!-- <template v-slot:[`item.available`]="{ item }">
				<v-chip :color="getColorActive(item.available)" dark>
					{{ item.available ? 'Available' : 'Unavailable' }}
				</v-chip>
			</template>
			<template v-slot:[`item.hasAlcohol`]="{ item }">
				<v-chip :color="getColorActive(item.hasAlcohol)" dark>
					{{ item.hasAlcohol ? 'Yes' : 'No' }}
				</v-chip>
			</template> -->
			<!-- <template v-slot:[`item.gender`]="{ item }">
				<v-chip :color="getColorGender(item.gender)" dark>
					{{ item.gender === 'female' ? 'Mujer' : 'Hombre' }}
				</v-chip>
			</template> -->
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="mr-2" @click="editItem(item)">
					mdi-pencil
				</v-icon>
				<v-icon small @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>
			<template v-slot:no-data>
				<v-sheet class="my-10" color="">
					<v-row justify="center">
						<v-sheet color="">
							<h2 class="text-h2 pa-3">{{ $t('no_categories') }}</h2>
						</v-sheet>
					</v-row>
					<v-row justify="center">
						<v-btn class="ma-4" color="primary" @click="getCategories">{{ $t('refresh') }}</v-btn>
					</v-row>
				</v-sheet>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';
import moment from 'moment';
import { required, alpha_spaces, max_value, min_value } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
setInteractionMode('eager');

extend('alpha_spaces', {
	...alpha_spaces,
});
extend('max_value', {
	...max_value,
});
extend('min_value', {
	...min_value,
});
extend('required', {
	...required,
});

export default {
	components: { ValidationProvider, ValidationObserver },
	data() {
		return {
			search: '',
			dialog: false,
			dialogDelete: false,
			/* NEW CATEGORY VARS */
			// newImage: null,
			// imageUrl: null,
			loadingTable: false,
			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',
			businesses: [],
			categories: [],
			editedIndex: -1,
			editedItem: {
				_id: '',
				name: '',
				priority: 0,
				restaurant: {
					_id: '',
					name: '',
				},
			},
			defaultItem: {
				_id: '',
				name: '',
				priority: 0,
				restaurant: {
					_id: '',
					name: '',
				},
			},
			numberRule: (v) => {
				//if (!v.trim()) return true;
				if (!isNaN(parseFloat(v)) && v >= 0) return true;
				return 'The number has to be greater than 0';
			},
			descriptionRules: (v) => {
				//if (!v.trim()) return true;
				if (!v || v.length <= 130) return true;
				return 'Max 130 characters';
			},
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? this.$i18n.t('new_category') : this.$i18n.t('edit_category');
		},
		AddButtonText() {
			return this.editedIndex === -1 ? this.$i18n.t('add') : this.$i18n.t('save');
		},
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},
		tableHeaders() {
			var headers = [
				{ text: this.$i18n.t('name'), value: 'name' },
				{ text: this.$i18n.t('priority_with_scale'), value: 'priority' },
				{ text: this.$i18n.t('actions'), value: 'actions', sortable: false },
			];

			if (this.accessLevel == 'admin') {
				const restaurants = {
					text: this.$i18n.t('business'),
					value: 'restaurant.name',
					align: 'center',
				};
				const venue = {
					text: 'Venue',
					value: 'restaurant.stadium.name',
					align: 'center',
					width: '200',
				};
				headers.unshift(restaurants);
				headers.unshift(venue);
			}
			return headers;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		getColorActive(active) {
			if (!active) return 'red';
			else return 'green';
		},

		getPaymentDate(date) {
			return moment(date).format('L');
		},
		getImage(name) {
			////console.log(process.env.VUE_APP_IMAGE_URL + name);
			return process.env.VUE_APP_IMAGE_URL + name;
		},
		onFileChange(e) {
			if (e) {
				const file = e;
				this.imageUrl = URL.createObjectURL(file);
				this.newImage = file;
			}
		},
		resetImage() {
			this.imageUrl = null;
			this.newImage = null;
		},
		editItem(item) {
			this.editedIndex = this.categories.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.imageUrl = this.getImage(this.editedItem.image);
			this.dialog = true;
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},

		deleteItem(item) {
			this.editedIndex = this.categories.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.deleteCategory();
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				//edit
				//Object.assign(this.people[this.editedIndex], this.editedItem);
				this.editCategory();
			} else {
				//add
				//this.people.push(this.editedItem);
				this.addCategory();
			}
			this.close();
		},

		// NETWORK LOGIC

		async getCategories() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				var categoryURL = `category/restaurant/${user._id}`;
				if (user.accessLevel == 'admin') {
					categoryURL = 'category/';
				}

				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + categoryURL, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						////console.log(data.data);
						this.categories = data.data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
				this.loadingTable = false;
			}
		},

		async getBusinesses() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				await fetch(process.env.VUE_APP_API_URL + 'business/', options)
					.then((response) => response.json())
					.then((data) => {
						////console.log(data.data);
						this.businesses = data.data;
					});
			} catch (error) {
				//console.log(error);

				this.setupAlert(true, error, 'error');
			}
		},

		async addCategory() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('name', this.editedItem.name);
				formData.append('priority', this.editedItem.priority);

				if (this.accessLevel == 'business') {
					/*
					!ONLY IF ITS BUSINESS
					*/
					formData.append('restaurant', user.business._id);
				} else if (this.accessLevel == 'admin') {
					formData.append('restaurant', this.editedItem.restaurant._id);
				}
				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'category/', requestOptions).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_category_added'), 'success');
					}
					//response.json();
				});
				this.getCategories();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async editCategory() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				formData.append('name', this.editedItem.name);
				formData.append('priority', this.editedItem.priority);

				if (this.accessLevel == 'business') {
					/*
					!ONLY IF ITS BUSINESS
					*/
					formData.append('restaurant', user.business._id);
				} else if (this.accessLevel == 'admin') {
					formData.append('restaurant', this.editedItem.restaurant._id);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + `category/${this.editedItem._id}`, requestOptions).then(
					(response) => {
						if (response.ok) {
							this.setupAlert(true, this.$i18n.t('alert_msg_category_updated'), 'success');
						}
					},
				);
				this.getCategories();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async deleteCategory() {
			try {
				const token = localStorage.getItem('weserv.access');

				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				await fetch(process.env.VUE_APP_API_URL + `category/${this.editedItem._id}`, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'DELETE',
				}).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_category_deleted'), 'success');
					}
				});
				this.getCategories();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		this.getCategories();
		if (this.accessLevel == 'admin') {
			this.getBusinesses();
		}
	},
};
</script>

<style scoped></style>
