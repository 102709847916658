<template>
	<v-card :elevation="0" color="transparent">
		<!-- ALERT -->
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>
		<!-- TITLE AND SEARCH BAR -->
		<v-card-title class="font-weight-bold">
			<h1>{{ $t('daily_orders') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>

		<v-row>
			<v-col cols="12" lg="8" md="8" sm="8">
				<v-card outlined color="secondary" class="py-4">
					<v-row class=" pa-3" justify="center">
						<v-card class="ma-2" v-for="(status, index) in statuses" :key="status.number">
							<v-col cols="12" sm="12" md="12" lg="12">
								<v-chip :color="getStatusColor(status.number)" dark>{{ status.text }}</v-chip>
								<h2 class="text-h4 text-center mt-1 ">{{ statusCount[index] }}</h2>
							</v-col>
						</v-card>
					</v-row>
				</v-card>
			</v-col>
			<v-col cols="12" lg="4" md="4" sm="4">
				<v-card outlined color="tertiary" class="py-4">
					<v-row class=" pa-3" justify="center">
						<v-card class="ma-2">
							<v-col cols="12" sm="12" md="12" lg="12">
								<v-chip color="green" dark>{{ $t('daily_revenue').toUpperCase() }}</v-chip>
								<h2 class="text-h4 text-center mt-1 ">{{ getPriceFormat(orderTotal) }}</h2>
							</v-col>
						</v-card>
						<v-card class="ma-2">
							<v-col cols="12" sm="12" md="12" lg="12">
								<v-chip color="blue" dark>{{ $t('number_orders').toUpperCase() }}</v-chip>
								<h2 class="text-h4 text-center mt-1 ">{{ orders.length }}</h2>
							</v-col>
						</v-card>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<!-- DATA TABLE FOR ORDERS -->
		<v-data-table
			:headers="tableHeaders"
			:items="orders"
			:items-per-page="10"
			:search="search"
			multi-sort
			:loading="loadingTable"
		>
			<template v-slot:top>
				<v-toolbar flat color="transparent">
					<v-spacer></v-spacer>
					<v-btn
						v-if="!isOrdersEmpty && accessLevel != 'employee'"
						outlined
						color="light-green"
						dark
						@click="downloadExcel"
						>{{ $t('download_report') }}</v-btn
					>
					<!-- ORDER DETAIL DIALOG -->
					<v-dialog v-model="orderDialog" transition="dialog-bottom-transition" max-width="1400">
						<template v-slot:default="dialog">
							<v-card>
								<v-toolbar color="primary" dark>
									<v-toolbar-title>{{ $t('order_number') }} - {{ selectedItem.number }}</v-toolbar-title>
									<v-spacer></v-spacer>
									<v-rating
										:value="selectedItem.rating"
										length="5"
										color="amber"
										background-color="grey lighten-1"
										readonly
										medium
									></v-rating>
								</v-toolbar>

								<v-alert
									v-model="detailAlert"
									transition="slide-y-transition"
									border="left"
									close-text="Close Alert"
									:type="detailAlertType"
									dismissible
								>
									{{ detailAlertMessage }}
								</v-alert>

								<v-card-text class="my-10">
									<div v-if="!orderLoading">
										<v-row class="pa-4">
											<h2 class="text-h5 font-weight-bold">{{ $t('date') }}:</h2>
											<h4 class="ml-4 text-h5">
												{{ `${getDateFormat(selectedItem.orderDate)}, ${getTimeFormat(selectedItem.orderDate)}` }}
											</h4>
											<v-spacer></v-spacer>
											<!--  STATUS BUTTONS -->
											<v-btn
												v-if="selectedItem.status != 3 && selectedItem.status >= 0"
												:color="getStatusButtonsColor(selectedItem)"
												dark
												@click="showChangeStatusDialog(selectedItem, false)"
												>{{ getStatusButtonsLabel(selectedItem) }}</v-btn
											>
											<v-btn
												v-if="selectedItem.status == 0"
												class="ml-4"
												color="error"
												@click="showChangeStatusDialog(selectedItem, true)"
												>{{ $t('reject') }}</v-btn
											>
										</v-row>
										<v-row class="pa-4" v-if="accessLevel == 'admin'">
											<h2 class="text-h5 font-weight-bold">{{ $t('business') }}:</h2>
											<h4 class="ml-4 text-h5">
												{{ selectedItem.restaurant.name }}
											</h4>
										</v-row>
										<v-row justify="center">
											<!-- ORDER DETAILS -->
											<v-col cols="12" sm="6">
												<v-card max-width="480">
													<v-card-text>
														<v-list>
															<v-row align="center" class="pa-3">
																<h3>{{ $t('status') }}:</h3>
																<v-spacer />
																<v-chip :color="getStatusColor(selectedItem.status)" dark>{{
																	getStatus(selectedItem)
																}}</v-chip>
															</v-row>
															<v-row align="center" class="pa-3">
																<h3>{{ $t('delivery_method') }}:</h3>
																<v-spacer />
																<v-chip :color="getDeliveryColor(selectedItem)" dark>{{
																	getDeliveryText(selectedItem)
																}}</v-chip>
															</v-row>

															<v-row align="center" class="pa-3">
																<h3>{{ $t('payment_method') }}:</h3>
																<v-spacer />
																<v-chip :color="getPaymentColor(selectedItem)" dark>{{
																	getPaymentText(selectedItem)
																}}</v-chip>
															</v-row>
															<v-row v-if="selectedItem.processedBy" align="center" class="pa-3">
																<h3>{{ $t('processed_by') }}:</h3>
																<v-spacer />
																<v-chip color="light-green" dark>{{
																	`${selectedItem.processedBy.firstname} ${selectedItem.processedBy.lastname}`
																}}</v-chip>
															</v-row>
															<div v-if="selectedItem.deliveryMethod.keyUS == 'SEAT'">
																<v-divider class="mt-4"></v-divider>
																<v-container class="mt-3">
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('section') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ selectedItem.section.name }}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('row') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ selectedItem.row.name }}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('seat') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ selectedItem.seat.name }}</v-chip>
																	</v-row>
																</v-container>
															</div>
														</v-list>
													</v-card-text>
												</v-card>
											</v-col>
											<!-- ORDER CLIENT DETAILS -->
											<v-col cols="12" sm="6">
												<v-card v-if="selectedItem.user" max-width="480">
													<v-card-text>
														<v-list>
															<v-row align="center" class="pa-3">
																<h3>{{ $t('client') }}:</h3>
																<v-spacer />
																<v-chip color="light-blue" dark>{{
																	`${selectedItem.user.firstname} ${selectedItem.user.lastname}`
																}}</v-chip>
															</v-row>

															<div>
																<v-divider class="mt-4"></v-divider>
																<v-container class="mt-3">
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('phone') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ getPhoneFormat(selectedItem.user.phone) }}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('email') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ selectedItem.user.email }}</v-chip>
																	</v-row>
																</v-container>
															</div>
															<v-divider class="mt-4"></v-divider>
															<v-btn block class="mt-2" color="red" dark @click="reportingDialog = true"
																>{{ $t('report_user')
																}}<v-icon right dark>
																	mdi-cancel
																</v-icon></v-btn
															>
														</v-list>
													</v-card-text>
												</v-card>
												<v-card v-show="selectedItem.status == -2" class="my-4" max-width="480">
													<v-card-text>
														<v-list>
															<v-row align="center" class="pl-3 pa-2">
																<h3>{{ $t('rejection_information') }}</h3>
															</v-row>

															<div>
																<v-divider class="mt-4"></v-divider>
																<v-container class="mt-3">
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('reason') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ getReasonText(selectedItem.reason) }}</v-chip>
																	</v-row>
																	<v-row align="center" class="pa-1">
																		<h3>{{ $t('comments') }}</h3>
																		<v-spacer />
																		<v-chip dark small>{{ selectedItem.comments }}</v-chip>
																	</v-row>
																</v-container>
															</div>
														</v-list>
													</v-card-text>
												</v-card>
												<v-card v-if="!selectedItem.user" class="my-4" max-width="480" color="red lighten-3">
													<v-card-text>
														<h3 class=" mx-4 text-center font-weight-black text-button">
															{{ $t('client_was_deleted') }}
														</h3>
														<div class="mx-auto">
															<lottie-player
																autoplay
																loop
																mode="normal"
																speed="1.5"
																src="/src/assets/animations/error-cone.json"
																style="width: 100px"
															></lottie-player>
														</div>
													</v-card-text>
												</v-card>
											</v-col>
										</v-row>
										<!-- PRODUCTS TABLE -->
										<v-card class="mt-4">
											<v-card-text>
												<v-simple-table>
													<template v-slot:default>
														<thead>
															<tr>
																<th class="text-left">
																	{{ $t('product') }}
																</th>
																<th class="text-left">
																	{{ $t('quantity') }}
																</th>
																<th class="text-left">
																	{{ $t('total') }}
																</th>
															</tr>
														</thead>
														<tbody>
															<tr v-for="product in selectedItem.products" :key="product._id">
																<td>{{ product.product.name }}</td>
																<td>{{ product.quantity }}</td>
																<td>{{ getProductPrice(product) }}</td>
															</tr>
															<tr v-if="selectedItem.stadium.country == 'US'">
																<td></td>
																<td>
																	<h3>{{ $t('subtotal') }}</h3>
																</td>
																<td>
																	<h3>
																		{{ getProductsTotal(selectedItem.products) }}
																	</h3>
																</td>
															</tr>
															<tr>
																<td></td>
																<td>
																	<h3>{{ $t('total') }}</h3>
																</td>
																<td>
																	<h3>
																		{{ getProductsTotal(selectedItem.products, selectedItem.stadium) }}
																	</h3>
																</td>
															</tr>
														</tbody>
													</template>
												</v-simple-table>
											</v-card-text>
										</v-card>
									</div>
									<!-- PLACEHOLDER -->
									<div v-if="orderLoading">
										<v-row>
											<v-col cols="12" sm="6">
												<v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
											</v-col>

											<v-col cols="12" sm="6" align-self="center" class="d-flex flex-row-reverse">
												<v-skeleton-loader class="mr-0" max-width="300" type="actions"></v-skeleton-loader>
											</v-col>
										</v-row>

										<v-row>
											<v-col cols="12" sm="4">
												<v-card>
													<v-skeleton-loader max-width="300" type="article, list-item-three-line"></v-skeleton-loader>
												</v-card>
											</v-col>

											<v-col cols="12" sm="4">
												<v-card>
													<v-skeleton-loader
														class="mr-0"
														max-width="300"
														type="article, list-item-two-line"
													></v-skeleton-loader>
												</v-card>
											</v-col>
										</v-row>

										<v-card class="my-5">
											<v-row>
												<v-col cols="12" sm="12">
													<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
													<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
												</v-col>
											</v-row>
										</v-card>
									</div>
								</v-card-text>
								<v-card-actions class="justify-end">
									<v-btn text @click="dialog.value = false">{{ $t('close') }}</v-btn>
								</v-card-actions>
							</v-card>
							<!-- CONFIRM DIALOG -->
							<v-dialog persistent v-model="dialogStatus" max-width="700px">
								<v-card>
									<v-card-title class=" justify-center">
										<span>{{ $t('status_change_text') }}</span
										>&nbsp; <span :class="`${dialogStatusColor}--text`">{{ dialogStatusText }}</span
										>&nbsp;
										<span>?</span>
									</v-card-title>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="error" text @click="cancelStatusChange">{{ $t('cancel') }}</v-btn>
										<v-btn color="success" text @click="confirmStatusChange(selectedItem)">OK</v-btn>
										<v-spacer></v-spacer>
									</v-card-actions>
								</v-card>
							</v-dialog>
							<!-- END CONFIRM DIALOG -->
							<!-- REJECTION DIALOG -->
							<v-dialog persistent v-model="rejectedDialog" max-width="800px">
								<v-card>
									<v-card-title class=" justify-center">{{ $t('rejection_information') }}</v-card-title>
									<v-card-text>
										<validation-observer ref="observer" v-slot="{ invalid }">
											<form @submit.prevent="submit">
												<v-row justify="center">
													<validation-provider v-slot="{ errors }" :name="$t('reason')" rules="required">
														<v-select
															v-model="selectedItem.reason"
															:items="reasons"
															filled
															chips
															:label="$t('reason')"
															:error-messages="errors"
															item-text="text"
															item-value="id"
														></v-select>
													</validation-provider>
												</v-row>
												<v-row justify="center">
													<validation-provider v-slot="{ errors }" :name="$t('comments')" rules="required|alpha_spaces">
														<v-textarea
															v-model="selectedItem.comments"
															:error-messages="errors"
															:label="$t('comments')"
															filled
															required
														></v-textarea>
													</validation-provider>
												</v-row>

												<v-container class="mt-5">
													<v-btn class="mr-4" color="error" @click="rejectedDialogClose">
														{{ $t('close') }}
													</v-btn>
													<v-btn
														type="submit"
														color="success"
														:disabled="invalid"
														@click="rejectedDialogConfirmed(selectedItem)"
													>
														{{ $t('submit') }}
													</v-btn>
												</v-container>
											</form>
										</validation-observer>
									</v-card-text>
								</v-card>
							</v-dialog>
							<!-- END REJECTION DIALOG -->

							<!-- REPORTING DIALOG -->
							<v-dialog persistent v-model="reportingDialog" max-width="800px">
								<v-card>
									<v-card-title class=" justify-center">{{ $t('report_user') }}</v-card-title>
									<v-card-text>
										<validation-observer ref="observer" v-slot="{ invalid }">
											<form @submit.prevent="submit">
												<v-row justify="center">
													<validation-provider v-slot="{ errors }" :name="$t('reason')" rules="required">
														<v-select
															v-model="report.reason"
															:items="reportUserReasons"
															filled
															chips
															:label="$t('reason')"
															:error-messages="errors"
															item-text="text"
															item-value="id"
														></v-select>
													</validation-provider>
												</v-row>
												<v-row justify="center">
													<validation-provider v-slot="{ errors }" :name="$t('comments')" rules="required|alpha_spaces">
														<v-textarea
															v-model="report.comments"
															:error-messages="errors"
															:label="$t('comments')"
															filled
															required
														></v-textarea>
													</validation-provider>
												</v-row>

												<v-container class="mt-5">
													<v-btn class="mr-4" color="error" @click="reportingDialogClose">
														{{ $t('close') }}
													</v-btn>
													<v-btn type="submit" color="success" :disabled="invalid" @click="sendReportUser">
														{{ $t('submit') }}
													</v-btn>
												</v-container>
											</form>
										</validation-observer>
									</v-card-text>
								</v-card>
							</v-dialog>
							<!-- END REPORTING DIALOG -->
							<!-- LOADING STATUS CHANGE DIALOG -->
							<v-dialog v-model="loadingDialog" hide-overlay persistent width="100">
								<v-card color="primary" dark>
									<v-card-text class="text-center">
										<v-progress-circular indeterminate color="white" size="50" class=" mt-4"></v-progress-circular>
									</v-card-text>
								</v-card>
							</v-dialog>

							<!-- END LOADING STATUS CHANGE DIALOG -->
						</template>
						<!-- END ORDER DETAIL DIALOG -->
					</v-dialog>
				</v-toolbar>
			</template>

			<!-- SLOTS FOR THE COLUMN ITEMS IN THE TABLE -->
			<template v-slot:[`item.orderDate`]="{ item }">
				{{ getDateFormat(item.orderDate) }}
			</template>
			<template v-slot:[`item.time`]="{ item }">
				{{ getTimeFormat(item.orderDate) }}
			</template>
			<!-- <template v-slot:[`item.image`]="{ item }">
				<v-avatar class="ma-1">
					<img :src="getImage(item.image)" alt="no image" />
				</v-avatar>
			</template> -->
			<template v-slot:[`item.total`]="{ item }">
				{{ getPriceFormat(item.total) }}
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<v-chip :color="getStatusColor(item.status)" dark>
					{{ getStatus(item) }}
				</v-chip>
			</template>
			<template v-slot:[`item.rating`]="{ item }">
				<v-rating
					:value="item.rating"
					length="5"
					color="amber"
					background-color="grey lighten-1"
					readonly
					size="14"
				></v-rating>
			</template>
			<template v-slot:[`item.deliveryMethod.keyUS`]="{ item }">
				<v-chip :color="getDeliveryColor(item)" dark>
					{{ getDeliveryText(item) }}
				</v-chip>
			</template>

			<template v-slot:[`item.paymentMethod.keyUS`]="{ item }">
				<v-chip :color="getPaymentColor(item)" dark>
					{{ getPaymentText(item) }}
				</v-chip>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon medium class="mr-2" @click="getDetailOrder(item)">
					mdi-arrow-right
				</v-icon>
			</template>
			<template v-slot:no-data>
				<v-sheet class="my-10" color="">
					<v-row justify="center">
						<v-sheet color="">
							<h2 class="text-h2 pa-3">{{ $t('no_orders_today') }}</h2>
						</v-sheet>
					</v-row>
					<v-row justify="center">
						<v-btn class="ma-4" color="primary" @click="getOrders">{{ $t('refresh') }}</v-btn>
					</v-row>
				</v-sheet>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';
import moment from 'moment';
import io from 'socket.io-client';
//import { v4 as uuidv4 } from 'uuid';
import { required, alpha_spaces } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
setInteractionMode('eager');

extend('alpha_spaces', {
	...alpha_spaces,
});
extend('required', {
	...required,
});

export default {
	components: { ValidationProvider, ValidationObserver },
	data() {
		return {
			search: '',
			dialog: false,
			dialogStatus: false,
			dialogStatusText: '',
			dialogStatusColor: 'error',
			wantedStatus: null,
			loadingDialog: false,
			rejectedDialog: false,
			reportingDialog: false,
			/* order VARS */
			reason: '',
			comments: '',
			orderDialog: false,
			orderLoading: true,

			report: {
				reason: '',
				comments: '',
			},

			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',

			detailAlert: false,
			detailAlertMessage: '',
			detailAlertType: 'error',

			loadingTable: false,
			socket: null,

			reasons: [
				{ id: 1, text: this.$i18n.t('order_out_of_time') },
				{ id: 2, text: this.$i18n.t('product_out_of_stock') },
				{ id: 3, text: this.$i18n.t('technical_difficulties') },
				// { id: 4, keyUS: 'Card Declined', keyMX: 'Tarjeta Rechazada' },
				// { id: 5, keyUS: 'Card Declined Too many times.', keyMX: 'Tarjeta Rechazada Demasiadas Veces' }
			],
			reportUserReasons: [
				{ id: 1, text: this.$i18n.t('missing_failed_to_pay') },
				{ id: 2, text: this.$i18n.t('spam_orders') },
				{ id: 3, text: this.$i18n.t('refused_to_pay') },
				//{ id: 4, text: this.$i18n.t('technical_difficulties') },
				{ id: -1, text: this.$i18n.t('other_reason') },
				// { id: 4, keyUS: 'Card Declined', keyMX: 'Tarjeta Rechazada' },
				// { id: 5, keyUS: 'Card Declined Too many times.', keyMX: 'Tarjeta Rechazada Demasiadas Veces' }
			],
			statuses: [
				{ number: -2, text: this.$i18n.t('rejected'), qty: 0 },
				//{ number: -1, keyUS: 'CANCELLED', keyMX: 'CANCELADA' , qty: 0 },
				{ number: 0, text: this.$i18n.t('new_order'), qty: 0 },
				{ number: 1, text: this.$i18n.t('preparing'), qty: 0 },
				{
					number: 2,
					text: `${this.$i18n.t('ready')} | ${this.$i18n.t('on_the_way')}`,
					qty: 0,
				},
				{ number: 3, text: this.$i18n.t('delivered'), qty: 0 },
			],

			orders: [],
			selectedIndex: -1,
			selectedItem: null,

			numberRule: (v) => {
				//if (!v.trim()) return true;
				if (!isNaN(parseFloat(v)) && v >= 0) return true;
				return 'The number has to be greater than 0';
			},
			descriptionRules: (v) => {
				//if (!v.trim()) return true;
				if (!v || v.length <= 130) return true;
				return 'Max 130 characters';
			},
		};
	},
	computed: {
		statusCount() {
			var a = 0,
				b = 0,
				c = 0,
				d = 0,
				e = 0;
			this.orders.forEach((order) => {
				switch (order.status) {
					case -2:
						a += 1;
						break;
					// case -1:
					// 	b += 1;
					// 	break;
					case 0:
						b += 1;
						break;
					case 1:
						c += 1;
						break;
					case 2:
						d += 1;
						break;
					case 3:
						e += 1;
						break;
				}
			});
			return [a, b, c, d, e];
		},
		orderTotal() {
			var total = 0;
			this.orders.forEach((order) => {
				total += order.total;
			});
			return total;
		},
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},
		tableHeaders() {
			var headers = [
				{ text: this.$i18n.t('order_number'), value: 'number' },

				{
					text: this.$i18n.t('payment_method'),
					value: 'paymentMethod.keyUS',
					align: 'center',
				},
				{
					text: this.$i18n.t('delivery_method'),
					value: 'deliveryMethod.keyUS',
					align: 'center',
				},
				{ text: this.$i18n.t('status'), value: 'status', align: 'center' },
				{ text: this.$i18n.t('total'), value: 'total', align: 'center' },
				//{ text: '# Asistencias', value: 'total_assistance', align: 'center' },
				{ text: this.$i18n.t('rating'), value: 'rating', align: 'center', filterable: false },
				{ text: this.$i18n.t('time'), value: 'time' },
				{ text: this.$i18n.t('date'), value: 'orderDate' },
				{ text: '', value: 'actions', sortable: false },
			];
			//console.log(this.accessLevel);
			//console.log(this.accessLevel == 'admin');
			if (this.accessLevel == 'admin') {
				const restaurants = {
					text: this.$i18n.t('business'),
					value: 'restaurant.name',
					align: 'center',
				};
				const venue = {
					text: this.$i18n.t('venue'),
					value: 'stadium.name',
					align: 'center',
				};
				headers.unshift(restaurants);
				headers.unshift(venue);
			}
			return headers;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		submit() {
			this.$refs.observer.validate();
			//login
		},
		getStatusColor(status) {
			switch (status) {
				case -2:
					return 'error';

				case -1:
					return 'red';

				case 0:
					return 'blue';

				case 1:
					return 'amber';

				case 2:
					return 'orange'; //item.deliveryMethod.keyUS === 'PICKUP' ? 'READY' : 'ON THE WAY';

				case 3:
					return 'success';

				default:
					return 'purple';
			}
		},

		getStatus(item) {
			switch (item.status) {
				case -2:
					return this.$i18n.t('rejected');

				case -1:
					return this.$i18n.t('cancelled');

				case 0:
					return this.$i18n.t('new_order');

				case 1:
					return this.$i18n.t('preparing');

				case 2:
					return item.deliveryMethod.keyUS === 'PICKUP' ? this.$i18n.t('ready') : this.$i18n.t('on_the_way');

				case 3:
					return this.$i18n.t('delivered');

				default:
					return 'ERROR';
			}
		},

		getStatusDialog(item, status) {
			switch (status) {
				case -2:
					return this.$i18n.t('rejected');

				case -1:
					return this.$i18n.t('cancelled');

				case 0:
					return this.$i18n.t('new_order');

				case 1:
					return this.$i18n.t('preparing');

				case 2:
					return item.deliveryMethod.keyUS === 'PICKUP' ? this.$i18n.t('ready') : this.$i18n.t('on_the_way');

				case 3:
					return this.$i18n.t('delivered');

				default:
					return 'ERROR';
			}
		},

		getRatingColor(item) {
			switch (item.status) {
				case 0:
					return 'blue';

				case 1:
					return 'red';

				case 2:
					return 'deep-orange'; //item.deliveryMethod.keyUS === 'PICKUP' ? 'READY' : 'ON THE WAY';

				case 3:
					return 'amber';
				case 4:
					return 'light-green';

				case 5:
					return 'green';

				default:
					return 'purple';
			}
		},

		// getRatingText(item) {
		// 	switch (item.status) {
		// 		case 0:
		// 			return 'NO RATING';

		// 		case 1:
		// 			return '1 - VERY BAD';

		// 		case 2:
		// 			return '2 - BAD'; //item.deliveryMethod.keyUS === 'PICKUP' ? 'READY' : 'ON THE WAY';

		// 		case 3:
		// 			return '3 - DECENT';
		// 		case 4:
		// 			return '4 - GOOD';
		// 		case 5:
		// 			return '5 - VERY GOOD';

		// 		default:
		// 			return 'ERROR';
		// 	}
		// },

		getReasonText(reason) {
			switch (reason) {
				case 0:
					return this.$i18n.t('no_reason');

				case 1:
					return this.$i18n.t('order_out_of_time');

				case 2:
					return this.$i18n.t('product_out_of_stock'); //item.deliveryMethod.keyUS === 'PICKUP' ? 'READY' : 'ON THE WAY';

				case 3:
					return this.$i18n.t('technical_difficulties');
				case 4:
					return this.$i18n.t('card_declined');
				case 5:
					return this.$i18n.t('card_declined_too_many_times');

				default:
					return 'ERROR';
			}
		},

		getDeliveryColor(item) {
			switch (item.deliveryMethod.keyUS) {
				case 'SEAT':
					return 'cyan';

				case 'PICKUP':
					return 'pink';

				default:
					return 'purple';
			}
		},
		getDeliveryText(item) {
			switch (item.deliveryMethod.keyUS) {
				case 'SEAT':
					return this.$i18n.t('seat_method');

				case 'PICKUP':
					return this.$i18n.t('pickup_method');

				default:
					return 'ERROR';
			}
		},
		getPaymentText(item) {
			switch (item.paymentMethod.keyUS) {
				case 'CASH':
					return this.$i18n.t('cash');

				case 'CARD':
					return this.$i18n.t('card');

				default:
					return 'ERROR';
			}
		},
		getPaymentColor(item) {
			switch (item.paymentMethod.keyUS) {
				case 'CASH':
					return 'light-green';

				case 'CARD':
					return 'amber';

				default:
					return 'purple';
			}
		},
		getPriceFormat(price) {
			var formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});

			return formatter.format(price);
		},
		getProductPrice(item) {
			////console.log(item);
			var formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});

			return formatter.format(item.quantity * item.product.price);
		},
		getProductsTotal(products, stadium) {
			var total = 0;
			products.forEach((product) => {
				total += product.product.price * product.quantity;
			});

			if (stadium) {
				stadium.country == 'US' ? (total *= 1 + stadium.tax / 100) : total;
			}

			return this.getPriceFormat(total);
		},

		getImage(name) {
			////console.log(process.env.VUE_APP_IMAGE_URL + name);
			return process.env.VUE_APP_IMAGE_URL + name;
		},
		getPhoneFormat(str) {
			//Filter only numbers from the input
			const cleaned = ('' + str).replace(/\D/g, '');
			const areacode = cleaned.length - 10;
			var match;
			switch (areacode) {
				case 0:
					match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
					break;
				case 1:
					match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
					break;
				case 2:
					match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
					break;
				case 3:
					match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{4})$/);
					break;

				default:
					match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
					break;
			}

			if (match) {
				if (match.length == 4) {
					return '(' + match[1] + ') ' + match[2] + '-' + match[3];
				} else {
					return ' + ' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
				}
			}

			return null;
		},

		getStatusButtonsLabel(order) {
			switch (order.status) {
				case 0:
					return this.$i18n.t('prepare');

				case 1:
					return order.deliveryMethod.keyUS === 'PICKUP' ? this.$i18n.t('ready') : this.$i18n.t('on_the_way');

				case 2:
					return this.$i18n.t('delivered');

				default:
					return 'ERROR';
			}
		},

		getStatusButtonsColor(order) {
			switch (order.status) {
				case -2:
					return 'error';
				case 0:
					return 'amber';

				case 1:
					return 'deep-orange';

				case 2:
					return 'success';

				default:
					return 'ERROR';
			}
		},

		async getDetailOrder(item) {
			////console.log(item._id);
			this.selectedIndex = this.orders.indexOf(item);
			this.selectedItem = Object.assign({}, item);
			this.orderLoading = true;
			this.orderDialog = true;
			const order = await this.getOrderDetail(item._id);
			////console.log(order);
			this.selectedItem = Object.assign({}, order);
			setTimeout(() => {
				this.orderLoading = false;
			}, 200);
		},

		// editItem(item) {
		// 	this.editedIndex = this.products.indexOf(item);
		// 	this.editedItem = Object.assign({}, item);
		// 	this.imageUrl = this.getImage(this.editedItem.image);
		// 	this.dialog = true;
		// },
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},

		setupDetailAlert(active, message, type) {
			this.detailAlert = active;
			this.detailAlertMessage = message;
			this.detailAlertType = type;

			setTimeout(() => {
				this.detailAlert = false;
			}, 2000);
		},
		getDateFormat(string) {
			return moment(string).format(this.$i18n.t('date_format'));
		},

		getTimeFormat(string) {
			return moment(string).format(this.$i18n.t('time_format'));
		},

		showChangeStatusDialog(order, rejected) {
			if (rejected) {
				this.wantedStatus = -2;
			} else {
				this.wantedStatus = order.status + 1;
			}
			this.dialogStatusColor = this.getStatusColor(this.wantedStatus);
			this.dialogStatusText = this.getStatusDialog(order, this.wantedStatus);
			this.dialogStatus = true;
		},

		rejectedDialogConfirmed(order) {
			this.rejectedDialog = false;
			this.changeStatusOrder(order);
		},

		rejectedDialogClose() {
			this.rejectedDialog = false;
			this.wantedStatus = 0;
		},

		confirmStatusChange(order) {
			if (this.wantedStatus == -2) {
				this.dialogStatus = false;
				this.rejectedDialog = true;
			} else {
				this.changeStatusOrder(order);
				this.dialogStatus = false;
			}
		},

		cancelStatusChange() {
			this.wantedStatus = 0;
			this.dialogStatus = false;
		},

		async changeStatusOrder(order) {
			await this.setNewStatus(order, this.wantedStatus);
			this.wantedStatus = 0;
		},
		reportingDialogClose() {
			this.report.reason = '';
			this.report.comments = '';
			this.reportingDialog = false;
		},
		async sendReportUser() {
			await this.sendReportUserRequest();
			this.reportingDialogClose();
		},

		// NETWORK LOGIC

		async sendReportUserRequest() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				formData.append('user', this.selectedItem.user._id);
				formData.append('comment', this.report.comments);
				formData.append('reason', this.report.reason);
				formData.append('order', this.selectedItem._id);

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					body: formData,
					method: 'POST',
				};

				await fetch(process.env.VUE_APP_API_URL + `user/report`, options).then(async (response) => {
					if (response.ok) {
						this.setupDetailAlert(true, this.$i18n.t('report_sent'), 'success');

						//const data = await response.json();
						////console.log(data);
					}
				});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},

		async downloadExcel() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);

				const lang = this.$i18n.locale == 'en' ? 'US' : 'MX';
				var query = '';
				if (this.accessLevel == 'admin') {
					query = '&days=1';
				} else {
					query = '&daily=true';
				}

				await fetch(process.env.VUE_APP_API_URL + `order/restaurant/report?lang=${lang}${query}`, options)
					.then(async (response) => {
						//console.log(response.headers.get('Content-Disposition'));
						return response.blob();
					})
					.then((blob) => {
						var file = window.URL.createObjectURL(blob);
						window.location.assign(file);
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},

		async setNewStatus(order, status) {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				////console.log(status);
				const formData = new FormData();
				if (status == -2) {
					formData.append('reason', this.selectedItem.reason);
					formData.append('comments', this.selectedItem.comments);
				}
				formData.append('status', status);

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};
				/* Sets loading dialog */
				this.loadingDialog = true;
				/* PUT request to edit status */
				await fetch(process.env.VUE_APP_API_URL + `order/${order._id}/status`, requestOptions).then(
					async (response) => {
						if (response.ok) {
							//this.setupAlert(true, ' edited successfully', 'success');

							const data = await response.json();
							////console.log(data);
							this.selectedItem = Object.assign({}, data);
							this.loadingDialog = false;
							this.setupDetailAlert(true, this.$i18n.t('alert_msg_order_status_updated'), 'success');
						}
					},
				);
			} catch (error) {
				//console.log(error);
				this.setupDetailAlert(true, error, 'error');
			}
		},

		async getOrders() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				var user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					setTimeout(() => {
						user = JSON.parse(localStorage.getItem('weserv.user'));
						if (!user) {
							throw new Error(this.$i18n.t('error_msg_no_user'));
						}
					}, 100);
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + `order/today/`, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						//console.log(data.data);
						this.orders = data.data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
				this.loadingTable = false;
			}
		},
		async getOrderDetail(id) {
			try {
				const token = localStorage.getItem('weserv.access');
				var user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					setTimeout(() => {
						user = JSON.parse(localStorage.getItem('weserv.user'));
						if (!user) {
							throw new Error(this.$i18n.t('error_msg_no_user'));
						}
						this.accessLevel = user.accessLevel;
					}, 100);
				}

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};
				////console.log(options);
				var response;
				await fetch(process.env.VUE_APP_API_URL + `order/${id}`, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						////console.log(data);
						response = data;
					});
				return response;
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	async created() {
		await this.getOrders();
	},
	async mounted() {
		const token = localStorage.getItem('weserv.access');

		if (!token) {
			this.setupAlert(true, this.$i18n.t('error_msg_no_token'), 'error');
		}
		const socket = io(`wss://api.weserv.app/api/socket/orders`, {
			transports: ['websocket'],
			auth: {
				token: token,
			},
		});

		socket.on('connect', () => {
			console.log('connected');
		});
		socket.on('disconnect', () => {
			console.log('disconnected');
		});
		socket.on('newOrder', (order) => {
			//console.log(order);
			this.orders.unshift(order);
		});
		socket.on('updateOrder', (updatedOrder) => {
			//console.log(updatedOrder);
			const index = this.orders.findIndex((order) => {
				return order._id === updatedOrder._id;
			});
			if (updatedOrder.status) {
				this.orders[index].status = updatedOrder.status;
			}
			if (updatedOrder.rating) {
				this.orders[index].rating = updatedOrder.rating;
			}
		});

		socket.on('connect_error', (err) => {
			//console.log(err.message);
			this.setupAlert(true, `${err.message} ~ ${err.data.content}`, 'error'); // prints the message associated with the error
		});

		socket.io.on('reconnect', (lsocket) => {
			console.log('reconnected');
			console.log(lsocket);
			// Emit "updateSocketId" event to update the recorded socket ID with user and room
			// socket.emit('updateSocketId', { lsocket }, (error) => {
			// 	if (error) {
			// 		console.error(error);
			// 		this.setupAlert(true, `${error.message}`, 'error'); // prints the message associated with the error
			// 	}
			// });
		});
		this.socket = socket;
		await this.getOrders();
	},
	beforeDestroy() {
		this.socket.disconnect();
	},
};
</script>

<style scoped></style>
