<template>
	<v-card :elevation="0" color="transparent">
		<v-alert
			v-model="alert"
			transition="slide-y-transition"
			border="left"
			close-text="Close Alert"
			:type="alertType"
			dismissible
		>
			{{ alertMessage }}
		</v-alert>

		<v-row align="center">
			<v-btn class="ml-4" color="primary" @click="back">{{ $t('back') }}</v-btn>
			<v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
		</v-row>

		<v-card-title class="font-weight-bold">
			<h1>{{ $t('rows') }}</h1>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				:label="$t('search')"
				single-line
				hide-details
			></v-text-field>
		</v-card-title>
		<v-row class="py-3" align="center">
			<h2 class="text- ml-5">
				<span class="font-weight-light">{{ $t('venue') }}:</span> {{ section.stadium.name }}
			</h2>
			<h2 class="text-h6  ml-5">~</h2>
			<h2 class="text-h6  ml-5">
				<span class="font-weight-light">{{ $t('section') }}:</span> {{ section.name }}
			</h2>
		</v-row>

		<v-card outlined class="">
			<v-data-table
				:headers="tableHeaders"
				:items="rows"
				:items-per-page="30"
				:search="search"
				:loading="loadingTable"
				multi-sort
				calculate-widths
			>
				<template v-slot:top>
					<v-toolbar flat color="transparent">
						<v-spacer></v-spacer>
						<v-dialog v-model="dialog" max-width="600px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="blue" dark class="ml-2 mb-2" @click="addOptions(2)" v-bind="attrs" v-on="on">
									{{ $t('add_multiple_rows_with_seats') }}
								</v-btn>
								<v-btn color="green" dark class="ml-2 mb-2" @click="addOptions(1)" v-bind="attrs" v-on="on">
									{{ $t('add_row_with_seats') }}
								</v-btn>
								<v-btn color="primary" dark class=" mb-2" @click="addOptions(0)" v-bind="attrs" v-on="on">
									{{ $t('add_row') }}
								</v-btn>
							</template>
							<v-card>
								<v-card-title class="d-flex justify-center">
									<span v-if="!formWithRowsAndSeats" class="headline">{{ formTitle }}</span>
								</v-card-title>

								<v-card-text class="d-flex justify-center my-5">
									<!-- INPUTS  -->

									<validation-observer ref="observer" v-slot="{ invalid }">
										<form @submit.prevent="submit">
											<v-row justify="center" v-if="!formWithRowsAndSeats">
												<v-col cols="6" sm="12">
													<validation-provider v-slot="{ errors }" :name="$t('name')" rules="required">
														<v-text-field
															v-model="editedItem.name"
															:error-messages="errors"
															:label="$t('name')"
															filled
															required
														></v-text-field>
													</validation-provider>
												</v-col>
												<v-col cols="6" sm="12">
													<validation-provider
														v-slot="{ errors }"
														:name="$t('priority')"
														:rules="{ required: true, min_value: 0, max_value: 100 }"
													>
														<v-text-field
															v-model="editedItem.priority"
															:error-messages="errors"
															:label="$t('priority')"
															filled
															required
														></v-text-field>
													</validation-provider>
												</v-col>
											</v-row>
											<v-card v-if="formWithSeats">
												<h2 class="text-h5 text-center pa-3">{{ $t('generate_seats') }}</h2>
												<v-row align="center" justify="center">
													<v-col cols="12" sm="4">
														<validation-provider v-slot="{ errors }" :name="$t('start')" rules="required">
															<v-text-field
																v-model="startSeat"
																:error-messages="errors"
																:label="$t('start')"
																filled
															></v-text-field>
														</validation-provider>
													</v-col>
													<v-col cols="12" sm="4">
														<validation-provider v-slot="{ errors }" :name="$t('end')" rules="required">
															<v-text-field
																v-model="endSeat"
																:error-messages="errors"
																:label="$t('end')"
																filled
															></v-text-field>
														</validation-provider>
													</v-col>
												</v-row>
											</v-card>
											<v-card v-if="formWithRowsAndSeats">
												<h2 class="text-h5 text-center pa-3">{{ $t('generate_rows') }}</h2>
												<v-row align="center" justify="center">
													<v-col cols="12" sm="4">
														<validation-provider v-slot="{ errors }" :name="$t('start')" rules="required">
															<v-text-field
																v-model="startRow"
																:error-messages="errors"
																:label="$t('start')"
																filled
															></v-text-field>
														</validation-provider>
													</v-col>
													<v-col cols="12" sm="4">
														<validation-provider v-slot="{ errors }" :name="$t('end')" rules="required">
															<v-text-field
																v-model="endRow"
																:error-messages="errors"
																:label="$t('end')"
																filled
															></v-text-field>
														</validation-provider>
													</v-col>
												</v-row>
												<h2 class="text-h5 text-center pa-3">{{ $t('generate_seats') }}</h2>
												<v-row align="center" justify="center">
													<v-col cols="12" sm="4">
														<validation-provider v-slot="{ errors }" :name="$t('start')" rules="required">
															<v-text-field
																v-model="startSeat"
																:error-messages="errors"
																:label="$t('start')"
																filled
															></v-text-field>
														</validation-provider>
													</v-col>
													<v-col cols="12" sm="4">
														<validation-provider v-slot="{ errors }" :name="$t('end')" rules="required">
															<v-text-field
																v-model="endSeat"
																:error-messages="errors"
																:label="$t('end')"
																filled
															></v-text-field>
														</validation-provider>
													</v-col>
												</v-row>
											</v-card>
											<v-container class="mt-5">
												<v-btn class="mr-4" color="error" @click="close">
													{{ $t('close') }}
												</v-btn>
												<v-btn type="submit" color="success" :disabled="invalid" @click="save">
													{{ AddButtonText }}
												</v-btn>
											</v-container>
										</form>
									</validation-observer>
								</v-card-text>

								<!-- <v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" @click="close">
									Cancel
								</v-btn>
								<v-btn color="success" @click="save">
									{{ AddButtonText }}
								</v-btn>
							</v-card-actions> -->
							</v-card>
						</v-dialog>
						<v-dialog v-model="dialogDelete" max-width="600px">
							<v-card>
								<v-card-title class="headline justify-center">{{ $t('delete_dialog_confirm_row') }}</v-card-title>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text @click="closeDelete">{{ $t('cancel') }}</v-btn>
									<v-btn color="error" text @click="deleteItemConfirm">OK</v-btn>
									<v-spacer></v-spacer>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<!-- <template v-slot:[`item.birthday`]="{ item }">
				{{ getDateFormat(item.birthday) }}
			</template> -->
				<!-- <template v-slot:[`item.image`]="{ item }">
				<v-avatar class="ma-1">
					<img :src="getImage(item.image)" alt="no image" />
				</v-avatar>
			</template> -->
				<!-- <template v-slot:[`item.total_given`]="{ item }">
				<v-chip :color="getColor(item.total_given)" dark>
					{{ item.total_given }}
				</v-chip>
			</template> -->
				<!-- <template v-slot:[`item.available`]="{ item }">
				<v-chip :color="getColorActive(item.available)" dark>
					{{ item.available ? 'Available' : 'Unavailable' }}
				</v-chip>
			</template>
			<template v-slot:[`item.hasAlcohol`]="{ item }">
				<v-chip :color="getColorActive(item.hasAlcohol)" dark>
					{{ item.hasAlcohol ? 'Yes' : 'No' }}
				</v-chip>
			</template> -->
				<!-- <template v-slot:[`item.gender`]="{ item }">
				<v-chip :color="getColorGender(item.gender)" dark>
					{{ item.gender === 'female' ? 'Mujer' : 'Hombre' }}
				</v-chip>
			</template> -->
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon small class="mr-2" @click="sendToSeats(item)">
						mdi-seat
					</v-icon>
					<v-icon small class="mr-2" @click="editItem(item)">
						mdi-pencil
					</v-icon>
					<v-icon small @click="deleteItem(item)">
						mdi-delete
					</v-icon>
				</template>
				<template v-slot:no-data>
					<v-sheet class="my-10" color="">
						<v-row justify="center">
							<v-sheet color="">
								<h2 class="text-h2 pa-3">{{ $t('no_rows') }}</h2>
							</v-sheet>
						</v-row>
						<v-row justify="center">
							<v-btn class="ma-4" color="primary" @click="getRows">{{ $t('refresh') }}</v-btn>
						</v-row>
					</v-sheet>
				</template>
			</v-data-table>
		</v-card>
	</v-card>
</template>

<script>
//import Dialog from '../dialogs/ProductDialog.vue';

import { required, alpha, numeric, max_value, min_value } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
setInteractionMode('eager');

extend('numeric', {
	...numeric,
});

extend('alpha', {
	...alpha,
});
extend('required', {
	...required,
});

extend('max_value', {
	...max_value,
});
extend('min_value', {
	...min_value,
});

export default {
	components: { ValidationProvider, ValidationObserver },
	data() {
		return {
			search: '',
			dialog: false,
			dialogDelete: false,
			/* NEW CATEGORY VARS */
			// newImage: null,
			// imageUrl: null,
			loadingTable: false,

			formWithSeats: false,
			formWithRowsAndSeats: false,
			/* ALERTS */

			alert: false,
			alertMessage: '',
			alertType: 'error',
			section: {
				name: '',
				stadium: {
					name: '',
				},
			},
			startRow: null,
			endRow: null,
			startSeat: null,
			endSeat: null,
			rows: [],
			editedIndex: -1,
			editedItem: {
				_id: '',
				name: '',
				priority: 0,
			},

			defaultItem: {
				_id: '',
				name: '',
				priority: 0,
			},
			numberRule: (v) => {
				//if (!v.trim()) return true;
				if (!isNaN(parseFloat(v)) && v >= 0) return true;
				return 'The number has to be greater than 0';
			},
			descriptionRules: (v) => {
				//if (!v.trim()) return true;
				if (!v || v.length <= 130) return true;
				return 'Max 130 characters';
			},
		};
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? this.$i18n.t('new_row') : this.$i18n.t('edit_row');
		},
		AddButtonText() {
			return this.editedIndex === -1 ? this.$i18n.t('add') : this.$i18n.t('save');
		},
		accessLevel() {
			const user = JSON.parse(localStorage.getItem('weserv.user'));

			return user.accessLevel;
		},

		breadcrumbs() {
			return [
				{
					text: this.$i18n.t('venues'),
					disabled: false,
					href: '/admin/stadiums',
				},
				{
					text: this.$i18n.t('sections'),
					disabled: false,
					href: `/admin/stadium/${this.section.stadium._id}/sections`,
				},
				{
					text: this.$i18n.t('rows'),
					disabled: true,
					href: `/admin/stadium/${this.$route.params.id}/sections`,
				},
			];
		},
		tableHeaders() {
			var headers = [
				{ text: this.$i18n.t('name'), value: 'name' },
				{ text: this.$i18n.t('priority'), value: 'priority' },
				{ text: this.$i18n.t('actions'), value: 'actions', sortable: false },
			];

			return headers;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		addOptions(option) {
			switch (option) {
				case 0: // Add Row
					this.formWithSeats = false;
					this.formWithRowsAndSeats = false;
					break;
				case 1: // Add Seats
					this.formWithSeats = true;
					this.formWithRowsAndSeats = false;
					break;
				case 2: // Add Rows and Seats
					this.formWithSeats = false;
					this.formWithRowsAndSeats = true;
					break;
			}
		},
		editItem(item) {
			this.addOptions(0);
			this.editedIndex = this.rows.indexOf(item);
			this.editedItem = Object.assign({}, item);

			this.dialog = true;
		},
		setupAlert(active, message, type) {
			this.alert = active;
			this.alertMessage = message;
			this.alertType = type;

			setTimeout(() => {
				this.alert = false;
			}, 2000);
		},

		deleteItem(item) {
			this.editedIndex = this.rows.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
			this.deleteRow();
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
			this.startSeat = null;
			this.endSeat = null;
			this.startRow = null;
			this.endRow = null;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
				//edit
				//Object.assign(this.people[this.editedIndex], this.editedItem);
				this.editRow();
			} else {
				//add
				//this.people.push(this.editedItem);
				if (this.formWithRowsAndSeats) {
					this.addRowsWithSeats();
				} else {
					this.addRow();
				}
			}
			this.close();
		},
		back() {
			this.$router.push(`/admin/stadium/${this.section.stadium._id}/sections`);
		},
		sendToSeats(item) {
			//this.showingSeats = true;
			////console.log(`/admin/stadium/${item._id}/rows`);
			this.$router.push(`/admin/row/${item._id}/seats`);
		},

		// NETWORK LOGIC

		async getRows() {
			try {
				this.loadingTable = true;
				const token = localStorage.getItem('weserv.access');
				//const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				// if (!user) {
				// 	throw new Error(this.$i18n.t('error_msg_no_user'));
				// }

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				const sectionId = this.$route.params.id;

				if (!sectionId) {
					throw new Error('No id was passed in the paramenters');
				}

				const rowsURL = `stadium/section/${sectionId}/rows`;
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + rowsURL, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						////console.log(data.data);
						this.rows = data;
						this.loadingTable = false;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
				this.loadingTable = false;
			}
		},

		async getSection() {
			try {
				const token = localStorage.getItem('weserv.access');
				//const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				// if (!user) {
				// 	throw new Error(this.$i18n.t('error_msg_no_user'));
				// }

				const options = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'GET',
				};

				const sectionID = this.$route.params.id;

				if (!sectionID) {
					throw new Error(this.$i18n.t('error_msg_no_id'));
				}

				const stadiumURL = `stadium/section/${sectionID}`;
				////console.log(options);
				await fetch(process.env.VUE_APP_API_URL + stadiumURL, options)
					.then((response) => response.json())
					.then((data) => {
						////console.log('CATEGORY');
						////console.log(data.data);
						this.section = data;
					});
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},

		async addRow() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('name', this.editedItem.name);
				formData.append('section', this.section._id);
				formData.append('priority', this.editedItem.priority);

				if (this.formWithSeats) {
					formData.append('start', this.startSeat);
					formData.append('end', this.endSeat);
				}

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'stadium/row/', requestOptions).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_row_added'), 'success');
					}
					//response.json();
				});
				this.getRows();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async addRowsWithSeats() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}

				const formData = new FormData();
				//formData.append('uuid', uuidv4());

				formData.append('section', this.section._id);
				formData.append('startRow', this.startRow);
				formData.append('endRow', this.endRow);
				formData.append('startSeat', this.startSeat);
				formData.append('endSeat', this.endSeat);

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'POST',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + 'stadium/rows/', requestOptions).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_row_added'), 'success');
					}
					//response.json();
				});
				this.getRows();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async editRow() {
			try {
				const token = localStorage.getItem('weserv.access');
				const user = JSON.parse(localStorage.getItem('weserv.user'));
				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				if (!user) {
					throw new Error(this.$i18n.t('error_msg_no_user'));
				}
				const formData = new FormData();
				formData.append('name', this.editedItem.name);
				formData.append('section', this.section._id);
				formData.append('priority', this.editedItem.priority);

				const requestOptions = {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'PUT',
					body: formData,
				};

				await fetch(process.env.VUE_APP_API_URL + `stadium/row/${this.editedItem._id}`, requestOptions).then(
					(response) => {
						if (response.ok) {
							this.setupAlert(true, this.$i18n.t('alert_msg_row_updated'), 'success');
						}
					},
				);
				this.getRows();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
		async deleteRow() {
			try {
				const token = localStorage.getItem('weserv.access');

				if (!token) {
					throw new Error(this.$i18n.t('error_msg_no_token'));
				}
				await fetch(process.env.VUE_APP_API_URL + `stadium/row/${this.editedItem._id}`, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
					method: 'DELETE',
				}).then((response) => {
					if (response.ok) {
						this.setupAlert(true, this.$i18n.t('alert_msg_row_deleted'), 'success');
					}
				});
				this.getRows();
			} catch (error) {
				//console.log(error);
				this.setupAlert(true, error, 'error');
			}
		},
	},
	created() {
		this.getRows();
		this.getSection();
	},
};
</script>

<style scoped></style>
